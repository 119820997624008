import React, { Fragment, useState } from 'react'
import { components, useServices } from 'cng-web-lib'
import { useHistory, useParams } from 'react-router-dom'

import AddVesselFormProperties from './AddVesselFormProperties'
import CngBackdrop from 'src/views/vesselschedule/searchschedule/cngcomponent/CngBackDrop'
import TcalVsVesselApiUrls from 'src/apiUrls/TcalVsVesselApiUrls'
import VesselsTranslationText from '../VesselsTranslationText'
import pathMap from 'src/paths/PathMap_VesselSchedule'

const {
  form: { CngViewForm },
  table: { useDefaultNotification }
} = components

function AddVesselPage({ showNotification }) {
  const { id } = useParams()
  const history = useHistory()
  const { createRecord } = useServices()
  const [loading, setLoading] = useState(false)
  const translatedTextsObject = VesselsTranslationText()

  const {
    success: showSuccessNotification,
    error: showErrorNotification
  } = useDefaultNotification(showNotification)

  function onSubmit(data) {
    console.log('submit', data)
    data.newVessel.map((v) => {
      v.name = v.name.trim()
      delete v.id
      delete v.vesselBoxExpanded
    })

    function onSuccess() {
      setLoading(false)
      showSuccessNotification(translatedTextsObject.addSuccess)
      history.push(pathMap.TCAL_VS_MANAGE_VESSELS_VIEW)
    }

    function onError(error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.errorMessages &&
        error.response.data.errorMessages.length > 0
      ) {
        showErrorNotification(error.response.data.errorMessages)
      } else {
        showErrorNotification(error.message)
      }
      setLoading(false)
      console.log('add vessels error', error.message)
    }

    setLoading(true)
    createRecord.execute(
      TcalVsVesselApiUrls.MULTIPLE_CREATE,
      {
        ...data
      },
      onSuccess,
      onError
    )
  }

  return (
    // use CngViewForm here for customizable button set
    <Fragment>
      <CngBackdrop loading={loading} />
      <CngViewForm
        fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
        history={history}
        showNotification={showNotification}
        bodySection={
          <AddVesselFormProperties.FormFields
            disabled={false}
            showNotification={showNotification}
            loading={loading}
          />
        }
        formikProps={{
          ...AddVesselFormProperties.formikProps,
          onSubmit: onSubmit
        }}
      />
    </Fragment>
  )
}

export default AddVesselPage
