import ManageScheduleValidationTranslateText from 'src/views/vesselschedule/manageschedule/validationschema/ManageScheduleValidationTranslateText'
import { Yup } from 'cng-web-lib'
import moment from 'moment'

function ScheduleInfoValidationSchema() {

  const validationMessageTranslation = ManageScheduleValidationTranslateText()
  const transformDateFormat = 'DD-MM-YYYY HH:mm'
  const terminalNoEmojiRegex = "^((?!(.*(\ud83c[\udf00-\udfff]|\ud83d[\udc00-\ude4f]|\ud83d[\ude80-\udeff]).*)).)*$"
  
  return Yup.object({

    voyageLegs: Yup.array(
      Yup.object({

        transportMode: Yup.string()
          .when("waypoint", {
            is: (val) => val != '3',
            then: Yup.string().required(validationMessageTranslation.requiredMessage).nullable()
          }),
          
        portCode: Yup.string().required(validationMessageTranslation.requiredMessage).nullable()
          .when("prevPortCode",
            (prevPortCode, yup) => prevPortCode && yup.notOneOf([prevPortCode], validationMessageTranslation.arrivalPortSameError))
            // (prevPortCode, yup) => prevPortCode && yup.length(1, validationMessageTranslation.arrivalPortSameError))
          ,

        departureTime: Yup.date()
          //transform to DD-MM-YYYY format for validation, Yup default only accept MM-DD-YYYY
          .transform(function (value, originalValue) {
            value = moment(originalValue, transformDateFormat)
        
            // if it's valid return the date object, otherwise return empty ``
            return value.isValid() ? value.toDate() : ''
          })
          .when("waypoint", {
            is: (val) => val != '3',
            then: Yup.date()
                  // .nullable()
                  // .validFormat('YYYY-MM-DD HH:MM') //disable to let date field validation work for onChange
                  .required(validationMessageTranslation.requiredMessage)
                  .typeError(validationMessageTranslation.dateTimeErrorDDMMYYYHHmmMessage)
                  .when("arrivalTime", {
                    is: (val) => moment(val, transformDateFormat).isValid(),
                    then: Yup.date().when("arrivalTime",
                                (arrivalTime, yup) => arrivalTime 
                                  && yup.min(moment(arrivalTime, transformDateFormat).add(1, 'minutes'), // add 1 minute to validate not allow same date
                                        validationMessageTranslation.departureEarlierThanArrival))
                                  .typeError(validationMessageTranslation.dateTimeErrorDDMMYYYHHmmMessage)
                                  ,
                    otherwise: Yup.date().typeError(validationMessageTranslation.dateTimeErrorDDMMYYYHHmmMessage)
                  })
                    // (arrivalTime, yup) => arrivalTime && yup.min(arrivalTime, validationMessageTranslation.departureEarlierThanArrival))
                    ,
            otherwise: Yup.date().nullable()
          }),

        arrivalTime: Yup.date()
          //transform to DD-MM-YYYY format for validation, Yup default only accept MM-DD-YYYY
          .transform(function (value, originalValue) {

            value = moment(originalValue, transformDateFormat)
        
            // if it's valid return the date object, otherwise return empty ``
            return value.isValid() ? value.toDate() : ''
          })
          .when("waypoint", {
            is: (val) => val != '1',
              then: Yup.date().required(validationMessageTranslation.requiredMessage)
                    // .nullable()
                    // .validFormat('YYYY-MM-DD HH:MM') //disable to let date field validation work for onChange
                    .typeError(validationMessageTranslation.dateTimeErrorDDMMYYYHHmmMessage)
                    .when("prevDepartureTime", {
                      is: (val) => moment(val, transformDateFormat).isValid(),
                      then: Yup.date().when("prevDepartureTime",
                        (prevDepartureTime, yup) => prevDepartureTime && 
                          yup.min(moment(prevDepartureTime, transformDateFormat).add(1, 'minutes'), // add 1 minute to validate not allow same date
                              validationMessageTranslation.arrivalEarlierThanDepartureMessage)).typeError(validationMessageTranslation.dateTimeErrorDDMMYYYHHmmMessage),
                      otherwise: Yup.date().typeError(validationMessageTranslation.dateTimeErrorDDMMYYYHHmmMessage)
                    })
                      ,
                    
              otherwise: Yup.date().nullable()
          }), 

        cyCutoffTime: Yup.date()
          //transform to DD-MM-YYYY format for validation, Yup default only accept MM-DD-YYYY
          .transform(function (value, originalValue) {
            value = moment(originalValue, transformDateFormat)
        
            // if it's valid return the date object, otherwise return empty ``
            return value.isValid() ? value.toDate() : ''
          })
          // .validFormat() //disable to let date field validation work for onChange
          .typeError(validationMessageTranslation.dateTimeErrorDDMMYYYHHmmMessage)
          .nullable()
          .when("departureTime", {
            is: (val) => moment(val, transformDateFormat).isValid(),
            then: Yup.date().when("departureTime",
                        (departureTime, yup) => departureTime 
                          && yup.max(moment(departureTime, transformDateFormat).subtract(1, 'minutes'), // subtract 1 minute to validate not allow same date, 
                                validationMessageTranslation.cyCutoffLaterThanDeparture))
                          .typeError(validationMessageTranslation.dateTimeErrorDDMMYYYHHmmMessage)
                          ,
            otherwise: Yup.date().typeError(validationMessageTranslation.dateTimeErrorDDMMYYYHHmmMessage)
          }),
          // ,

          terminal: Yup.string()
          .matches(terminalNoEmojiRegex, validationMessageTranslation.emojiCharacterPresentMessage)
          .max(100, validationMessageTranslation.exceedMaxCharactersMessage)
          .nullable()
        })


        // arrivalTime: Yup.date()
        //   .when("waypoint", {P
        //     is: (val) => val != '1',
        //     then: Yup.date().required(validationMessageTranslation.requiredMessage)
        //           .nullable()
        //           // .validFormat('YYYY-MM-DD HH:MM') //disable to let date field validation work for onChange
        //           .typeError(validationMessageTranslation.dateTimeErrorDDMMYYYHHmmMessage)
        //           .when('prevDepartureTime',{
        //             is: null,
        //             then: Yup.date().required(validationMessageTranslation.requiredMessage).nullable().typeError(validationMessageTranslation.dateTimeErrorDDMMYYYHHmmMessage),
        //             otherwise: Yup.date()
        //               // .min(moment(Yup.ref('prevDepartureTime')).format('YYYY-MM-DD HH:MM'),  validationMessageTranslation.arrivalEarlierThanDepartureMessage)
        //               .min(Yup.ref('prevDepartureTime'),  ({ min }) => `prevDepartureTime arrialval date earier than ${min}`)
        //               .typeError(validationMessageTranslation.dateTimeErrorDDMMYYYHHmmMessage),
        //           })
        //           ,
        //       otherwise: Yup.date().nullable()
        //   }), 

        
    ),

  })
}

export default ScheduleInfoValidationSchema


