import { BasePage as CngBasePage } from 'cng-web-lib'
import ManageBlacklistedCarriersTable from './ManageBlacklistedCarriersTable.js'
import React from 'react'
import useBreadcrumbNameMap from 'src/navigations/breadcrumbs/Breadcrumb_VesselSchedule'

function ManageBlacklistedCarriersTablePage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()

  return (
    <CngBasePage
      renderPage={(showSnackbar) => (
        <ManageBlacklistedCarriersTable
          showNotification={showSnackbar}
          {...props}
        />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

export { ManageBlacklistedCarriersTablePage }
