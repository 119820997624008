import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
  constants,
  useTranslation
} from 'cng-web-lib'

import VSCodeMappingApiUrls from 'src/apiUrls/VSCodeMappingApiUrls'
import React from 'react'
import CodeMappingKeys from 'src/constants/locale/key/CodeMapping'
import Namespace from 'src/constants/locale/Namespace'

const {
  card: { CngSimpleCardHeader },
  table: {
    CngCrudTable,
    DateTimeRangeFilter: CngDateTimeRangeFilter,
    useDefaultNotification
  }
} = components

const {
  locale: {
    key: { UiComponentKeys }
  }
} = constants

function TablePage(props) {
  const {
    location: { pathname },
    showNotification
  } = props

  const notification = useDefaultNotification(showNotification)
  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.CODE_MAPPING
  ])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let codeMapping = translate(
      Namespace.CODE_MAPPING,
      CodeMappingKeys.TITLE
    )
    let tableTitle = translate(
      Namespace.UI_COMPONENT,
      UiComponentKeys.Table.TITLE,
      {
        nameTitleised: codeMapping
      }
    )
    let codeMappingId = translate(
      Namespace.CODE_MAPPING,
      CodeMappingKeys.CODE_MAPPING_ID
    )
    let typeName = translate(
      Namespace.CODE_MAPPING,
      CodeMappingKeys.TYPE_NAME
    )
    let calistaCode = translate(
      Namespace.CODE_MAPPING,
      CodeMappingKeys.CALISTA_CODE
    )
    let otherPlatformCode = translate(
      Namespace.CODE_MAPPING,
      CodeMappingKeys.OTHER_PLATFORM_CODE
    )
    let createdBy = translate(
      Namespace.CODE_MAPPING,
      CodeMappingKeys.CREATED_BY
    )
    let createdDate = translate(
      Namespace.CODE_MAPPING,
      CodeMappingKeys.CREATED_DATE
    )
    let updatedBy = translate(
      Namespace.CODE_MAPPING,
      CodeMappingKeys.UPDATED_BY
    )
    let updatedDate = translate(
      Namespace.CODE_MAPPING,
      CodeMappingKeys.UPDATED_DATE
    )
    let version = translate(
      Namespace.CODE_MAPPING,
      CodeMappingKeys.VERSION
    )

    return {
      codeMapping,
      tableTitle,
      codeMappingId,
      typeName,
      calistaCode,
      otherPlatformCode,
      createdBy,
      createdDate,
      updatedBy,
      updatedDate,
      version
    }
  }

  const columns = [
    {
      field: "typeName",
      title: translatedTextsObject.typeName,
    },
    {
      field: "calistaCode",
      title: translatedTextsObject.calistaCode,
    },
    {
      field: "otherPlatformCode",
      title: translatedTextsObject.otherPlatformCode,
    },
    {
      field: "createdDate",
      title: translatedTextsObject.createdDate,
      type: "datetime",
      filterComponent: CngDateTimeRangeFilter,
    },
    {
      field: "updatedDate",
      title: translatedTextsObject.updatedDate,
      type: "datetime",
      filterComponent: CngDateTimeRangeFilter,
    }
  ]

  return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.tableTitle} />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CngCrudTable
              {...props}
              fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
              addRoute={`${pathname}/add`}
              columns={columns}
              del={{ url: VSCodeMappingApiUrls.DELETE }}
              editRoute={`${pathname}/edit`}
              exportData={{ url: VSCodeMappingApiUrls.EXPORT }}
              fetch={{ url: VSCodeMappingApiUrls.GET }}
              idAccessor="id"
              notification={notification}
              //tableConfigurationCode="REPLACE_WITH_CORE_TABLE_CONFIG_CODE"
              viewRoute={`${pathname}/view`}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default TablePage
