import { Yup, constants } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'

const {
  locale: {
    key: { CommonValidationMessageKeys }
  }
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )

  return Yup.object({
    newVessel: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required(requiredMessage),
        status: Yup.string().required(requiredMessage)
      })
    )
  })
}

export default makeValidationSchema
