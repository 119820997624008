import { Box, Grid } from '@material-ui/core'
import React, { Fragment, useEffect, useRef, useState } from 'react'

import AccordionHeaderComponent from 'src/views/common/ui/AccordionHeaderComponent'
import FooterAddComponent from 'src/views/common/ui/FooterAddComponent'
import MultipleAddVesselDetailsComponent from './MultipleAddVesselDetailsComponent'
import VesselsTranslationText from '../VesselsTranslationText'
import { components } from 'cng-web-lib'
import pathMap from 'src/paths/PathMap_VesselSchedule'
import { useHistory } from 'react-router-dom'
import { useFieldArray } from 'react-hook-form'
import { v4 as uuid } from 'uuid'
const {
  form : {
    adapter: {
      useFormAdapter:{ useField, useFormikContext }
    }
  },
  button: { CngPrimaryButton, CngSecondaryButton }
} = components

const FormBody = (props) => {
  const history = useHistory()
  const { errors, setFieldValue } = useFormikContext()

  const getFieldError = (itemIndex, fieldPath) => {
    if (errors) {
      let pathArr = fieldPath.split('.')
      if (
        errors[pathArr[0]] &&
        errors[pathArr[0]][itemIndex] &&
        errors[pathArr[0]][itemIndex][pathArr[1]]
      ) {
        // if (touched) {
        //   if (
        //     touched[pathArr[0]] &&
        //     touched[pathArr[0]][itemIndex] &&
        //     touched[pathArr[0]][itemIndex][pathArr[1]]
        //   ) {
            // return true
            return errors[pathArr[0]][itemIndex][pathArr[1]]
        //   } else {
        //     return null
        //   }
        // }
      }
    }
    return null
  }

  const translatedTextsObject = VesselsTranslationText()

  const vesselItem = {
    id: uuid(), //used as the key of each card
    name: '',
    imoNo: '',
    status: '',
    description: '',
    vesselBoxExpanded: false
  }

  const [vesselList, setVesselList] = useState([])
  const [, , { setValue: setVesselsField }] = useField('newVessel')
 // const { fields: stateVoyageLegs } = useFieldArray({name: "newVessel"})
  const draftVesselId = useRef(-1)

  const handleInputChange = (e, index) => {
    const { name, value } = e.target
    const list = [...vesselList]

    list[index][name.split('.')[1]] = value

    setVesselList(list)
    // setVesselsField(list)
    setFieldValue('newVessel', list)
  }

  const handleVesselDetailsExpand = (idx) => () => {
    const isVesselBoxExpanded = vesselList[idx].vesselBoxExpanded
    const temp = [...vesselList]
    temp[idx].vesselBoxExpanded = !isVesselBoxExpanded
    setVesselList([].concat([], temp))
  }

  const handleRemoveVessel = (index) => {
    const list = [...vesselList]

    list.splice(index, 1)
    setVesselList(list)
    // setVesselsField(list)
    setFieldValue('newVessel',list)
  }

  const handleAddVessel = () => {
    const addVessel = { ...vesselItem }
    addVessel.id = uuid() //draftVesselId.current
    draftVesselId.current = draftVesselId.current - 1
    setVesselList([...vesselList, addVessel])
    // setVesselsField([...vesselList, addVessel])
    setFieldValue('newVessel', [...vesselList, addVessel])
  }

  const handleCopyVessel = (index) => {
    const list = [...vesselList]
    const copyVessel = list.slice(index, index + 1)[0]

    const copyVesselItem = { ...copyVessel }
    copyVesselItem.id =uuid() // draftVesselId.current
    draftVesselId.current = draftVesselId.current - 1

    setVesselList([...vesselList, copyVesselItem])
    // setVesselsField([...vesselList, copyVesselItem])
    setFieldValue('newVessel',[...vesselList, copyVesselItem])
  }

  const handleDropdownChange = (e, index, name) => {
    const list = [...vesselList]
    list[index][name] = e.target.value
    console.log(list)

    setVesselList(list)
    // setVesselsField(list)
    setFieldValue('newVessel', list)
  }

  useEffect(() => {
    const ves = { ...vesselItem }
   // ves.id = 'ves' + draftVesselId.current
    draftVesselId.current = draftVesselId.current - 1
    setVesselList([ves])
    // setVesselsField([ves])
    setFieldValue('newVessel', [ves])
  }, [])

  return (
    <Fragment>
      <Box m={1}>
        <Grid container>
          <AccordionHeaderComponent
            title={translatedTextsObject.vesselInformation}
          />
        </Grid>
        <MultipleAddVesselDetailsComponent
          vesselList={vesselList}
          handleAddVessel={handleAddVessel}
          handleRemoveVessel={handleRemoveVessel}
          handleCopyVessel={handleCopyVessel}
          handleVesselDetailsExpand={handleVesselDetailsExpand}
          handleInputChange={handleInputChange}
          getFieldError={getFieldError}
          handleDropdownChange={handleDropdownChange}
        />
      </Box>

      <Box pt={1} mr={2}>
        <FooterAddComponent
          handleAddChild={handleAddVessel}
          footerText={translatedTextsObject.addAnotherVessel}
        />
      </Box>

      <Box pt={3} mr={2}>
        <Grid container xs={12} sm={12}>
          <Grid xs={6} sm={6} justify='flex-start'>
            <CngSecondaryButton
              onClick={() => {
                const ves = { ...vesselItem }
               // ves.id = 'ves' + draftVesselId.current
                draftVesselId.current = draftVesselId.current - 1
                setVesselList([ves])
                // setVesselsField([ves])
                setFieldValue('newVessel',[ves])
              }}
            >
              {translatedTextsObject.discard}
            </CngSecondaryButton>
          </Grid>
          <Grid container xs={6} sm={6} justify='flex-end'>
            <Box>
              <CngPrimaryButton type='submit'>
                {translatedTextsObject.addVessels}
              </CngPrimaryButton>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  )
}

const initialValues = {
  newVessel: [
    {
      id: '',
      name: '',
      imoNo: '',
      status: '',
      description: ''
    }
  ]
}

const MultipleAddVesselComponent = Object.freeze({
  FormBody: FormBody,
  initialValues: initialValues
})

export default MultipleAddVesselComponent
