import { Box, Paper } from '@material-ui/core'

import CngBackdrop from '../../searchschedule/cngcomponent/CngBackDrop'
import MultipleAddVesselComponent from './MultipleAddVesselComponent'
import React from 'react'
import { components } from 'cng-web-lib'
import makeValidationSchema from './MakeValidationSchema'

const {
  table: { useDefaultNotification },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  ...MultipleAddVesselComponent.initialValues
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function FormFields({ disabled, showNotification, loading }) {
  return (
    <Box>
      <CngGridItem xs={12} sm={9} shouldHide={loading ? false : true}>
        <CngBackdrop loading={loading} />
      </CngGridItem>

      <Paper>
        <Box p={2} className='page-content'>
          <MultipleAddVesselComponent.FormBody />
        </Box>
      </Paper>
      <Paper>
        <Box alignItems='center'></Box>
      </Paper>
    </Box>
  )
}

const AddVesselFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  FormFields: FormFields
})

export default AddVesselFormProperties
