import { Box, Card, Grid, Paper } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { components, constants, useServices } from 'cng-web-lib'
import { useHistory, useLocation } from 'react-router-dom'

import AmendVesselDialog from './AmendVesselDialog'
import Loading from 'src/views/vesselschedule/searchschedule/Loading'
import TcalVsManageScheduleApiUrls from 'src/apiUrls/TcalVsManageScheduleApiUrls.js'
import TcalVsVesselApiUrls from 'src/apiUrls/TcalVsVesselApiUrls.js'
import VesselsTranslationText from '../VesselsTranslationText'
import moment from 'moment'
import pathMap from 'src/paths/PathMap_VesselSchedule'

const {
  button: { CngPrimaryButton, CngSecondaryButton },
  table: { useDefaultNotification },
  CngGridItem
} = components

const {
  filter: { EQUAL }
} = constants

function ViewVesselForm(props) {
  const { fetchRecords } = useServices()
  const location = useLocation()
  const history = useHistory()
  const translatedTextsObject = VesselsTranslationText()
  const { showNotification } = props

  const notification = useDefaultNotification(showNotification)

  const [reload, setReload] = useState(0)
  const [isAmendVesselDialogOpen, setAmendVesselDialogOpen] = useState(false)
  const [vessel, setVessel] = useState([])
  const [voyages, setVoyages] = useState([])
  const [loading, setLoading] = useState(true)

  function closeDialog() {
    setAmendVesselDialogOpen(false)
  }

  function reloadTime() {
    setReload(reload + 1)
  }

  useEffect(() => {
    setLoading(true)
    fetchRecords.execute(
      TcalVsVesselApiUrls.GET,
      {
        filters: [
          {
            field: 'id',
            operator: EQUAL,
            value: location.state.vesselId
          }
        ]
      },
      (data) => {
        let FromApi = data.content
        setVessel(FromApi)
        setLoading(false)
      },
      (error) => {
        console.log(error)
        setLoading(false)
      }
    )

    fetchRecords.execute(
      TcalVsManageScheduleApiUrls.GET,
      {
        filters: [
          {
            field: 'vesselId',
            operator: EQUAL,
            value: location.state.vesselId
          }
        ]
      },
      (data) => {
        let FromApi = data.content
        setVoyages(FromApi)
      },
      (error) => {
        console.log(error)
      }
    )
  }, [reload])

  return (
    <Paper>
      <Loading loading={loading} />
      {!loading ? (
        <Box p={2} className='page-content'>
          <Box m='8px'>
            <Card>
              <Grid container spacing={2}>
                <CngGridItem xs={12}>
                  <Box pt={1} className='view-card-content-label'>{`SN1`}</Box>
                </CngGridItem>
                <CngGridItem xs={4}>
                  <Box className='view-card-content'>
                    <Box className='view-card-content-label'>
                      {translatedTextsObject.name}
                    </Box>
                    <Box className='view-card-content-value'>
                      <Box className='vs-break-word'>{vessel[0].name}</Box>
                    </Box>
                  </Box>
                </CngGridItem>
                <CngGridItem xs={4}>
                  <Box className='view-card-content'>
                    <Box className='view-card-content-label'>
                      {translatedTextsObject.imoNo}
                    </Box>
                    <Box className='view-card-content-value'>
                      {vessel[0].imoNo}
                    </Box>
                  </Box>
                </CngGridItem>
                <CngGridItem xs={4}>
                  <Box className='view-card-content'>
                    <Box className='view-card-content-label'>
                      {translatedTextsObject.vessel +
                        ' ' +
                        translatedTextsObject.vesselStatus}
                    </Box>
                    <Box className='view-card-content-value'>
                      {vessel[0].status
                        ? translatedTextsObject.active
                        : translatedTextsObject.inactive}
                    </Box>
                  </Box>
                </CngGridItem>
                <CngGridItem xs={12}>
                  <Box className='view-card-content'>
                    <Box className='view-card-content-label'>
                      {translatedTextsObject.description}
                    </Box>
                    <Box className='view-card-content-value'>
                      <Box className='vs-break-word'>
                        {vessel[0].description === null
                          ? '-'
                          : vessel[0].description}
                      </Box>
                    </Box>
                  </Box>
                </CngGridItem>
              </Grid>
            </Card>
          </Box>

          <Box pt={3} mr={2}>
            <Grid container>
              <CngGridItem xs={6}>
                <Grid container justify='flex-start'>
                  <CngSecondaryButton
                    onClick={() =>
                      history.push(pathMap.TCAL_VS_MANAGE_VESSELS_VIEW)
                    }
                  >
                    {translatedTextsObject.backToMVesssel}
                  </CngSecondaryButton>
                </Grid>
              </CngGridItem>
              <CngGridItem xs={6}>
                <Grid container justify='flex-end'>
                  <Box>
                    <CngPrimaryButton
                      onClick={() => setAmendVesselDialogOpen(true)}
                    >
                      {translatedTextsObject.amend}
                    </CngPrimaryButton>
                  </Box>
                </Grid>
              </CngGridItem>
            </Grid>
          </Box>
          <AmendVesselDialog
            isDialogOpen={isAmendVesselDialogOpen}
            reloadTime={reloadTime}
            closeDialog={closeDialog}
            showNotification={showNotification}
            vId={vessel[0].id}
            vesselName={vessel[0].name}
            imoNo={vessel[0].imoNo}
            vesselStatus={vessel[0].status}
            remarks={vessel[0].description}
            partyId={vessel[0].partyId}
            version={vessel[0].version}
            showWarningMsg={
              voyages.filter((voyage) => {
                voyage = voyage.voyageLegs.filter(
                  (leg) =>
                    moment(leg.departureTime).isAfter(moment()) ||
                    moment(leg.arrivalTime).isAfter(moment())
                )
                return voyage.length > 0
              }).length > 0
                ? true
                : false
            }
          />
        </Box>
      ) : null}
    </Paper>
  )
}

export default ViewVesselForm
