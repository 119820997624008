import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Card,
    Grid
} from '@material-ui/core'
import { DateTimeFormatter, components } from 'cng-web-lib'
import React, { useEffect, useRef, useState } from 'react'

import AccordionFooterControlComponent from 'src/views/common/ui/AccordionFooterControlComponet'
import AccordionHeaderComponent from 'src/views/common/ui/AccordionHeaderComponent'
import LabelValueVertical from 'src/views/freightbooking/components/LabelValueVertical'
import ManageScheduleTranslationText from '../ManageScheduleTranslationText'
import { v4 as uuid } from 'uuid'

const {
    form: {
        field: {
            CngTextField
        },
    },
    CngGridItem,
} = components

const initialValues = Object.freeze({
    remarks: ""
})

const FormBody = (props) => {

    const {voyageData} = props
    // const [, ,] = useField('remarks')
    const translatedTextsObject = ManageScheduleTranslationText()

    return (
        <Card>
            <Box className="view-card-header">
                {translatedTextsObject.remarks}
            </Box>
            <Grid container>
                <Grid container item xs={12} sm={12} spacing={2}>
                    <LabelValueVertical 
                        label={translatedTextsObject.remarks}
                        value={voyageData.remarks} />
                </Grid>
            </Grid>
        </Card>
    )

}

const ViewRemarksComponent = Object.freeze({
    FormBody: FormBody,
    initialValues: initialValues
})

export default ViewRemarksComponent