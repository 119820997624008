import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Card,
    Grid
} from '@material-ui/core'
import { DateTimeFormatter, components } from 'cng-web-lib'
import React, { useEffect, useRef, useState } from 'react'

import AccordionFooterControlComponent from 'src/views/common/ui/AccordionFooterControlComponet'
import AccordionHeaderComponent from 'src/views/common/ui/AccordionHeaderComponent'
import ManageScheduleTranslationText from '../ManageScheduleTranslationText'
import { v4 as uuid } from 'uuid'

const {
    form: {
        adapter: {
            useFormAdapter:{ useField, useFormikContext }
        },
        field: {
            CngTextField
        },
    },
    CngGridItem,
} = components

const initialValues = Object.freeze({
    remarks: ""
})

const FormBody = (props) => {

    const [, ,] = useField('remarks')
    const translatedTextsObject = ManageScheduleTranslationText()
    const [remarksExpanded, setRemarksExpanded] = useState(false)

    const handleRemarksExpand = () => {
        setRemarksExpanded(!remarksExpanded);
    };

    // useEffect(() => {
    //     setVesselStatusKey(uuid())

    // }, [props.isEdit])

    return (
        <Card>
            <Accordion expanded={remarksExpanded}>
                <AccordionSummary aria-controls="remarksComponentContent" id='remarksComponentHeader'>
                    <Grid container>
                        <AccordionHeaderComponent title={translatedTextsObject.remarks} hideMandatory={true}/>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container>
                        <Grid container item xs={12} sm={12} spacing={2}>
                            <CngTextField
                                type='text'
                                name='remarks'
                                label={translatedTextsObject.remarks}
                                inputProps={{ maxLength: 255 }}
                                multiline
                                rows={3}
                            />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>

            <AccordionFooterControlComponent
                handleAccordionExpand={handleRemarksExpand}
                expanded={remarksExpanded}
                footerText={translatedTextsObject.addRemarks}
            />
        </Card>
    )

}

const RemarksComponent = Object.freeze({
    FormBody: FormBody,
    initialValues: initialValues
})

export default RemarksComponent