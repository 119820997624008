import {
  Avatar,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@material-ui/core'
import React, { useEffect } from 'react'

import DocumentInfoDecoratorComponent from 'src/views/common/ui/DocumentInfoDecoratorComponent'
import { FileText } from 'react-feather'
import FreightBookingApiUrls from 'src/apiUrls/FreightBookingApiUrls'
import FreightBookingTranslationText from 'src/views/freightbooking/shared/FreightBookingTranslationText'
import Paper from '@material-ui/core/Paper'
import { components } from 'cng-web-lib'

const {
  button: { CngPrimaryButton }
} = components

const DocumentDetails = (props) => {
  const translatedTextsObject = FreightBookingTranslationText()

  useEffect(() => {
    console.log('file details change')

    var files = props.tile
    console.log(files)
  }, [props.tile])

  if (props.tile == null || props.tile.length <= 0) {
    return null
  } else {
    return (
      <Paper style={{ backgroundColor: '#fafbfc' }}>
        <Box my={1}>
          <TableContainer style={{ backgroundColor: '#fafbfc' }}>
            <Table id='table'>
              <TableBody>
                {props.tile.map((elem, i) => {
                  return (
                    <TableRow key={`row-${i}`}>
                      <TableCell>
                        <Box display='flex'>
                          <Box flexGrow={1}>
                            <Box display='flex'>
                              <Box>
                                <Avatar style={{ backgroundColor: '#f5f6fa' }}>
                                  <FileText size='25px' />
                                </Avatar>
                              </Box>
                              <Box ml={2}>
                                <DocumentInfoDecoratorComponent
                                  bold={true}
                                  name={elem.file.name}
                                  size={elem.file.size}
                                />
                              </Box>
                            </Box>
                          </Box>
                          <Box>
                            <Button
                              color='primary'
                              onClick={props.removeRowClick(i)}
                            >
                              Delete
                            </Button>
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box mt={2} className={'vs-nofile'}>
          <CngPrimaryButton onClick={props.upload}>
            Start Upload
          </CngPrimaryButton>
        </Box>
      </Paper>
    )
  }
}

export default DocumentDetails
