import { Box, Button, Grid } from '@material-ui/core'

import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import React from 'react'
import { components } from 'cng-web-lib'

const {
    form : {
        adapter: {
            useFormAdapter:{ useField, useFormikContext }
        }
    }
} = components

function AddScheduleButtonComponent(props){

    const translatedTextsObject = CalistaUiComponentTranslationText()
    const { submitForm, setFieldValue } = useFormikContext()
    
    return (
        <Box pl={5} pr={5} pt={4} pb={4}>
            <Grid container xs={12} sm={12}>
                <Grid xs={6} sm={6} justify="flex-start">
                    <Button variant="contained"
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                            props.onDiscard()
                            }
                        }
                        classes={{ root: 'ng-button-filled-secondary' }}
                    >
                        {translatedTextsObject.discard}
                    </Button>
                </Grid>
                <Grid container xs={6} sm={6} justify="flex-end" >
                    <Box>
                        <Button variant="contained" 
                            className="button-light-green originalText"
                            type="submit" 
                            onClick={() => { props.onConfirmNext()}
                            }
                        >
                            {translatedTextsObject.confirmNext}
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default AddScheduleButtonComponent