import { Avatar, Box, Paper, Typography, Button, Grid } from '@material-ui/core'
import { PlusCircle } from 'react-feather'
import React, { useEffect, useState } from 'react'
import AddNewPreferredCarrier from './AddNewPreferredCarrier.js'
import ManageCarriersTranslationText from 'src/views/vesselschedule/managepreferredcarrier/ManageCarriersTranslationText'
import TcalVsManagePreferredCarrierApiUrls from 'src/apiUrls/TcalVsManagePreferredCarrierApiUrls.js'
import { components } from 'cng-web-lib'
import moment from 'moment'
import { useServices } from 'cng-web-lib'
import { useFieldArray } from 'react-hook-form'


const {
  form: {
    adapter: {
      useFormAdapter:{ useField, useFormikContext }
    },
    field: { CngCheckboxGroup }
  }
} = components


function SelectPreferredCarrierStep(props) {
  const translatedTextsObject = ManageCarriersTranslationText()
  const { fetchRecords } = useServices()
  const [preferredCarriers, setPreferredCarriers] = useState([])
  const [selectedNewCarriers, setSelectedNewCarriers] = useState([])
  const { setFieldValue } = useFormikContext()
  const [, , { setValue: setPreferredCarrierList }] = useField(
    'preferredCarrier'
  )
  // const [newPreferredCarrierList, , { setValue: setNewPreferredCarrierList }] = useField(
  //   'newPreferredCarrier'
  // )
  // const [prefPcarrier, , { setValue: setPrefPcarrier }] = useField('prefPcarrier')
  //const checkBoxValue = getValues("prefPcarrier")
  const [checkBoxValue, , ] = useField(
    'prefPcarrier'
  )
  //const { fields: prefPcarrier } = useFieldArray({name: "prefPcarrier"})
  const { fields: newPreferredCarrierList } = useFieldArray({name: "newPreferredCarrier"})

  const newCarrier = {
    carrierPartyId: '',
    contracted: false,
    contractStartDate: '',
    contractEndDate: '',
    name: '',
    code: '',
    selected: false
  }

  function renderDateColumn(date) {
    if (!date) {
      return null
    }

    return <span>{moment(date).format('DD/MM/YYYY')}</span>
  }

  useEffect(() => {
    fetchRecords.execute(
      TcalVsManagePreferredCarrierApiUrls.PREFERRED_CARRIERS_GET,
      {},
      onSuccess,
      onError
    )

    function onSuccess(data) {
      let fromApi = data.content.map((rowData) => ({
        label: (
          <Paper>
            <Box display='flex' alignItems='center' width={780} my={0.3}>
              <Box my={1} ml={1.5}>
                <Avatar
                  alt='Carrier'
                  src={`${process.env.PUBLIC_URL}/static/images/carriers/${rowData.code}.svg`}
                  classes={{
                    img: 'vs-carrier-image'
                  }}
                >
                  <img
                    className={'search-button'}
                    src={`${process.env.PUBLIC_URL}/static/images/carriers/genericCarrier.svg`}
                  ></img>
                </Avatar>
              </Box>
              <Box ml={1.5} width="25%">
                {' '}
                <b>{rowData.name}</b>
              </Box>
              <Box ml={1.5} flexGrow={1}>
                {rowData.contracted ? (
                  <b>{translatedTextsObject.contracted}</b>
                ) : null}
              </Box>
              <Box ml={1.5} flexGrow={1}>
                {rowData.contractStartDate ? (
                  <div>
                    <div className={'rs-showContentWithTransparentLargerFont'}>
                      {translatedTextsObject.startingDate}
                    </div>
                    <div className={'rs-showContentLargerFont'}>
                      {renderDateColumn(rowData.contractStartDate)}
                    </div>
                  </div>
                ) : null}
              </Box>
              <Box ml={1.5} flexGrow={1}>
                {rowData.contractEndDate ? (
                  <div>
                    <div className={'rs-showContentWithTransparentLargerFont'}>
                      {translatedTextsObject.endingDate}
                    </div>
                    <div className={'rs-showContentLargerFont'}>
                      {renderDateColumn(rowData.contractEndDate)}
                    </div>
                  </div>
                ) : null}
              </Box>
            </Box>
          </Paper>
        ),
        value: rowData.prefCarrierId + '',
        carrierName: rowData.name,
        carrierCode: rowData.code,
        contracted: rowData.contracted,
        contractStart: rowData.contractStartDate,
        contractEnd: rowData.contractEndDate
      }))
      setPreferredCarriers(fromApi)
      // setPreferredCarrierList(fromApi)
      setFieldValue('preferredCarrier', fromApi)
    }

    function onError(error) {
      console.log('error', error.message)
    }
  }, [])

  const [index, setIndex] = useState(0)

  const handleAddCarrier = () => {
    let temp_list = [...newPreferredCarrierList]
    temp_list.push(newCarrier)
    // setNewPreferredCarrierList(temp_list)
    setFieldValue('newPreferredCarrier', temp_list)
    let temp = ({
      label: <AddNewPreferredCarrier index={index}/>,
      value: 'new_' + index,
      carrierName: '',
      carrierCode: ''
    })
    setPreferredCarriers(preferredCarriers.concat(temp))
    setFieldValue('preferredCarrier', preferredCarriers.concat(temp))
    setIndex(index+1)
  } 

  useEffect(() => {
    //console.log('prefPcarrier',checkBoxValue.value )    
    let new_checkedCarriers = checkBoxValue.value.filter((car) => car.match(/^new_\d+$/))
    let difference = new_checkedCarriers.filter(x => selectedNewCarriers.indexOf(x) === -1).concat(selectedNewCarriers.filter(x => new_checkedCarriers.indexOf(x) === -1))

    if(difference.length > 0){
      let temp_list = [...newPreferredCarrierList]
      difference.forEach(car => {
        let car_idx = car.split('_')[1]
        temp_list[car_idx].selected = !temp_list[car_idx].selected
      })
      // setNewPreferredCarrierList(temp_list)
      setFieldValue('newPreferredCarrier', temp_list)
      setSelectedNewCarriers(new_checkedCarriers)
    }

  },[checkBoxValue.value])


  return (
    <div>
      <Box style={{display: props.editCarrierPort ? 'none' : 'flex'}}>
        <Typography variant='body2' gutterBottom style={{color:"#1C1D21CC"}}>
          {translatedTextsObject.setPrefCarrierOrAdd}
        </Typography>
      </Box>
      <Box style={{display: props.editCarrierPort ? 'flex' : 'none'}} ml={6}>
        <Typography variant='body2' gutterBottom style={{color:"#1C1D21CC"}}>
          {translatedTextsObject.editPrefCarrierOrAdd}
        </Typography>
      </Box>
      <CngCheckboxGroup
        disabled={false}
        name='prefPcarrier'
        labelSpacing= {props.editCarrierPort ? 1 : 0}
        options={preferredCarriers}
        direction='column'
      />
      <Grid container justify="center">
        <Grid item onClick={handleAddCarrier} sm={props.editCarrierPort ? 10 : 11} xs={props.editCarrierPort ? 10 : 11}> 
          <Button 
            variant="contained"
            fullWidth
            style={{backgroundColor:"#f4f4fc", color:"#8e98b0", textTransform:"none"}}
          >
            <PlusCircle style={{ marginRight: 5 }} />
            Add Carrier
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}

export default SelectPreferredCarrierStep
