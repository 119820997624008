import {
  Box,
  Paper,
  Table,
  TableContainer,
  TableFooter,
  TableRow
} from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import { components, constants, useServices } from 'cng-web-lib'

import CngTableBody from 'src/components/table/CngTableBody'
import CngTableHeader from 'src/components/table/CngTableHeader'
import CngToolbarTable from 'src/components/table/CngToolbarTable'
import ConfirmDialog from 'src/components/dialog/ConfirmDialog'
import Loading from 'src/views/vesselschedule/searchschedule/Loading'
import ResultPageTablePagination from 'src/views/vesselschedule/searchschedule/ResultPageTablePagination'
import TcalVsManageScheduleApiUrls from 'src/apiUrls/TcalVsManageScheduleApiUrls'
import TcalVsVesselApiUrls from 'src/apiUrls/TcalVsVesselApiUrls.js'
import VesselTranslationText from './VesselsTranslationText'
import { makeStyles } from '@material-ui/core'
import moment from 'moment'
import pathMap from 'src/paths/PathMap_VesselSchedule'
import { useHistory } from 'react-router-dom'

const {
  table: { useDefaultNotification }
} = components

const {
  filter: { EQUAL }
} = constants

const useStyles = makeStyles((theme) => ({
  div: {
    '& .MuiTable-root': {
      borderSpacing: '0 5px',
      borderCollapse: 'separate',
      '& .MuiTableHead-root .MuiTableRow-head .MuiTableCell-head': {
        backgroundColor: '#fafbfc',
        borderBottom: 'none',
        '&:nth-child(1)': {
          width: 'auto !important'
        },
        '&:nth-child(n + 2):nth-last-child(n + 2)': {
          width: '20% !important'
        }
      },
      '& .MuiTableRow-root[index]': {
        '&:nth-child(even)': {
          backgroundColor: '#fafbfc'
        },
        '& td[class*="MuiTableCell-root"]': {
          border: '1px solid #f1f4f9 !important',
          borderLeft: 'none !important',
          borderRight: 'none !important',
          '&:nth-child(1)': {
            borderLeft: '1px solid #f1f4f9 !important',
            borderRadius: '5px 0 0 5px !important',
            width: 'auto !important'
          },
          '&:nth-child(n + 2):nth-last-child(n + 2)': {
            width: '20% !important'
          },
          '&:nth-last-child(1)': {
            borderRight: '1px solid #f1f4f9 !important',
            borderRadius: '0 5px 5px 0 !important',
            '& .MuiIconButton-root': {
              border: '1px solid #f4f4f7',
              borderRadius: '10px'
            }
          }
        }
      }
    }
  },
  trashIcon: {
    color: theme.palette.text.error,
    width: theme.spacing(2),
    height: theme.spacing(2)
  }
}))

function ManageVesselsTable(props) {
  const {
    success: showSuccessNotification,
    error: showErrorNotification
  } = useDefaultNotification(props.showNotification)

  const history = useHistory()
  const translatedTextsObject = VesselTranslationText()
  const { fetchRecords, deleteRecord } = useServices()
  const [vessels, setVessels] = useState([])
  const [originalVessels, setOriginalVessels] = useState([])
  const [voyages, setVoyages] = useState([])
  const deleteMessage = useRef('')
  const [userProfile, setUserProfile] = useState([])
  const [user, setUser] = useState([])
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(true)
  const [openSearchPanel, setOpenSearchPanel] = useState(false)
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [columns, setColumns] = useState([
    {
      title: translatedTextsObject.name.toUpperCase(),
      active: true,
      field: 'name',
      show: true,
      order: 'desc'
    },
    {
      title: translatedTextsObject.imoNo.toUpperCase(),
      active: false,
      field: 'imoNo',
      show: true
    },
    {
      title: translatedTextsObject.description.toUpperCase(),
      active: false,
      field: 'description',
      show: true,
      type: 'truncate'
    },
    {
      title: translatedTextsObject.user.toUpperCase(),
      active: false,
      field: 'updatedBy',
      show: true,
      lookup: 'user'
    },
    {
      title: translatedTextsObject.lastModified.toUpperCase(),
      active: false,
      field: 'updatedDate',
      show: true,
      type: 'date'
    },
    {
      title: translatedTextsObject.vesselStatus.toUpperCase(),
      active: false,
      field: 'status',
      show: true,
      type: 'status'
    }
  ])

  const classes = useStyles()
  const { showNotification } = props
  const notification = useDefaultNotification(showNotification)

  useEffect(() => {
    fetchRecords.execute(
      `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/user/get`,
      undefined,
      (data) => {
        let FromApi = data.content
        setUserProfile(FromApi)
      },
      (error) => {
        console.log(error)
      }
    )

    fetchRecords.execute(
      `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/user/detail/get`,
      undefined,
      (data) => {
        let FromApi = data.content
        setUser(FromApi)
        FetchVesselsList()
      },
      (error) => {
        console.log(error)
      }
    )

    function FetchVesselsList() {
      fetchRecords.execute(
        TcalVsVesselApiUrls.GET,
        undefined,
        (data) => {
          let FromApi = data.content
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((v, index) => ({
              ...v,
              checked: false,
              seqNo: index + 1
            }))
          setVessels(FromApi)
          setOriginalVessels(FromApi)
          setLoading(false)
        },
        (error) => {
          console.log(error)
          setLoading(false)
        }
      )
    }

    fetchRecords.execute(
      TcalVsManageScheduleApiUrls.GET,
      undefined,
      (data) => {
        let FromApi = data.content
        setVoyages(FromApi)
      },
      (error) => {
        console.log(error)
      }
    )
  }, [])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const comparator = (prop, desc = true) => (a, b) => {
    if (a[prop] === null) {
      return 1
    }
    if (b[prop] === null) {
      return -1
    }
    const order = desc ? -1 : 1
    if (a[prop] < b[prop]) {
      return -1 * order
    }
    if (a[prop] > b[prop]) {
      return 1 * order
    }
    return 0 * order
  }

  const onSortClick = (fieldName) => () => {
    setColumns(
      columns.map((column) => ({
        ...column,
        active: fieldName === column.field,
        order:
          (fieldName === column.field &&
            (column.order === 'desc' ? 'asc' : 'desc')) ||
          undefined
      }))
    )

    setVessels(
      vessels
        .slice()
        .sort(
          comparator(
            fieldName,
            columns.find((column) => column.field === fieldName).order ===
              'desc'
          )
        )
        .map((v, index) => ({
          ...v,
          seqNo: index + 1
        }))
    )
  }

  const onRowCheck = (id) => () => {
    history.push({
      pathname: pathMap.TCAL_VS_MANAGE_VESSELS_VAMEND,
      state: { vesselId: id }
    })
  }

  const onCheckBoxChange = (id) => (e) => {
    let newVessels = [...vessels]
    const index = vessels.findIndex((vessel) => vessel.id === id)
    const vessel = vessels[index]
    newVessels[index] = { ...vessel, checked: !vessel.checked }

    setVessels(newVessels)
    e.stopPropagation()
  }

  const onHeaderCheck = (event) => {
    let newVessels = [...vessels]
    newVessels = newVessels.map((item) => ({
      ...item,
      checked: event.target.checked
    }))
    setVessels(newVessels)
  }

  const onCancel = () => {
    let newVessels = [...vessels]
    newVessels = newVessels.map((item) => ({
      ...item,
      checked: false
    }))
    setVessels(newVessels)
  }

  const onSearchChange = (e) => {
    setSearch(e.target.value)
    filterTextImplementation(e.target.value)
  }

  const onClickSearchPanel = () => {
    setOpenSearchPanel(true)
  }

  const onCloseSearchPanel = () => {
    setSearch('')
    filterTextImplementation('')
    setOpenSearchPanel(false)
    setColumns(
      columns.map((column) => ({
        ...column,
        active: column.field === 'name',
        order: column.field === 'name' ? 'desc' : undefined
      }))
    )
  }

  const onCheckBoxViewColumnChange = (index) => ({ target: { checked } }) => {
    let newColumns = [...columns]
    const column = columns[index]
    newColumns[index] = { ...column, show: !column.show }
    setColumns(newColumns)
  }

  const onDelete = () => {
    const newVessels = vessels
      .filter((vessel) => !vessel.checked)
      .map((v, index) => ({ ...v, seqNo: index + 1 }))
    let deleteVessels = vessels.filter((vessel) => vessel.checked)
    deleteVessels.forEach((v) => {
      delete v.seqNo
      delete v.checked
    })
    setVessels(newVessels)
    setConfirmDialogOpen(false)
    deleteRecord.execute(
      TcalVsVesselApiUrls.MULTIPLE_DELETE,
      { deleteVessels },
      () => {
        showSuccessNotification(
          deleteVessels.length + translatedTextsObject.deleteSuccess
        )
      },
      (error) => {
        showErrorNotification(error)
      }
    )
  }

  const onOpenConfirmDialog = () => {
    let checkedVessels = vessels.filter((vessel) => vessel.checked)

    let checkedVoyages = voyages.filter((vVoyage) => {
      if (checkedVessels.find((v) => v.id === vVoyage.vesselId) === undefined) {
        return false
      }
      vVoyage = vVoyage.voyageLegs.filter(
        (leg) =>
          moment(leg.departureTime).isAfter(moment()) ||
          moment(leg.departureTime).isAfter(moment())
      )
      return vVoyage.length > 0
    })

    deleteMessage.current =
      (checkedVoyages.length > 0 ? translatedTextsObject.d1 + ' ' : '') +
      translatedTextsObject.d2 +
      ' ' +
      vessels.filter((vessel) => vessel.checked === true).length +
      ' ' +
      translatedTextsObject.items +
      ' ? ' +
      translatedTextsObject.d3
    setConfirmDialogOpen(true)
  }

  const onAddButtonClick = () => {
    history.push(pathMap.TCAL_VS_MANAGE_VESSELS_ADD)
  }

  function filterTextImplementation(filterText) {
    let newVessels = [...originalVessels]
    newVessels = newVessels
      .filter(
        (item) =>
          !filterText ||
          item.name.toUpperCase().includes(filterText.toUpperCase()) ||
          (item.imoNo !== null &&
            item.imoNo.toUpperCase().includes(filterText.toUpperCase()))
      )
      .map((v, index) => ({ ...v, seqNo: index + 1 }))

    setVessels(newVessels)
  }

  return (
    <Paper>
      <Loading loading={loading} />
      <ConfirmDialog
        isConfirmDialogOpen={confirmDialogOpen}
        closeDialog={() => setConfirmDialogOpen(false)}
        confirmDialog={onDelete}
        content={deleteMessage.current}
        okMsg={translatedTextsObject.yesDelete}
        cancelMsg={translatedTextsObject.noTakeMeBack}
        title={translatedTextsObject.deleteRecords}
      />
      {!loading ? (
        <Box m='8px' className={classes.div}>
          <CngToolbarTable
            data={vessels}
            dataType='Vessel'
            onCancel={onCancel}
            openSearchPanel={openSearchPanel}
            searchDesc={translatedTextsObject.searchDesc}
            search={search}
            onSearchChange={onSearchChange}
            onCloseSearchPanel={onCloseSearchPanel}
            page={page}
            rowsPerPage={rowsPerPage}
            onClickSearchPanel={onClickSearchPanel}
            columns={columns}
            onCheckBoxViewColumnChange={onCheckBoxViewColumnChange}
            classes={classes}
            showNotification={showNotification}
            addButtonText={translatedTextsObject.addVessels}
            onAddButtonClick={onAddButtonClick}
            onOpenConfirmDialog={onOpenConfirmDialog}
            user={user}
            userProfile={userProfile}
            viewOnly={false}
          />
          <TableContainer>
            <Table>
              <CngTableHeader
                data={vessels}
                onHeaderCheck={onHeaderCheck}
                columns={columns}
                onSortClick={onSortClick}
                showAction={true}
                showCheckbox={true}
              />
              <CngTableBody
                data={vessels}
                page={page}
                rowsPerPage={rowsPerPage}
                columns={columns}
                onRowClick={onRowCheck}
                onRowCheck={onCheckBoxChange}
                user={user}
                userProfile={userProfile}
                showAction={true}
                showCheckbox={true}
              />
              <TableFooter>
                <TableRow>
                  <ResultPageTablePagination
                    recordLength={vessels.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Box>
      ) : null}
    </Paper>
  )
}

export default ManageVesselsTable
