import { Box, Typography } from '@material-ui/core'
import React, { useContext, useEffect } from 'react'
import { Yup, components, useServices } from 'cng-web-lib'

import { Alert } from '@material-ui/lab'
import AmendVesselDialogContext from './AmendVesselDialogContext'
import DiscardButton from 'src/components/button/DiscardButton.js'
import SubmitButton from 'src/components/button/SubmitButton.js'
import TcalVsVesselApiUrls from 'src/apiUrls/TcalVsVesselApiUrls.js'
import VesselsTranslationText from '../VesselsTranslationText'
import classNames from 'classnames'
import pathMap from 'src/paths/PathMap_VesselSchedule'
import { useHistory } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

const {
  form: {
    CngViewForm,
    adapter: {
      useFormAdapter:{ useField, useFormikContext }
    },
    field: { CngTextField, CngSelectField }
  },
  table: { useDefaultNotification }
} = components

const AsteriskRedCngTextField = withStyles({
  root: {
    '& .MuiFormLabel-asterisk.MuiInputLabel-asterisk': {
      color: 'red'
    }
  }
})(CngTextField)

const AsteriskRedCngSelectField = withStyles({
  root: {
    '& .MuiFormLabel-asterisk.MuiInputLabel-asterisk': {
      color: 'red'
    }
  }
})(CngSelectField)

function AmendVesselForm(props) {
  const { updateRecord } = useServices()
  const dialogContext = useContext(AmendVesselDialogContext)
  const {
    closeDialog,
    reloadTime,
    showNotification,
    form: { isSubmitting, setSubmitting },
    vId,
    vesselName,
    imoNo,
    vesselStatus,
    remarks,
    partyId,
    version,
    showWarningMsg
  } = dialogContext
  const {
    success: showSuccessNotification,
    error: showErrorNotification
  } = useDefaultNotification(showNotification)
  const history = useHistory()
  const translatedTextsObject = VesselsTranslationText()

  function onSubmit(data) {
    function onSuccess() {
      setSubmitting(false)
      closeDialog()
      reloadTime()
      showSuccessNotification(`${translatedTextsObject.changeSuccess}`)

      history.push({
        pathname: pathMap.TCAL_VS_MANAGE_VESSELS_VIEW
      })
    }

    function onError(error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.errorMessages &&
        error.response.data.errorMessages.length > 0
      ) {
        showErrorNotification(error.response.data.errorMessages)
      } else {
        showErrorNotification(error.message)
      }
      console.log('amend-vessel error', error.message)
      setSubmitting(false)
    }

    setSubmitting(true)
    updateRecord.execute(
      TcalVsVesselApiUrls.PUT,
      {
        ...data,
        name: data['name'].trim()
      },
      onSuccess,
      onError
    )
  }

  return (

    <CngViewForm
      formikProps={{
        initialValues: {  ...initialValues},
        validationSchema: validationSchema,
        onSubmit: onSubmit,
        validateOnChange: false
      }}
      bodySection={
      <FormBody
            closeDialog={closeDialog}
            isSubmitting={isSubmitting}
            vId={vId}
            vesselName={vesselName}
            imoNo={imoNo}
            vesselStatus={vesselStatus}
            remarks={remarks}
            partyId={partyId}
            version={version}
            showWarningMsg={showWarningMsg}
          />
    }
    fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
  />
  )
}

function FormBody({
  closeDialog,
  isSubmitting,
  vId,
  vesselName,
  imoNo,
  vesselStatus,
  remarks,
  partyId,
  version,
  showWarningMsg
}) {
  const translatedTextsObject = VesselsTranslationText()
  const [, , { setValue: setVesselId }] = useField('id')
  const [, , { setValue: setVesselName }] = useField('name')
  const [, , { setValue: setImoNo }] = useField('imoNo')
  const [, , { setValue: setVesselStatus }] = useField('status')
  const [, , { setValue: setRemark }] = useField('description')
  const [, , { setValue: setPartyId }] = useField('partyId')
  const [, , { setValue: setVersion }] = useField('version')

  useEffect(() => {
    setVesselId(vId)
    setVesselName(vesselName)
    setImoNo(imoNo)
    setVesselStatus(vesselStatus)
    setRemark(remarks === null ? '' : remarks)
    setPartyId(partyId)
    setVersion(version)
  }, [])

  return (
    <Box m={1.5}>
      <Box mb={2}>
        {showWarningMsg ? (
          <Alert
            severity='warning'
            classes={{
              // root: classNames('ng-alert', 'ng-alert-warning'),
              root: classNames('warningBox'),
              // message: classNames('ng-alert-message'),
              icon: classNames('ng-preceeding-icon')
            }}
            style={{ backgroundColor: '#FFFBF5', borderColor: '#FEF4E2' }}
          >
            <Typography variant='body2' gutterBottom className='warningText'>
              {translatedTextsObject.w1}
              <br /> {translatedTextsObject.w2}
            </Typography>
          </Alert>
        ) : null}
      </Box>
      <Box my={1}>
        <Box display='flex'>
          <Box flexGrow={1} />
          <Box width={0.5} mr={1.5}>
            <AsteriskRedCngTextField
              name='name'
              label={translatedTextsObject.name}
              inputProps={{ maxLength: 100 }}
              required
            />
          </Box>
          <Box width={0.5}>
            <CngTextField
              name='imoNo'
              label={translatedTextsObject.imoNo}
              inputProps={{ maxLength: 15 }}
            />
          </Box>
        </Box>
      </Box>
      <Box my={1}>
        <AsteriskRedCngSelectField
          name='status'
          label={
            translatedTextsObject.vessel +
            ' ' +
            translatedTextsObject.vesselStatus
          }
          items={[
            { text: translatedTextsObject.active, value: 'true' },
            { text: translatedTextsObject.inactive, value: 'false' }
          ]}
          required
        />
      </Box>
      <Box my={1}>
        <CngTextField
          type='text'
          name='description'
          label={translatedTextsObject.description}
          multiline
          rows={4}
          inputProps={{ maxLength: 255 }}
        />
      </Box>
      <Box display='flex' flexDirection={'row-reverse'} mt={2}>
        <SubmitButton isSubmitting={isSubmitting}>
          {translatedTextsObject.saveChanges}
        </SubmitButton>
        <Box mr={1}>
          <DiscardButton onClick={closeDialog} disabled={isSubmitting} />
          {/* <CancelButton onClick={closeDialog} disabled={isSubmitting} /> */}
        </Box>
      </Box>
    </Box>
  )
}

const initialValues = {
  id: -1,
  name: '',
  imoNo: '',
  status: 'true',
  description: '',
  partyId: '',
  version: 0
}

const validationSchema = Yup.object({
  name: Yup.string().required('Vessel Name is required')
})

export default AmendVesselForm
