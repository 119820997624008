import { Box, Paper } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import { components, constants, useServices } from 'cng-web-lib'
import { useHistory, useLocation } from 'react-router-dom'

import AddScheduleButtonComponent from './AddScheduleButtonComponent'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import CngBackdrop from '../../searchschedule/cngcomponent/CngBackDrop'
import ConfirmDialog from 'src/components/dialog/ConfirmDialog'
import GeneralStepperComponent from 'src/views/common/ui/GeneralStepperComponent'
import ManageScheduleTranslationText from '../ManageScheduleTranslationText'
import RemarksComponent from './RemarksComponent'
import TcalVsManageScheduleApiUrls from 'src/apiUrls/TcalVsManageScheduleApiUrls.js'
import TcalVsVesselApiUrls from 'src/apiUrls/TcalVsVesselApiUrls.js'
import Utils from 'src/views/common/utils/Utils'
import VesselVoyageComponent from './VesselVoyageComponent'
import WarningDialog from 'src/components/dialog/WarningDialog'
import pathMap from 'src/paths/PathMap_VesselSchedule'
import { v4 as uuid } from 'uuid'

// import Api from '../shared/api'

const {
  form : {
    adapter: {
      useFormAdapter:{ useField, useFormikContext }
    }
  },
  table: { useDefaultNotification },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  ...VesselVoyageComponent.initialValues
  // saveDraft: true,
  // bookingId: '',
  // setFreightBookingId: '',
  // bookingStatus: '',
  // ...BookingTypeComponent.initialValues,
  // ...ScheduleComponent.initialValues,
  // ...ShipmentComponent.initialValues,
  // ...ContainerComponent.initialValues,
  // ...ContactDetailsComponent.initialValues,
  // ...PaymentInstructionComponent.initialValues,
  // ...CargoComponent.initialValues
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES }
  // makeValidationSchema: DEFAULT_VALIDATION_SCHEMA
  //makeValidationSchema: makeValidationSchema}
}

const {
  CodeMaintenanceType,
  filter: { EQUAL }
} = constants

function FormFields(props) {
  const history = useHistory()
  const location = useLocation()
  const voyageData = location.state
    ? location.state.hasOwnProperty('voyageData')
      ? location.state.voyageData
      : null
    : null

  const [searchCriteria, ,] = useField('searchCriteria')

  const msTranslatedTextsObject = ManageScheduleTranslationText()
  const uiTranslatedTextsObject = CalistaUiComponentTranslationText()

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [warningDialogOpen, setWarningDialogOpen] = useState(false)
  const [shouldRender, setShouldRender] = useState(false)
  const [loading, setLoading] = useState(false)
  const isEdit = useRef(false)
  const { errors, submitForm, setFieldValue } = useFormikContext()
  const { fetchRecords } = useServices()
  const [voyageId, setVoyageId] = useState(0)

  const addScheduleStep = [
    msTranslatedTextsObject.addScheduleStep1,
    msTranslatedTextsObject.addScheduleStep2,
    msTranslatedTextsObject.addScheduleStep3
  ]

  const amendScheduleStep = [
    msTranslatedTextsObject.amendScheduleStep1,
    msTranslatedTextsObject.amendScheduleStep2,
    msTranslatedTextsObject.amendScheduleStep3
  ]

  function isEmpty(obj) {
    return Object.keys(obj).length === 0
  }

  const onConfirmNext = async () => {
    if (!isEmpty(errors)) {
      setWarningDialogOpen(true)
      //alert('There is error in your form. Please correct the error before submit the form')
    }
    submitForm()
  }

  const onDiscard = () => {
    setConfirmDialogOpen(true)
  }

  const cancelSchedule = () => {
    if (searchCriteria.value != null) {
      //amend from carrier search schedule, return to search schedule with searchCriteria
      history.push({
        pathname: pathMap.CARRIER_SEARCH_SCHEDULE_RESULT,
        state: searchCriteria.value
      })
    } else if (voyageId > 0) {
      //amend from view schedule, return to view schedule
      history.push({
        pathname: pathMap.TCAL_VS_MANAGE_SCHEDULE_VIEW,
        state: {
          voyageId: voyageData.id,
          hideAmend: false
        }
      })
    } else {
      //add schedule, return to manage schedule table list
      history.push(pathMap.TCAL_VS_MANAGE_SCHEDULE_LIST)
    }
  }

  function populateData(voyageData) {
    if (voyageData != null && !isEmpty(voyageData)) {
      const dataArr = Object.entries(voyageData)
      dataArr.forEach(([key, val]) => {
        //console.log('key: ' + key + ' val: ' + val)
        if (key == 'id') {
          setVoyageId(voyageData.id)
        }
        // setFieldValue(key, (dateFields.includes(key) ? Utils.formatString(val, 'YYYY-MM-DD') : val))
        setFieldValue(key, val)
      })

      isEdit.current = true
    }
  }

  useEffect(() => {
    setLoading(true)
    // alert(JSON.stringify(voyageData))
    setFieldValue(
      'searchCriteria',
      location.state
        ? location.state.hasOwnProperty('searchCriteria')
          ? location.state.searchCriteria
          : null
        : null
    )

    if (voyageData !== null) {
      populateData(voyageData)
    }

    setLoading(false)
    setShouldRender(true)
  }, [voyageData])

  if (!shouldRender) {
    return null
  }

  return (
    <Box>
      {/* {'Error :' + JSON.stringify(errors)} */}
      {/* <br/> */}
      {/* {'Touched :' + JSON.stringify(touched)} */}

      <CngGridItem xs={12} sm={9} shouldHide={loading ? false : true}>
        <CngBackdrop loading={loading} />
      </CngGridItem>

      <Paper>
        <Box alignItems='center' pr={15} pl={15}>
          <GeneralStepperComponent steps={isEdit.current ? amendScheduleStep : addScheduleStep} activeStep={0} />
        </Box>

        <Box p={5} className='page-content'>
          <Box>
            <VesselVoyageComponent.FormBody isEdit={isEdit.current} />
          </Box>
          <Box pt={5}>
            <RemarksComponent.FormBody />
          </Box>
        </Box>
      </Paper>
      <Paper className='sticky-footer'>
        <Box alignItems='center'>
          <AddScheduleButtonComponent
            onConfirmNext={onConfirmNext}
            onDiscard={onDiscard}
          />
        </Box>
      </Paper>
      <ConfirmDialog
        isConfirmDialogOpen={confirmDialogOpen}
        closeDialog={() => setConfirmDialogOpen(false)}
        confirmDialog={cancelSchedule}
        content={
          voyageId == 0
            ? msTranslatedTextsObject.dialogDiscardScheduleContent
            : msTranslatedTextsObject.dialogDiscardAmendScheduleContent
        }
        okMsg={uiTranslatedTextsObject.ok}
        cancelMsg={uiTranslatedTextsObject.cancel}
        title={msTranslatedTextsObject.dialogDiscardScheduleTitle}
      />

      <WarningDialog
        isDialogOpen={warningDialogOpen}
        closeDialog={() => setWarningDialogOpen(false)}
        okDialog={() => setWarningDialogOpen(false)}
        content={uiTranslatedTextsObject.formErrorWarning}
        okMsg={uiTranslatedTextsObject.ok}
      />
    </Box>
  )
}

const AddScheduleFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  FormFields: FormFields
  // toClientDataFormat: toClientDataFormat,
  // toServerDataFormat: toServerDataFormat
})

export default AddScheduleFormProperties
