import * as CalistaConstant from 'src/constants/CalistaConstant'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Tooltip
} from '@material-ui/core'
import { DateTimeFormatter, components } from 'cng-web-lib'
import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import AccordionFooterControlComponent from 'src/views/common/ui/AccordionFooterControlComponet'
import AccordionHeaderComponent from 'src/views/common/ui/AccordionHeaderComponent'
import AddIcon from '@material-ui/icons/Add'
import Alert from '@material-ui/lab/Alert'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import DeleteIcon from '@material-ui/icons/Delete'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import ManageScheduleTranslationText from '../ManageScheduleTranslationText'
import PortSelectAutoComplete from 'src/views/vesselschedule/searchschedule/PortSelectAutoComplete'
import TransportationModeSelect from 'src/components/select/TransportationModeSelect'
import Utils from 'src/views/common/utils/Utils'
import VesselAutocompleteField from 'src/components/autocomplete/VesselAutocompleteField'
import moment from 'moment'
import { prop } from 'ramda'
import { v4 as uuid } from 'uuid'
import { withStyles } from '@material-ui/core/styles'
import { useFieldArray } from 'react-hook-form'

const {
  form: {
    adapter: {
      useFormAdapter:{ useField, useFormikContext }
    },
    field: {
      CngTextField,
      CngDateField,
      CngDateTimeField,
      CngCodeMasterAutocompleteField
    }
  },
  CngGridItem
} = components

// const initialValues = Object.freeze({
//     waypoint: "",
//     transportationMode: "",
//     port: "",
//     departDate: "",
//     departTime: "",
//     cyCutoffDate: "",
//     cyCutoffTime: "",
//     transitTime: "",
//     scheduleExpanded: false
// })

const AsteriskRedCngDateTimeField = withStyles({
  root: {
    '& .MuiFormLabel-asterisk.MuiInputLabel-asterisk': {
      color: 'red'
    }
  }
})(CngDateTimeField)


const initialValues = Object.freeze({
  voyageLegs: [
    {
      seqNo: '',
      id: '', // pk
      scheduleId: '', //for react key reference
      waypoint: '',
      transportMode: '',
      portCode: '',
      countryCode: '',
      portKeyRef: '',
      departureDate: '',
      departureTime: '',
      arrivalDate: '',
      arrivalTime: '',
      cyCutoffDate: '',
      cyCutoffTime: '',
      transitTime: '',
      terminal: '',
      version: 0,
      scheduleExpanded: false,
      prevDepartureTime: '',
      prevPortCode: ''
    }
  ]
})

const FormBody = (props) => {
  // const [, ,] = useField('waypoint')
  // const [, ,] = useField('transportMode')
  // const [, ,] = useField('portCode')
  // const [, ,] = useField('departureDate')
  // const [, ,] = useField('departureTime')
  // const [, ,] = useField('cyCutoffDate')
  // const [, ,] = useField('cyCutoffTime')
  // const [, ,] = useField('transitTime')

  const YYYY_MM_DD_HH_MM = 'YYYY-MM-DD HH:mm'
  const DD_MM_YYYY_HH_MM = 'DD-MM-YYYY HH:mm'
  const DD_MM_YYYY_HH_MM_SS = 'DD-MM-YYYY HH:mm:ss'
  const backendDateFormat = 'YYYY-MM-DDTHH:mm:ss'
  //2021-05-12T14:04:00.000+0000

    const { setStateVoyageLegs } = props
  const translatedTextsObject = ManageScheduleTranslationText()
  const { submitForm, setFieldValue, errors, touched } = useFormikContext()

  const voyageLegItem = {
    seqNo: '',
    id: '', // this is used for backend
    legId: uuid(), //used in frontend only,as the key of each card
    scheduleId: '', //for react key reference
    waypoint: '',
    transportMode: '',
    portCode: '',
    countryCode: '',
    portKeyRef: '',
    departureDate: '',
    departureTime: '',
    arrivalDate: '',
    arrivalTime: '',
    cyCutoffDate: '',
    cyCutoffTime: '',
    transitTime: '',
    terminal: '',
    version: 0,
    scheduleExpanded: false,
    prevDepartureTime: '',
    prevPortCode: ''
  }

  // useState voyageLegList for handle voyageLegList dynamically
  const [voyageLegList, setVoyageLegList] = useState([])
  //useField containers for handle formik valiation and submission
  // const [voyageLegsField, , { setValue: setVoyageLegsField }] = useField(
  //   'voyageLegs'
  // )
  const { fields } = useFieldArray({name: "voyageLegs"});
  const [shouldRender, setShouldRender] = useState(false)
  const draftVoyageLegId = useRef(-1)
  const isEdit = useRef(false)
  const [edit, setEdit] = useState(false)
  // const [, , ] = useField('testDate')
  const dateFields = ['cyCutoffTime', 'arrivalTime', 'departureTime']

  const handleDetailsExpand = (idx) => () => {
    console.log('voyageLegList > ' + idx + ' ' + JSON.stringify(voyageLegList))
    const isScheduleExpanded = voyageLegList[idx].scheduleExpanded
    console.log(
      'voyageLegList isScheduleExpanded > ' + idx + ' ' + isScheduleExpanded
    )
    const temp = [...voyageLegList]
    temp[idx].scheduleExpanded = !isScheduleExpanded
    console.log(temp)
    // console.log('voyageLegList temp[0].scheduleExpanded> ' + idx + ' ' + temp[0].scheduleExpanded)
    // console.log('voyageLegList temp[1].scheduleExpanded> ' + idx + ' ' + temp[1].scheduleExpanded)

    setVoyageLegList([].concat([], temp))
  }

  const handleInputChange = (e, index) => {
    // console.log('handleInputChange : ' + JSON.stringify(e) )
    const { name, value } = e.target
    const list = [...voyageLegList]
    // const list = [...voyageLegsField]
    list[index][name.split('.')[1]] = value

    // alert('name ' + name + ' - valu : ' + value)
    // setVoyageLegList(list)
    // setVoyageLegsField(list)

    setAllVoyageLegs(list)
  }

  const handleAutocompleteChange = (val, evt, index, name) => {
    // console.log('handleAutocompleteChange val > ' + JSON.stringify(val))
    // console.log('handleAutocompleteChange evt > ' + JSON.stringify(evt))

    const list = [...voyageLegList]

    if (evt) {
      const { value } = evt
      console.log('handleAutocompleteChange evt : ' + evt )

      // // const list = [...voyageLegsField]
      // alert('list[index][name] : ' + index + "." + name)
      list[index][name] = value

      if (name == 'portCode') {
        list[index]['countryCode'] = evt.data.countryCode
      }
      // console.log('handleInputChange : ' + JSON.stringify(list) )
      // alert('voyageLegList : ' + JSON.stringify(list))
      syncPort(list, index, value)
      setAllVoyageLegs(list)
    } else {
      console.log('handleAutocompleteChange empty. ' )

      list[index][name] = ''
      list[index]['countryCode'] = ''
      syncPort(list, index, '')

      //comment out to avoid losing focus on the portAutoComplete when clear value, 
      //the voyageLegs value still correct because of the CngAutocomplete behavior 
      // setAllVoyageLegs(list)
    }

  }

  const handleValueChange = (evt, index, name) => {
    if (evt) {
      // console.log('handleValueChange evt : ' + evt )
      const list = [...voyageLegList]
      // // const list = [...voyageLegsField]
      // alert('list[index][name] : ' + index + "." + name)
      list[index][name] = evt
      // console.log('handleValueChange : ' + JSON.stringify(list) )
      // alert('voyageLegList : ' + JSON.stringify(list))

      // setVoyageLegList(list)
      // setVoyageLegsField(list)

      setAllVoyageLegs(list)
    }
  }

  
  const handleDateChange = (evt, index, name) => {
    // console.log('handleDateChange >' + evt)
    const list = [...voyageLegList]

    if (evt) {  
      // // const list = [...voyageLegsField]
      let date = moment(evt).format(DD_MM_YYYY_HH_MM_SS)
      list[index][name] = date

      // replace defualt onChange with handleDateChange causing form validate on change not working
      // use setFieldValue to enable validate after date value change
      // console.log('name > ' + name + ' date > ' + moment(date).isValid())
        setFieldValue(`voyageLegs[${index}].${name}`,date)
   
      // let fieldName = 'voyageLegs['+index+'].'+ name
      // setFieldValue(fieldName, evt, true)

       updateDateAndTransitTime(list)
       syncDate(list, index, name, date)

      // setVoyageLegList(list)
      // setVoyageLegsField(list)
    } else{
        //if evt == null
        //set date field to '' to trigger required validation instead of typeError validation
        list[index][name] = ''
        syncDate(list, index, name, '')
        setFieldValue(`voyageLegs[${index}].${name}`, '', true)
    }

    setAllVoyageLegs(list)
  }

  // handle click event of the Remove button
  const handleRemoveSchedule = (index) => {
    const list = [...voyageLegList]
    // const list = [...voyageLegsField]
    list.splice(index, 1)

    updateVoyageLegList(list)
    // setVoyageLegList(list)
    // setVoyageLegsField(list)

    setAllVoyageLegs(list)
  }

  // handle click event of the Add button
  const handleAddSchedule = (index) => {
    const addVoyageLeg = { ...voyageLegItem }
    addVoyageLeg.scheduleId = draftVoyageLegId.current
    addVoyageLeg.portKeyRef = uuid()
    draftVoyageLegId.current = draftVoyageLegId.current - 1

    // const newList = [...voyageLegList, addSchedule]
    const newList = [...voyageLegList]
    newList.splice(index + 1, 0, addVoyageLeg)
    updateVoyageLegList(newList)

    // setVoyageLegList(newList)
    // setVoyageLegsField(newList)
    setAllVoyageLegs(newList)
  }

  //handle click event of the Copy buttion
  const handleCopySchedule = (index) => {
    // const list = [...voyageLegList]
    const copyVoyageLeg = voyageLegList.slice(index, index + 1)[0]
    // alert('index : ' + index + ", " + JSON.stringify(copyContainer))

    const copyVoyageLegItem = { ...copyVoyageLeg }
    copyVoyageLegItem.scheduleId = draftVoyageLegId.current
    copyVoyageLegItem.portKeyRef = uuid()
    copyVoyageLegItem.id = ''
    copyVoyageLegItem.legId = uuid()
    draftVoyageLegId.current = draftVoyageLegId.current - 1

    // const newList = [...voyageLegList, copyVoyageLegItem]
    const newList = [...voyageLegList]
    newList.splice(index + 1, 0, copyVoyageLegItem)

    updateVoyageLegList(newList)
    // setVoyageLegList(newList);
    // setVoyageLegsField(newList)

    setAllVoyageLegs(newList)
  }

  const handleMoveUp = (index) => {
    const list = [...voyageLegList]
    let currentItem = list[index]
    let swapItem = list[index - 1]

    // if(index-1 == 0){
    //     currentItem.transitTime = swapItem.transitTime
    //     swapItem.transitTime = ""
    // }

    list[index] = swapItem
    list[index - 1] = currentItem

    updateVoyageLegList(list)
    // setVoyageLegList(list)
    // setVoyageLegsField(list)

    setAllVoyageLegs(list)
  }

  const handleMoveDown = (index) => {
    const list = [...voyageLegList]
    let currentItem = list[index]
    let swapItem = list[index + 1]

    // if(index == 0){
    //     swapItem.transitTime = currentItem.transitTime
    //     currentItem.transitTime = ""
    // }

    list[index] = swapItem
    list[index + 1] = currentItem

    updateVoyageLegList(list)
    // setVoyageLegList(list)
    // setVoyageLegsField(list)

    setAllVoyageLegs(list)
  }

  const updateVoyageLegList = (list) => {
    resetWaypointAndPrevDepartureTime(list)
    updateDateAndTransitTime(list)
  }

  const resetWaypointAndPrevDepartureTime = (list) => {
    list.forEach(function (voyageLeg, index) {
      if (index == 0) {
        voyageLeg.waypoint = CalistaConstant.SCHEDULE_WAYPOINT_START
        voyageLeg.arrivalDate = ''
        voyageLeg.arrivalTime = ''
        voyageLeg.prevDepartureTime = ''
        voyageLeg.prevPortCode = ''
      } else if (list.length > 1 && index == list.length - 1) {
        voyageLeg.waypoint = CalistaConstant.SCHEDULE_WAYPOINT_FINAL
        voyageLeg.transportMode = ''
        voyageLeg.transitTime = ''
      } else {
        voyageLeg.waypoint = CalistaConstant.SCHEDULE_WAYPOINT_STOPOVER
      }
      voyageLeg.seqNo = index + 1

      if (index + 1 < list.length) {
        list[index + 1]['prevDepartureTime'] = list[index]['departureTime']
        list[index + 1]['prevPortCode'] = list[index]['portCode']
      }
    })

    if (list.length > 1) {
    }
  }

  const updateDateAndTransitTime = (list) => {
    console.log("list > " + list)
    list.forEach(function (voyageLeg, index) {
      //update transit time
      // if(moment(voyageLeg.departureTime, moment.ISO_8601, true).isValid())
      let transitTime = 0
      if (index <= list.length - 2) {
        // alert('updateDateAndTransitTime')
        // console.log('updateDateAndTransitTime depart > ' + voyageLeg.departureTime)
        let depart = moment(voyageLeg.departureTime, DD_MM_YYYY_HH_MM_SS)
        // console.log('updateDateAndTransitTime depart > ' + depart + ' at seq : ' + (index +1))
        if (index < list.length - 1) {
          let arrive = moment(list[index + 1].arrivalTime, DD_MM_YYYY_HH_MM_SS)
          // console.log('updateDateAndTransitTime arrive > ' + arrive + ' at seq : ' + (index +2))
          if (depart.isValid() && arrive.isValid()) {
            // transitTime = arrive.diff(depart, 'days')
            transitTime = arrive
              .startOf('day')
              .diff(depart.startOf('day'), 'days')
            // console.log('updateDateAndTransitTime arrive > ' + transitTime)
          }
        }
      }
      //arrive date of waypoint 2 must be later than depart date of waypoint 1
      // if (transitTime > 0) {
        voyageLeg.transitTime = transitTime
      // }
    })
  }

  const syncDate = (list, index, name, date) => {
    if (name == 'departureTime') {
      if (moment(date, DD_MM_YYYY_HH_MM_SS).isValid()) {
        if (index + 1 < list.length) {
          list[index + 1]['prevDepartureTime'] = date
        }

        // replace defualt onChange with handleDateChange causing form validate on change not working
        // use setFieldValue to enable validate after date value change
        // trigger validation for the arrivalDate in following waypoint if departureDate changed
        setFieldValue(`voyageLegs[${index}][departureTime]`, date, true)
      }
    } else if (name == 'arrivalTime') {
      list[index]['prevDepartureTime'] = list[index - 1]['departureTime']
    }
  }

  const syncPort = (list, index, port) => {
    if (index + 1 < list.length) {
      list[index + 1]['prevPortCode'] = port
    }
  }

  const setAllVoyageLegs = (list) => {
    console.log('handleAutocompleteChangesetAllVoyageLegs ')
    setVoyageLegList(list)
   // setVoyageLegsField(list)
    setFieldValue('voyageLegs', list)

    // to get back data when passing back to previous from.
    // ideally show move setVoyageLegsField to AddScheuleDetailsFormProperties and set data to setVoyageLegsField
    setStateVoyageLegs(list)
  }

  const convertDate = (date) => {
    console.log('convertDate value >' + date + '<')
    let returnDate = ''

    if (date !== null) {
      if(date){
        console.log('convertDate > valid date')

        if (moment(date, DD_MM_YYYY_HH_MM_SS, true).isValid()){
          console.log('convertDate > return same date')
          //date from amend/state 12-05-2021 10:00:00
          returnDate = date
        } else {
          console.log('convertDate > convert date')
          //date from backend 2021-05-12T14:04:00.000+0000
          returnDate = moment(date).format(DD_MM_YYYY_HH_MM_SS)
        }
      }
    }
    console.log('convertDate returnDate >' + returnDate + '<')
    return returnDate
  } 

  const location = useLocation()
  const voyageData = location.state ? location.state.voyageData : null
  const voyageLegsExist = voyageData.hasOwnProperty('voyageLegs')

  useEffect(() => {
    //alert(JSON.stringify(voyageData.voyageLegs))
    if (!voyageLegsExist) {
      // alert(JSON.stringify(voyageLegsField))
      const voyLeg = { ...voyageLegItem }
      // sch.waypoint = CalistaConstant.SCHEDULE_WAYPOINT_START
      voyLeg.scheduleId = draftVoyageLegId.current
      voyLeg.portKeyRef = uuid()
      voyLeg.legId = uuid()
      draftVoyageLegId.current = draftVoyageLegId.current - 1

      const voyLeg2 = { ...voyageLegItem }
      voyLeg2.scheduleId = draftVoyageLegId.current
      voyLeg2.portKeyRef = uuid()
      voyLeg2.legId = uuid()
      draftVoyageLegId.current = draftVoyageLegId.current - 1

      const initialList = [voyLeg, voyLeg2]
      resetWaypointAndPrevDepartureTime(initialList)
      setVoyageLegList(initialList)
      //setVoyageLegsField(initialList)
      setFieldValue('voyageLegs', initialList)

      setAllVoyageLegs(initialList)
    } else {
      // populateData(voyageData)
      // setVoyageLegsField(voyageLegsField.value)
      // setEdit(true)

      // console.log('useEffect stateVoyageLegs > ' + JSON.stringify(voyageData.voyageLegs))

      let voyageLegLength = voyageData.voyageLegs.length
      const voyageLegsWithExpanded = voyageData.voyageLegs
        .sort((a, b) => a.seqNo - b.seqNo)
        .map((v, i) => ({
          seqNo: v.seqNo,
          id: v.id, // pk
          legId: uuid(),
          scheduleId: v.id, //for react key reference
          transportMode: v.transportMode,
          portCode: v.portCode,
          // countryCode: "",
          portKeyRef: uuid(),
          // departureTime:
          //   i != voyageLegLength - 1
          //     ? (v.departureTime != null || v.departureTime != '')
          //       ? moment(v.departureTime, DD_MM_YYYY_HH_MM_SS, true).isValid() ? v.departureTime : moment(v.departureTime).format(DD_MM_YYYY_HH_MM_SS)
          //       : ''
          //     : '', // clear departure for last item
          departureTime:
            i != voyageLegLength - 1
              ? convertDate(v.departureTime)
              : '',
          arrivalTime:
            i != 0 
            ? convertDate(v.arrivalTime)
            : '', // clear arrival for first item
          // cyCutoffTime: v.cyCutoffTime != null
          //   ? moment(v.cyCutoffTime, DD_MM_YYYY_HH_MM_SS, true).isValid() ? v.cyCutoffTime : moment(v.cyCutoffTime).format(DD_MM_YYYY_HH_MM_SS)
          //   : '',
          cyCutoffTime: convertDate(v.cyCutoffTime),
          transitTime: v.transitTime,
          terminal: v.terminal,
          prevDepartureTime: '',
          prevPortCode: '',
          scheduleExpanded: false,
          version: v.version
        }))
      console.log('voyageLegsWithExpanded > ' + JSON.stringify(voyageLegsWithExpanded))
      updateVoyageLegList(voyageLegsWithExpanded)
      // setVoyageLegList(voyageLegsWithExpanded)
      // setVoyageLegsField(voyageLegsWithExpanded)

      setAllVoyageLegs(voyageLegsWithExpanded)
      // setShouldRender(true)
    }

    setShouldRender(true)
  }, [])

  //render again when containersField.value has been set
  // useEffect(() => {
  //     alert('voyageLegsField.value > ' + JSON.stringify(voyageLegsField.value))
  //     // if (isEdit.current) {
  //         const voyageLegsWithExpanded = voyageLegsField.value.map((v) => (
  //             {
  //             seqNo: v.seqNo,
  //             id: v.id, // pk
  //             scheduleId: v.id, //for react key reference
  //             transportMode: v.transportMode,
  //             portCode: v.portCode,
  //             // countryCode: "",
  //             portKeyRef: uuid(),
  //             departureTime: v.departureTime ? moment(v.departureTime).format('YYYY-MM-DD HH:mm:ss') : "",
  //             arrivalTime: v.arrivalTime ? moment(v.arrivalTime).format('YYYY-MM-DD HH:mm:ss') : "",
  //             cyCutoffTime: v.cyCutoffTime ? moment(v.cyCutoffTime).format('YYYY-MM-DD HH:mm:ss') : "",
  //             transitTime: v.transitTime,
  //             prevDepartureTime : "",
  //             prevPortCode: "",
  //             scheduleExpanded: false
  //         }))
  //         alert('voyageLegsWithExpanded > ' + JSON.stringify(voyageLegsWithExpanded))
  //         resetWaypointAndPrevDepartureTime(voyageLegsWithExpanded)
  //         setVoyageLegList(voyageLegsWithExpanded)
  //         setVoyageLegsField(voyageLegsWithExpanded)
  //         setShouldRender(true)
  //     // }
  // }, [edit])

  // function populateData(data) {
  //     alert('populateData > ' + JSON.stringify(data))
  //     if (data != null && !isEmpty(data)) {
  //         const dataArr = Object.entries(data);
  //         dataArr.map(([key, val]) => {
  //             console.log("key: " + key + " val: " + JSON.stringify(val))
  //             return setFieldValue(key, (dateFields.includes(key) ? Utils.formatString(val, 'YYYY-MM-DD') : val))
  //             // return setFieldValue(key, val)
  //         });
  //     }
  // }

  function isEmpty(obj) {
    return Object.keys(obj).length === 0
  }

  //render again when voyageLegsField.value has been set
  // useEffect(() => {
  //     if (props.isEdit) {
  //         setVoyageLegsField(voyageLegsField.value)
  //         setShouldRender(true)
  //     }
  // }, [voyageLegsField.value])

  if (!shouldRender) {
    return null
  }

  const getFieldError = (itemIndex, fieldPath) => {
    if (errors) {
      let pathArr = fieldPath.split('.')
  
      if (errors[pathArr[0]] && errors[pathArr[0]][itemIndex]
          && errors[pathArr[0]][itemIndex][pathArr[1]]) {
         
               
                  console.log(errors[pathArr[0]][itemIndex][pathArr[1]])
                  return errors[pathArr[0]][itemIndex][pathArr[1]]
              }
      }
  
    
    return null
  }

  return (
    <Card>
      {/* {'Error :' + JSON.stringify(errors)}
                <br /> */}
      {/* {'Touched :' + JSON.stringify(touched)} */}

      <Accordion>
        <AccordionSummary
          aria-controls='scheduleInfoComponentContent'
          id='scheduleInfoComponentHeader'
        >
          <Grid container>
            <AccordionHeaderComponent
              title={translatedTextsObject.scheduleInformation}
            />

            <CngGridItem xs={12} sm={12}>
              <Box>
                <Card>
                  <Alert severity='info' className='font-mediumBold'>
                    {translatedTextsObject.addScheduleStep2Note}
                  </Alert>
                </Card>
              </Box>
            </CngGridItem>

            {console.log('voyageLegList > ' + JSON.stringify(voyageLegList))}
            {console.log('voyageLegsField > ' + JSON.stringify(fields))}
            {voyageLegList.map((x, i) => {
              return (
                // <Box pt={1.5} >
                <Grid
                  xs={12}
                  sm={12}
                  spacing={2}
                  key={x.legId}
                  className='pt-20'
                >
                  <Card>
                    <Accordion expanded={x.scheduleExpanded}>
                      <AccordionSummary
                        aria-controls='scheduletDetailsComponentContent'
                        id='scheduleDetailsComponentHeader'
                      >
                        <Grid container xs={12} sm={12} spacing={2}>
                          <CngGridItem item xs={12} sm={12}>
                            {'SN' + (i + 1)}
                          </CngGridItem>

                          <CngGridItem
                            item
                            xs={6}
                            sm={6}
                            className='inner-card-background'
                          >
                            <FormControl>
                              <FormLabel>
                                {translatedTextsObject.wayPoint}
                              </FormLabel>
                              <RadioGroup
                                row
                                name={`voyageLegs[${i}].waypoint`}
                                value={x.waypoint}
                                onChange={(val, evt) =>
                                  handleValueChange(evt, i, 'waypoint')
                                }
                              >
                                <FormControlLabel
                                  className='font-11'
                                  value={
                                    CalistaConstant.SCHEDULE_WAYPOINT_START
                                  }
                                  control={<Radio color='primary' />}
                                  label={translatedTextsObject.startingPoint}
                                  disabled
                                />
                                <FormControlLabel
                                  className='font-11'
                                  value={
                                    CalistaConstant.SCHEDULE_WAYPOINT_STOPOVER
                                  }
                                  control={<Radio color='primary' />}
                                  label={translatedTextsObject.stopOverPoint}
                                  disabled
                                />
                                <FormControlLabel
                                  className='font-11'
                                  value={
                                    CalistaConstant.SCHEDULE_WAYPOINT_FINAL
                                  }
                                  control={<Radio color='primary' />}
                                  label={translatedTextsObject.finalPoint}
                                  disabled
                                />
                              </RadioGroup>
                            </FormControl>
                          </CngGridItem>
                          <CngGridItem item xs={6} sm={6}>
                            {/* changed to TransportationModeSelect as this dropdown may added extra optons from BigSchedule which is not in our default list */}
                            {/* <CngCodeMasterAutocompleteField
                                name={`voyageLegs[${i}].transportMode`}
                                label={translatedTextsObject.transportationMode + ' *'}
                                codeType='CAL_CONT_STATUS'
                                // codeType='CAL_VESSEL_MODE' //not in code master yet, current gen hard code in ds.xml
                                onChange={(val, evt) => handleAutocompleteChange(val, evt, i, 'transportMode')}
                                textFieldProps={{
                                    InputProps: {
                                      customEndAdornment: (autocompleteEndAdornment, options) => {
                                        return (
                                            <Box>
                                                <div className="MuiAutocomplete-endAdornment">
                                                <Tooltip title={translatedTextsObject.tansportationModeTooltips} placement='top'>
                                                <Box pr={4}>
                                                    <IconButton color={'default'} style={{marginTop:-11}}>
                                                        <HelpOutlineIcon 
                                                            size='16px' 
                                                        />
                                                    </IconButton>
                                                </Box>
                                                </Tooltip>
                                                </div>
                                                {autocompleteEndAdornment}
                                            </Box>
                                        )
                                      }
                                    }
                                  }}
                            /> */}

                            {i == 0 || i < voyageLegList.length - 1 ? (
                              <Grid container xs={12} sm={12}>
                                <CngGridItem item xs={11} sm={11}>
                                  <TransportationModeSelect
                                    name={`voyageLegs[${i}].transportMode`}
                                    label={
                                      translatedTextsObject.transportationMode 
                                      // ' *'
                                    }
                                    // extraOption={[{"text": x.transportMode, "value": x.transportMode}]}
                                    error={getFieldError(
                                      i,
                                      'voyageLegs.transportMode'
                                    )}
                                    helperText={getFieldError(
                                      i,
                                      'voyageLegs.transportMode'
                                    )}
                                    onChange={(evt) =>
                                      handleValueChange(evt, i, 'transportMode')
                                    }
                                    index={i}
                                    fieldName='transportMode'
                                  />
                                </CngGridItem>
                                <CngGridItem item xs={1} sm={1}>
                                  <Tooltip
                                    title={
                                      translatedTextsObject.tansportationModeTooltips
                                    }
                                    placement='top'
                                  >
                                    <IconButton color={'default'}>
                                      <HelpOutlineIcon size='16px' />
                                    </IconButton>
                                  </Tooltip>
                                </CngGridItem>
                              </Grid>
                            ) : (
                              ''
                            )}
                          </CngGridItem>

                          <CngGridItem item xs={4} sm={4}>
                            <PortSelectAutoComplete
                              name={`voyageLegs[${i}].portCode`}
                              label={translatedTextsObject.port}
                              keyRef={x.portKeyRef}
                              // value={x.portCode}
                              // onChangeAutoComplete={onChangePolAutoComplete}
                              onChangeAutoComplete={(val, evt) =>
                                handleAutocompleteChange(val, evt, i, 'portCode')
                              }
                              required
                            />
                          </CngGridItem>

                          {/* temp change arrivalDate to arrivalTime */}
                          {i > 0 && i <= voyageLegList.length - 1 && (
                            <CngGridItem item xs={4} sm={4}>
                              <AsteriskRedCngDateTimeField
                                label={translatedTextsObject.arrivalTime}
                                name={`voyageLegs[${i}].arrivalTime`}
                                // value={x.arrivalTime}
                                // format='YYYY-MM-DD HH:mm'
                                // toServerDataFormat='YYYY-MM-DD HH:mm:ss'
                                required
                                format={DD_MM_YYYY_HH_MM}
                                toServerDataFormat={DD_MM_YYYY_HH_MM_SS}
                                onChange={(evt) =>
                                  handleDateChange(evt, i, 'arrivalTime')
                                }
                                error={getFieldError(
                                  i,
                                  'voyageLegs.arrivalTime'
                                )}
                                helperText={getFieldError(
                                  i,
                                  'voyageLegs.arrivalTime'
                                )}
                              />
                            </CngGridItem>
                          )}
                          {/* Time picker not ready */}
                          {/* {i > 0 && i<= voyageLegList.length -1 &&
                                                    <CngGridItem item xs={4} sm={4}>
                                                        <CngDateField
                                                            label={translatedTextsObject.arrivalTime + ' *'}
                                                            name={`voyageLegs[${i}].arrivalTime`}
                                                            // value={x.arrivalTime}
                                                            onChange={(evt) => handleDateChange(evt, i, 'arrivalTime')}
                                                            />
                                                    </CngGridItem>
                                                    } */}

                          {/* temp cange departureDate to departureTime */}
                          {i == 0 || i < voyageLegList.length - 1 ? (
                            <CngGridItem item xs={4} sm={4}>
                              <AsteriskRedCngDateTimeField
                                label={translatedTextsObject.departTime}
                                name={`voyageLegs[${i}].departureTime`}
                                required
                                // value={x.departureTime}
                                // format='YYYY-MM-DD HH:mm'
                                // toServerDataFormat='YYYY-MM-DD HH:mm:ss'
                                format={DD_MM_YYYY_HH_MM}
                                toServerDataFormat={DD_MM_YYYY_HH_MM_SS}
                                onChange={(evt) =>
                                  handleDateChange(evt, i, 'departureTime')
                                }
                                error={getFieldError(
                                  i,
                                  'voyageLegs.departureTime'
                                )}
                                helperText={getFieldError(
                                  i,
                                  'voyageLegs.departureTime'
                                )}
                              />
                            </CngGridItem>
                          ): null}

                          {i < voyageLegList.length - 1 && (
                            <CngGridItem item xs={4} sm={4}>
                              {/* Time picker not ready */}
                              {/* <CngDateField
                                                            label={translatedTextsObject.departureTime + ' *'}
                                                            name={`voyageLegs[${i}].departureTime`}
                                                            // value={x.departureTime}
                                                            onChange={(evt) => handleDateChange(evt, i, 'departureTime')}
                                                            /> */}
                            </CngGridItem>
                          )}
                        </Grid>
                      </AccordionSummary>

                      <AccordionDetails>
                        {/* This accordianDetails is the drop down additional information */}
                        <Grid container xs={12} sm={12} spacing={2}>
                          {/* temp change cycutoffDate to cyCutoffTime */}
                          <CngGridItem item xs={4} sm={4}>
                            <CngDateTimeField
                              label={translatedTextsObject.cyCutoffTime}
                              name={`voyageLegs[${i}].cyCutoffTime`}
                              // value={x.cyCutoffTime}
                            //   format='YYYY-MM-DD HH:mm'
                            //   toServerDataFormat='YYYY-MM-DD HH:mm:ss'
                                format={DD_MM_YYYY_HH_MM}
                                toServerDataFormat={DD_MM_YYYY_HH_MM_SS}
                              onChange={(evt) =>
                                handleDateChange(evt, i, 'cyCutoffTime')
                              }
                              error={getFieldError(
                                i,
                                'voyageLegs.cyCutoffTime'
                              )}
                              helperText={getFieldError(
                                i,
                                'voyageLegs.cyCutoffTime'
                              )}
                            />
                          </CngGridItem>
                          {/* Time picker not ready */}
                          {/* <CngGridItem item xs={4} sm={4}>
                                                        <CngDateField
                                                            label={translatedTextsObject.cyCutoffTime}
                                                            name={`voyageLegs[${i}].cyCutoffTime`}
                                                            // value={x.cyCutoffTime}
                                                            onChange={(evt) => handleDateChange(evt, i, 'cyCutoffTime')}
                                                            />
                                                    </CngGridItem> */}
                          {i == 0 || i < voyageLegList.length - 1 ? (
                            <CngGridItem item xs={4} sm={4}>
                              <CngTextField
                                type='text'
                                name={`voyageLegs[${i}].transitTime`}
                                label={
                                  translatedTextsObject.transitTime
                                }
                                value={x.transitTime}
                                disabled
                                // inputProps={{ readOnly: true }}
                                onChange={e => handleInputChange(e, i)}
                                // error={getFieldError(idx, 'voyageLegs.transitTime')}
                                // helperText={getFieldError(idx, 'voyageLegs.transitTime')}
                              />
                            </CngGridItem>
                            
                           
                          ) : (
                            ''
                          )}

                            <CngGridItem item xs={4} sm={4}> 
                          
                            <CngTextField
                                type='text'
                                name= {`voyageLegs[${i}].terminal`}
                                label={translatedTextsObject.terminal}
                                value={x.terminal}
                                onChange={(e) =>
                                  handleInputChange(e, i)
                                }
                                inputProps={{ maxLength: 100 }}
                              />
                            </CngGridItem> 
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                    <CngGridItem
                      xs={4}
                      sm={4}
                      style={
                        x.scheduleExpanded
                          ? { marginTop: -16, marginBottom: 16 }
                          : {}
                      }
                    >
                      <IconButton aria-label='copy'>
                        <AddIcon onClick={() => handleAddSchedule(i)} />
                      </IconButton>
                      <IconButton aria-label='copy'>
                        <FileCopyIcon onClick={() => handleCopySchedule(i)} />
                      </IconButton>
                      {voyageLegList.length > 2 && (
                        <IconButton aria-label='remove'>
                          <DeleteIcon onClick={() => handleRemoveSchedule(i)} />
                        </IconButton>
                      )}
                      {voyageLegList.length > 1 && i > 0 && (
                        <IconButton aria-label='moveUp'>
                          <ArrowUpwardIcon onClick={() => handleMoveUp(i)} />
                        </IconButton>
                      )}
                      {voyageLegList.length >= 2 &&
                        i != voyageLegList.length - 1 && (
                          <IconButton aria-label='moveDown'>
                            <ArrowDownwardIcon
                              onClick={() => handleMoveDown(i)}
                            />
                          </IconButton>
                        )}
                    </CngGridItem>
                  </Card>
                  <AccordionFooterControlComponent
                    handleAccordionExpand={handleDetailsExpand(i)}
                    expanded={x.scheduleExpanded}
                    footerText={
                      translatedTextsObject.additionalWaypointDetails
                    }
                  />
                </Grid>
                // </Box>
              )
            })}
          </Grid>
        </AccordionSummary>
      </Accordion>
    </Card>
  )
}

const ScheduleInfoComponent = Object.freeze({
  FormBody: FormBody,
  initialValues: initialValues
})

export default ScheduleInfoComponent
