import { Avatar, Box, Paper, Typography } from '@material-ui/core'
import { PlusCircle, Trash } from 'react-feather'
import React, { useCallback, useRef, useState } from 'react'

import ManageBlacklistedCarriersDialog from './ManageBlacklistedCarriersDialog.js'
import ManageCarriersTranslationText from 'src/views/vesselschedule/managepreferredcarrier/ManageCarriersTranslationText'
import TcalVsManageBlacklistedCarriersApiUrls from 'src/apiUrls/TcalVsManageBlacklistedCarriersApiUrls.js'
import { components } from 'cng-web-lib'
import { makeStyles } from '@material-ui/core'

const {
  button: { CngPrimaryButton },
  table: { CngCrudTable, useDefaultNotification }
} = components

const useStyles = makeStyles((theme) => ({
  div: {
    '& .MuiTable-root': {
      borderSpacing: '0 5px',
      borderCollapse: 'separate',
      '& .MuiTableHead-root .MuiTableRow-head .MuiTableCell-head': {
        backgroundColor: '#fafbfc',
        borderBottom: 'none',
        '&:nth-child(1)': {
          width: 'auto !important'
        },
        '&:nth-child(n + 2):nth-last-child(n + 2)': {
          width: '20% !important'
        }
      },
      '& .MuiTableRow-root[index]': {
        '&:nth-child(even)': {
          backgroundColor: '#fafbfc'
        },
        '& td[class*="MuiTableCell-root"]': {
          border: '1px solid #f1f4f9 !important',
          borderLeft: 'none !important',
          borderRight: 'none !important',
          '&:nth-child(1)': {
            borderLeft: '1px solid #f1f4f9 !important',
            borderRadius: '5px 0 0 5px !important',
            width: 'auto !important'
          },
          '&:nth-child(n + 2):nth-last-child(n + 2)': {
            width: '20% !important'
          },
          '&:nth-last-child(1)': {
            borderRight: '1px solid #f1f4f9 !important',
            borderRadius: '0 5px 5px 0 !important',
            '& .MuiIconButton-root': {
              border: '1px solid #f4f4f7',
              borderRadius: '10px'
            }
          }
        }
      }
    }
  },
  trashIcon: {
    color: theme.palette.text.error,
    width: theme.spacing(2),
    height: theme.spacing(2)
  }
}))

function ManageBlacklistedCarriersTable(props) {
  const translatedTextsObject = ManageCarriersTranslationText()
  const classes = useStyles()
  const { showNotification } = props

  const notification = useDefaultNotification(showNotification)

  const tableRef = useRef()
  const cngTableRef = useRef()

  const tableRefStateCallback = useCallback(() => {
    return tableRef.current?.state
  }, [])

  const columns = [
    {
      field: 'name',
      title: translatedTextsObject.carriersNovccParty,
      render: (rowData) => {
        return (
          <Box display='flex' alignItems='center'>
            <Box>
              <Avatar
                alt='Carrier'
                src={`${process.env.PUBLIC_URL}/static/images/carriers/${
                  'genericCarrier' || rowData.code
                }.svg`}
              />
            </Box>
            <Box ml={1.5} flexGrow={1}>
              <b>{rowData.name}</b>
            </Box>
          </Box>
        )
      }
    }
  ]

  const [
    isManageBlacklistedCarriersDialogOpen,
    setManageBlacklistedCarriersDialogOpen
  ] = useState(false)

  function closeDialog() {
    setManageBlacklistedCarriersDialogOpen(false)
  }

  return (
    <Paper>
      <Box m='8px' className={classes.div}>
        <CngCrudTable
          {...props}
          fieldLevel=''
          tableRef={(ref) => {
            if (ref === null) {
              return
            }

            tableRef.current = ref
          }}
          columns={columns}
          del={{
            url: TcalVsManageBlacklistedCarriersApiUrls.DELETE
          }}
          fetch={{
            url: TcalVsManageBlacklistedCarriersApiUrls.BLACKLISTED_CARRIERS_GET
          }}
          notification={notification}
          options={{
            filtering: false,
            draggable: false
          }}
          localization={{
            header: {
              actions: translatedTextsObject.remove
            }
          }}
          deleteButtonProps={{
            icon: ({ color, ...otherProps }) => (
              <Trash {...otherProps} className={classes.trashIcon} />
            ),
            tooltip: translatedTextsObject.remove
          }}
          refreshButtonProps={{
            hidden: true
          }}
          actions={[
            {
              tooltip: translatedTextsObject.addCarrier,
              custom: () => {
                return (
                  <Box flexGrow={2}>
                    <CngPrimaryButton
                      onClick={() =>
                        setManageBlacklistedCarriersDialogOpen(true)
                      }
                    >
                      <PlusCircle style={{ marginRight: 5 }} />{' '}
                      {translatedTextsObject.addCarrier}
                    </CngPrimaryButton>
                  </Box>
                )
              },
              isFreeAction: true
            }
          ]}
          fetchMode='FULL'
          customToolbar={(toolbar) => {
            const page = tableRefStateCallback()?.currentPage || 0
            const pageSize = tableRefStateCallback()?.pageSize || 0
            const totalResult = tableRefStateCallback()?.data?.length || 0

            return (
              <Box display='flex' alignItems='center'>
                <Box pl='8px'>
                  <Typography variant='body2'>{`Showing ${Math.min(
                    page * pageSize + 1,
                    totalResult
                  )}-${Math.min(
                    (page + 1) * pageSize,
                    totalResult
                  )} of ${totalResult}`}</Typography>
                </Box>
                <Box flexDirection='row-reverse' flexGrow={1}>
                  {toolbar}
                </Box>
              </Box>
            )
          }}
          postFetch={(data) => {
            return data.map((d) => ({ ...d, id: d.prefCarrierId }))
          }}
          cngTableRef={cngTableRef}
          // tableConfigurationCode="REPLACE_WITH_CORE_TABLE_CONFIG_CODE"
        />
      </Box>
      <ManageBlacklistedCarriersDialog
        isDialogOpen={isManageBlacklistedCarriersDialogOpen}
        closeDialog={closeDialog}
        showNotification={showNotification}
        cngTableRef={cngTableRef}
      />
    </Paper>
  )
}

export default ManageBlacklistedCarriersTable
