import React, { useState } from 'react'

import CloseDialogIconButton from 'src/components/iconbutton/CloseDialogIconButton.js'
import ManageCarriersTranslationText from 'src/views/vesselschedule/managepreferredcarrier/ManageCarriersTranslationText'
import ManageEditPreferredPortPairsDialogContext from './ManageEditPreferredPortPairsDialogContext.js'
import ManageEditPreferredPortPairsDialogForm from './ManageEditPreferredPortPairsDialogForm.js'
import Paper from '@material-ui/core/Paper'
import { components } from 'cng-web-lib'

const { CngDialog } = components

function ManageEditPreferredPortPairsDialog({
  isDialogOpen,
  closeDialog,
  rowData,
  portName,
  showNotification,
  cngTableRef
}) {
  const [isSubmitting, setSubmitting] = useState(false)
  const translatedTextsObject = ManageCarriersTranslationText()

  return (
    <ManageEditPreferredPortPairsDialogContext.Provider
      value={{
        closeDialog,
        showNotification,
        rowData,
        portName,
        form: {
          isSubmitting,
          setSubmitting
        },
        cngTableRef
      }}
    >
      <CngDialog
        customDialogContent={
          <Paper>
            <div style={{ overflow:'hidden' }}>
            <ManageEditPreferredPortPairsDialogForm />
            </div>
          </Paper>
        }
        dialogTitle={
          <>
            <b>{translatedTextsObject.editPreferredPortPair}</b>
            <CloseDialogIconButton
              onClick={closeDialog}
              disabled={isSubmitting}
            />
          </>
        }
        open={isDialogOpen}
        fullWidth
        maxWidth='md'
      />
    </ManageEditPreferredPortPairsDialogContext.Provider>
  )
}

export default ManageEditPreferredPortPairsDialog