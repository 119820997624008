import React, { useState } from 'react'

import AmendVesselDialogContext from './AmendVesselDialogContext'
import AmendVesselForm from './AmendVesselForm'
import CloseDialogIconButton from 'src/components/iconbutton/CloseDialogIconButton.js'
import Paper from '@material-ui/core/Paper'
import VesselsTranslationText from '../VesselsTranslationText'
import { components } from 'cng-web-lib'

const { CngDialog } = components

function AmendVesselDialog({
  isDialogOpen,
  reloadTime,
  closeDialog,
  showNotification,
  vId,
  vesselName,
  imoNo,
  vesselStatus,
  remarks,
  partyId,
  version,
  showWarningMsg
}) {
  const [isSubmitting, setSubmitting] = useState(false)
  const translatedTextsObject = VesselsTranslationText()

  return (
    <AmendVesselDialogContext.Provider
      value={{
        closeDialog,
        reloadTime,
        showNotification,
        form: {
          isSubmitting,
          setSubmitting
        },
        vId,
        vesselName,
        imoNo,
        vesselStatus,
        remarks,
        partyId,
        version,
        showWarningMsg
      }}
    >
      <CngDialog
        customDialogContent={
          <Paper>
            <AmendVesselForm />
          </Paper>
        }
        dialogTitle={
          <>
            <b>{translatedTextsObject.amendVesselInfo}</b>
            <CloseDialogIconButton
              onClick={closeDialog}
              disabled={isSubmitting}
            />
          </>
        }
        open={isDialogOpen}
        fullWidth
        maxWidth='sm'
      />
    </AmendVesselDialogContext.Provider>
  )
}

export default AmendVesselDialog
