import { Box, Grid, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'

import HtmlTooltip from 'src/views/freightbooking/components/HtmlTooltip'

const TerminalInitial = (props) => {
  const { withTooltip, longName, shortName } = props
  const [initial, setInitial] = useState('')
  let ini = ''
  let i = 0
  let char = ''

  const getFirst2Initial = () => {
    // alert(longName)
    if (longName != null && i <= longName.length) {
      char = longName.charAt(i)
      if (isNaN(char * 1)) {
        if (char == char.toUpperCase()) {
          ini = ini + char
        }
      }

      if (ini.length == 2) {
        console.log('initial : ' + ini)
        setInitial(ini)
        return
      } else {
        i++
      }
    }
  }

  useEffect(() => {
    getFirst2Initial()
  }, [])

  return (
    <Grid>
      <HtmlTooltip title={withTooltip ? <Box>{longName}</Box> : ''}>
        <Typography>
          <Box
            fontWeight='fontWeightBold'
            fontSize={props.manageSchedule ? 13 : 'x-small'}
          >
            {shortName}
          </Box>
        </Typography>
      </HtmlTooltip>
    </Grid>
  )
}

export default TerminalInitial
