import { Avatar, Box, Paper, Typography, Chip, Tooltip, makeStyles } from '@material-ui/core'
import { PlusCircle, Trash, LogOut } from 'react-feather'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { components, constants, useServices } from 'cng-web-lib'

import ManageCarriersTranslationText from 'src/views/vesselschedule/managepreferredcarrier/ManageCarriersTranslationText'
import ManagePreferredPortPairsDialog from './ManagePreferredPortPairsDialog.js'
import ManageEditPreferredPortPairsDialog from './ManageEditPreferredPortPairsDialog.js'
import ExportPreferredDialog from '../component/ExportPreferredDialog.js'
import TcalVsManagePreferredPortPairsApiUrls from 'src/apiUrls/TcalVsManagePreferredPortPairsApiUrls.js'
import CodeMaintainanceApi from 'src/views/common/api/CodeMatainanceApi'
import CngBackdrop from 'src/views/vesselschedule/searchschedule/cngcomponent/CngBackDrop'
import moment from 'moment'

const {
  button: { CngPrimaryButton, CngSecondaryButton },
  table: { CngCrudTable, useDefaultNotification },
  CngDialog
} = components

const useStyles = makeStyles((theme) => ({
  div: {
    '& .MuiTable-root': {
      borderSpacing: '0 5px',
      borderCollapse: 'separate',
      '& .MuiTableHead-root .MuiTableRow-head .MuiTableCell-head': {
        backgroundColor: '#fafbfc',
        borderBottom: 'none',
        '&:nth-child(1)': {
          width: '15% !important'
        },
        '&:nth-child(2)': {
          width: '15% !important',
        },
        '&:nth-child(3)': {
          width: '20% !important'
        },
        '&:nth-child(4)': {
          width: '10% !important',
          fontSize: '0px'
        },
        '&:nth-child(n + 5):nth-last-child(n + 2)': {
          width: '10% !important'
        },
        '&:nth-last-child(1)': {
          width: '5% !important'
        }
      },
      '& .MuiTableRow-root[index]': {
        '&:nth-child(even)': {
          backgroundColor: '#fafbfc'
        },
        '& td[class*="MuiTableCell-root"]': {
          border: '1px solid #f1f4f9 !important',
          borderLeft: 'none !important',
          borderRight: 'none !important',
          '&:nth-child(1)': {
            borderLeft: '1px solid #f1f4f9 !important',
            borderRadius: '5px 0 0 5px !important',
            width: '15% !important'
          },
          '&:nth-child(2)': {
            width: '15% !important'
          },
          '&:nth-child(3)': {
            width: '20% !important'
          },
          '&:nth-child(n + 4):nth-last-child(n + 2)': {
            width: '10% !important'
          },
          '&:nth-last-child(1)': {
            width: '5% !important',
            borderRight: '1px solid #f1f4f9 !important',
            borderRadius: '0 5px 5px 0 !important',
            '& .MuiIconButton-root': {
              border: '1px solid #f4f4f7',
              borderRadius: '10px'
            }
          }
        }
      }
    }
  },
  trashIcon: {
    color: theme.palette.text.error,
    width: theme.spacing(2),
    height: theme.spacing(2)
  }
}))

function ManagePreferredPortPairsTable(props) {

  const translatedTextsObject = ManageCarriersTranslationText()
  const { CodeMaintenanceType } = constants
  const { securedSendRequest, fetchRecords, deleteRecord } = useServices()
  const classes = useStyles()
  const { showNotification } = props
  const {
    success: showSuccessNotification,
    error: showErrorNotification
  } = useDefaultNotification(showNotification)

  const notification = useDefaultNotification(showNotification)

  const tableRef = useRef()
  const cngTableRef = useRef()
  const darkRef = useRef('')
  const [loading, setLoading] = useState(true)
  const [portPairTableArray, setPortPairTableArray] = useState([])
  const [codeMaintainencePort, setCodeMaintainencePort] = useState([])
  const [tableData, setTableData] = useState({})

  const tableRefStateCallback = useCallback(() => {
    return tableRef.current?.state
  }, [])

  const processPostFetchData = (data) =>{
    //console.log("postFetch: " + JSON.stringify(data))
    let portPairArr = []
    let formattedData = {}

    data.forEach(record => {
      if(portPairArr.includes(record.polCode + "_" + record.podCode)){
        let carrierList = formattedData[[record.polCode, record.podCode]].carrierList
        let contractedList = formattedData[[record.polCode, record.podCode]].contracted
        carrierList.push(record)
        contractedList.push(record.contracted)
        formattedData[[record.polCode, record.podCode]] = {"carrierList": carrierList, "contracted": contractedList, "contractStart": '', "contractEnd": '' }
      }else{
        portPairArr.push(record.polCode + "_" + record.podCode)
        let contractStart = record.contracted ? record.contractStartDate : ''
        let contractEnd = record.contracted ? record.contractEndDate  : ''
        formattedData[[record.polCode, record.podCode]] = {"carrierList": [record], "contracted": [record.contracted], "contractStart": contractStart, "contractEnd": contractEnd }
      }
    })

    let finalData = []
    for (const [key, value] of Object.entries(formattedData)) {
      let polCode = key.split(',')[0]
      let podCode = key.split(',')[1]
      finalData.push({...value, "polCode":polCode, "podCode": podCode})
    }

    setPortPairTableArray(portPairArr)
    setTableData(finalData)

    return finalData 
  }

  function renderDateColumn(date) {
    if (!date) {
      return null
    }

    return <span>{moment(date).format('DD/MM/YYYY')}</span>
  }

  const columns = [
    {
      field: 'polCode',
      title: translatedTextsObject.origin,
      render: (rowData) => {
        let descriptionEn = codeMaintainencePort.filter((pol) => pol.code == rowData.polCode)[0].descriptionEn.toLowerCase()
        .replace(/(^\w|[\s(\.-\\\/]\w)/g, m => m.toUpperCase())
        return (
          <Box>
          <div>
            {descriptionEn}
            <span className='pl-10'>
              <Chip
                label={<b>{rowData.polCode}</b>}
                size='small'
              />
            </span>
          </div>
          </Box>
        )
      }
    },
    {
      field: 'podCode',
      title: translatedTextsObject.destination,
      render: (rowData) => {
        let descriptionEn = codeMaintainencePort.filter((pod) => pod.code == rowData.podCode)[0].descriptionEn.toLowerCase()
        .replace(/(^\w|[\s(\.-\\\/]\w)/g, m => m.toUpperCase())
        return (
          <Box>
            <div>
              {descriptionEn}
              <span className='pl-10'>
                <Chip
                  label={<b>{rowData.podCode}</b>}
                  size='small'
                />
              </span>
            </div>
          </Box>
        )
      }
    },
    {
      field: 'name',
      title: translatedTextsObject.carriersNovccParty,
      render: (rowData) => {
        let textDesc = ''
        if(rowData.carrierList.length > 3){
          textDesc = `and ${rowData.carrierList.length - 3} more carrier(s)`
        }

        return (
          <Box display='flex' alignItems='center'>
            { rowData.carrierList.map((carrier, index) => (
              <Box m={1} style={{display: (index < 3) ? "inline" : "none" }} key={index}>
              <Tooltip title={carrier.name}>
              <Avatar
               classes={{
                 img: 'vs-carrier-image'
               }}
               alt='Carrier'
               src={`${process.env.PUBLIC_URL}/static/images/carriers/${carrier.code}.svg`}
              >
               <img
                 className={'search-button'}
                 src={`${process.env.PUBLIC_URL}/static/images/carriers/genericCarrier.svg`}
               ></img>
             </Avatar>
             </Tooltip>
             </Box>
            ))}
            <Box ml={1.5} flexGrow={1}>
              <b>{textDesc}</b>
            </Box>
          </Box>
        )
      }
    },
    {
      field: 'contracted',
      title: translatedTextsObject.contracted,
      render: (rowData) => {
        let no_contracted = rowData.contracted.filter((elem) => elem).length
        let total_contracted = rowData.contracted.length
        return (
          <Box>
            <Box style={{display: total_contracted > 1 ? "inline" : "none" }}>
              <b>{translatedTextsObject.contracted} ({no_contracted} out of {total_contracted})</b>
            </Box>
            <Box style={{display: total_contracted == 1 ? "inline" : "none" }}>
              {rowData.contracted ? (
                <b>{translatedTextsObject.contracted}</b>
              ) : null}
            </Box>
          </Box>
        )
      }
    },
    {
      field: 'contractStartDate',
      title: translatedTextsObject.startingDate,
      render: (rowData) => {
        return renderDateColumn(rowData.contractStart)
      }
    },
    {
      field: 'contractEndDate',
      title: translatedTextsObject.endingDate,
      render: (rowData) => {
        return renderDateColumn(rowData.contractEnd)
      }
    }
  ]

  const [
    isManagePreferredPortPairsDialogOpen,
    setManagePreferredPortPairsDialogOpen
  ] = useState(false)

  function closeDialog() {
    setManagePreferredPortPairsDialogOpen(false)
  }

  const [
    exportDialogOpen,
    setExportDialogOpen
  ] = useState(false)

  function closeExportDialog() {
    setExportDialogOpen(false)
  }

  const [
    deleteDialogOpen,
    setDeleteDialogOpen
  ] = useState(false)

  function closeDeleteDialog() {
    setDeleteDialogOpen(false)
  }

  const [rowDataDelete, setRowDataDelete] = useState({})

  const [
    editPreferredCarrierDialogOpen,
    setEditPreferredCarrierDialogOpen
  ] = useState(false)

  function closeEditDialog() {
    setEditPreferredCarrierDialogOpen(false)
  }

  const [rowDataEdit, setRowDataEdit] = useState({})

  const editRowData = (data) =>{
    setEditPreferredCarrierDialogOpen(true)
    setRowDataEdit(data)
  }

  const deleteRowData =(data) =>{
    setDeleteDialogOpen(true)
    setRowDataDelete(data)
  }

  const deleteRow = () =>{
    let dataArr = []

    rowDataDelete.carrierList.forEach(record => {
      dataArr.push({id: record.prefPortId, version: record.version })
    })

    deleteRecord.execute(
      TcalVsManagePreferredPortPairsApiUrls.MULTIPLE_DELETE,
      dataArr,
      () => {
        if (cngTableRef.current && cngTableRef.current.performRefresh) {
          cngTableRef.current.performRefresh()
        }
        showSuccessNotification(translatedTextsObject.deletePreferredPortPairSuccess)
      },
      (error) => {
        console.log(error)
        showErrorNotification(error)
      }
    )

    setDeleteDialogOpen(false)
  }

  useEffect(() => {
    securedSendRequest.execute(
      'GET',
      `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/user-preference/by-current-user`,
      {},
      (data) => {
        darkRef.current = data.data.theme
      },
      (error) => {
        console.log(error)
      },
      onComplete
    )

    function onComplete() {
      //setLoading(false)
      CodeMaintainanceApi.fetchCodeMaintainance(fetchRecords, CodeMaintenanceType.PORT, [], false, onLoadPortSuccess)
    }

    function onLoadPortSuccess(data) {
      console.log('onLoadPortSuccess')
      setCodeMaintainencePort(data)
      setLoading(false)
    }
  }, [])

  return (
    <Paper>
      {!loading ? (
        <Box
          m='8px'
          className={darkRef.current.trim() === 'LIGHT' ? classes.div : ''}
        >
          <CngCrudTable
            {...props}
            fieldLevel=''
            tableRef={(ref) => {
              if (ref === null) {
                return
              }

              tableRef.current = ref
            }}
            columns={columns}
            onRowClick={(evt, rowData) => editRowData(rowData)}
            fetch={{
              url:
                TcalVsManagePreferredPortPairsApiUrls.PREFERRED_PORT_PAIRS_GET
            }} 
            notification={notification}
            options={{
              filtering: false,
              draggable: false
            }}
            localization={{
              header: {
                actions: translatedTextsObject.remove
              }
            }}
            refreshButtonProps={{
              hidden: true
            }}
            actions={[
              {
                custom: () => {
                  return (
                    <Tooltip title={translatedTextsObject.exportTable}>
                    <Box flexGrow={2}>
                      <CngSecondaryButton
                        onClick={() =>
                          setExportDialogOpen(true)
                        }
                      >
                        <LogOut style={{ marginRight: 5 }} />{' '}
                        {translatedTextsObject.exportTable}
                      </CngSecondaryButton>
                    </Box>
                    </Tooltip>
                  )
                },
                isFreeAction: true
              },
              {
                tooltip: translatedTextsObject.remove,
                icon: () => <Trash className={classes.trashIcon} />,
                onClick: (event, rowData) => deleteRowData(rowData),
              }
            ]}
            fetchMode='FULL'
            customToolbar={(toolbar) => {
              const page = tableRefStateCallback()?.currentPage || 0
              const pageSize = tableRefStateCallback()?.pageSize || 0
              const totalResult = tableRefStateCallback()?.data?.length || 0

              return (
                <Box display='flex' alignItems='center'>
                  <Box pl='8px'>
                    <Typography variant='body2'>{`Showing ${Math.min(
                      page * pageSize + 1,
                      totalResult
                    )}-${Math.min(
                      (page + 1) * pageSize,
                      totalResult
                    )} of ${totalResult}`}</Typography>
                  </Box>
                  <Box flexDirection='row-reverse' flexGrow={1}>
                    {toolbar}
                  </Box>
                  <Box>
                  <Tooltip title={translatedTextsObject.addCarrier}>
                    <Box flexGrow={2}>
                        <CngPrimaryButton
                          onClick={() =>
                            setManagePreferredPortPairsDialogOpen(true)
                          }
                        >
                          <PlusCircle style={{ marginRight: 5 }} />{' '}
                          {translatedTextsObject.addNew}
                        </CngPrimaryButton>
                    </Box>
                  </Tooltip>
                  </Box>
                </Box>
              )
            }}
            postFetch={(data) => {
              return processPostFetchData(data)
            }}
            cngTableRef={cngTableRef}
            // tableConfigurationCode="REPLACE_WITH_CORE_TABLE_CONFIG_CODE"
          />
        </Box>
      ) : 
        <CngBackdrop loading={loading} />
      }
      <ManagePreferredPortPairsDialog
        portPairTableArray={portPairTableArray}
        isDialogOpen={isManagePreferredPortPairsDialogOpen}
        closeDialog={closeDialog}
        showNotification={showNotification}
        cngTableRef={cngTableRef}
      />
      <ManageEditPreferredPortPairsDialog
        isDialogOpen={editPreferredCarrierDialogOpen}
        closeDialog={closeEditDialog}
        rowData= {rowDataEdit}
        portName={codeMaintainencePort}
        showNotification={showNotification}
        cngTableRef={cngTableRef}
      />
      <ExportPreferredDialog
        isDialogOpen={exportDialogOpen}
        closeDialog={closeExportDialog}
        tableData={tableData}
        portName={codeMaintainencePort}
        columns={columns}
        fileName="Preferred Carrier Ports"
        showNotification={showNotification}
        cngTableRef={cngTableRef}
      />
      <CngDialog
      // Delete confirmation dialog
      dialogTitle='Delete'
      dialogTitleId='delete-confirmation'
      dialogContent= 'Are you sure you want to delete these record(s)?'
      dialogContentId='delete-confirmation-content'
      dialogAction={
        <>
          <CngSecondaryButton onClick={closeDeleteDialog}>Cancel</CngSecondaryButton>
          <CngPrimaryButton onClick={deleteRow} autoFocus>
            Delete
          </CngPrimaryButton>
        </>
      }
      open={deleteDialogOpen}
      />
    </Paper>
  )
}

export default ManagePreferredPortPairsTable
