import React, { useState } from 'react'

import CloseDialogIconButton from 'src/components/iconbutton/CloseDialogIconButton.js'
import ManageCarriersTranslationText from './ManageCarriersTranslationText'
import ManagePreferredCarrierDialogContext from './ManagePreferredCarrierDialogContext.js'
import ManagePreferredCarrierForm from './ManagePreferredCarrierForm.js'
import Paper from '@material-ui/core/Paper'
import { components } from 'cng-web-lib'

const { CngDialog } = components

function ManagePreferredCarrierDialog({
  isDialogOpen,
  closeDialog,
  showNotification,
  cngTableRef
}) {
  const [isSubmitting, setSubmitting] = useState(false)
  const translatedTextsObject = ManageCarriersTranslationText()

  return (
    <ManagePreferredCarrierDialogContext.Provider
      value={{
        closeDialog,
        showNotification,
        form: {
          isSubmitting,
          setSubmitting
        },
        cngTableRef
      }}
    >
      <CngDialog
        customDialogContent={
          <Paper>
            <div style={{ overflow:'hidden' }}>
            <ManagePreferredCarrierForm />
            </div>
          </Paper>
        }
        dialogTitle={
          <>
            <b>{translatedTextsObject.addPreferredCarrier}</b>
            <CloseDialogIconButton
              onClick={closeDialog}
              disabled={isSubmitting}
            />
          </>
        }
        open={isDialogOpen}
        fullWidth
        maxWidth='sm'
      />
    </ManagePreferredCarrierDialogContext.Provider>
  )
}

export default ManagePreferredCarrierDialog
