import PollingFormProperties from './PollingFormProperties'
import PollingApiUrls from 'src/apiUrls/PollingApiUrls'
import { components } from 'cng-web-lib'
import React from 'react'

const {
  form: { CngEditForm }
} = components

function PollingEditForm({
  showNotification,
  id,
  onFetchPreSuccess = () => {
    //do nothing
  }
}) {
  return (
    <CngEditForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      showNotification={showNotification}
      bodySection={
        <PollingFormProperties.Fields
          showNotification={showNotification}
        />
      }
      formikProps={PollingFormProperties.formikProps}
      toClientDataFormat={PollingFormProperties.toClientDataFormat}
      toServerDataFormat={PollingFormProperties.toServerDataFormat}
      fetch={{
        url: PollingApiUrls.GET,
        onPreSuccess: onFetchPreSuccess
      }}
      update={{
        url: PollingApiUrls.PUT
      }}
      id={id}
    />
  )
}

export default PollingEditForm
