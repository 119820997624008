import React, { useState } from 'react'

import CloseDialogIconButton from 'src/components/iconbutton/CloseDialogIconButton.js'
import ManageBlacklistedCarriersDialogContext from './ManageBlacklistedCarriersDialogContext.js'
import ManageBlacklistedCarriersForm from './ManageBlacklistedCarriersForm.js'
import ManageCarriersTranslationText from 'src/views/vesselschedule/managepreferredcarrier/ManageCarriersTranslationText'
import Paper from '@material-ui/core/Paper'
import { components } from 'cng-web-lib'

const { CngDialog } = components

function ManageBlacklistedCarriersDialog({
  isDialogOpen,
  closeDialog,
  showNotification,
  cngTableRef
}) {
  const [isSubmitting, setSubmitting] = useState(false)
  const translatedTextsObject = ManageCarriersTranslationText()

  return (
    <ManageBlacklistedCarriersDialogContext.Provider
      value={{
        closeDialog,
        showNotification,
        form: {
          isSubmitting,
          setSubmitting
        },
        cngTableRef
      }}
    >
      <CngDialog
        customDialogContent={
          <Paper>
            <ManageBlacklistedCarriersForm />
          </Paper>
        }
        dialogTitle={
          <>
            <b>{translatedTextsObject.addBlacklistedCarrier}</b>
            <CloseDialogIconButton
              onClick={closeDialog}
              disabled={isSubmitting}
            />
          </>
        }
        open={isDialogOpen}
        fullWidth
        maxWidth='sm'
      />
    </ManageBlacklistedCarriersDialogContext.Provider>
  )
}

export default ManageBlacklistedCarriersDialog
