import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  Chip,
  Collapse,
  Grid,
  IconButton,
  Typography
} from '@material-ui/core'
import {
  DateTimeFormatter,
  components,
  constants,
  useServices
} from 'cng-web-lib'
import React, { useEffect, useRef, useState } from 'react'

import AccordionFooterControlComponent from 'src/views/common/ui/AccordionFooterControlComponet'
import AccordionHeaderComponent from 'src/views/common/ui/AccordionHeaderComponent'
import CodeMaintainanceApi from 'src/views/common/api/CodeMatainanceApi'
import CountryViewField from 'src/components/field/CountryViewField'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import LabelValueVertical from 'src/views/freightbooking/components/LabelValueVertical'
import ManageScheduleTranslationText from '../ManageScheduleTranslationText'
import PortCountryViewField from 'src/components/field/PortCountryViewField'
import SchedulesStepper from 'src/views/vesselschedule/component/SchedulesStepper'
import TerminalInitial from 'src/views/vesselschedule/component/TerminalInitial'
import moment from 'moment'
import { v4 as uuid } from 'uuid'

const {
  form: {
    field: { CngTextField }
  },
  CngGridItem
} = components

const { CodeMaintenanceType } = constants

const initialValues = Object.freeze({
  remarks: ''
})

const FormBody = (props) => {
  const { voyageData, scheduleData } = props
  // const [, ,] = useField('remarks')
  const translatedTextsObject = ManageScheduleTranslationText()
  const [scheduleExpanded, setScheduleExpanded] = useState(false)
  const [totalTransitTime, setTotalTransitTime] = useState(0)
  const [cyCutOff, setCyCutOff] = useState()
  const [departs, setDeparts] = useState()
  const [departPort, setDepartPort] = useState()
  const [arrives, setArrives] = useState()
  const [arrivePort, setArrivePort] = useState()
  const [country, setCountry] = useState([])
  const [terminal, setTerminal] = useState([])
  const { fetchRecords } = useServices()

  const expandInfo = () => {
    setScheduleExpanded(!scheduleExpanded)
  }

  useEffect(() => {
    CodeMaintainanceApi.fetchCodeMaintainance(
      fetchRecords,
      CodeMaintenanceType.COUNTRY,
      [],
      false,
      onLoadCountrySuccess
    )

    function onLoadCountrySuccess(data) {
      setCountry(data)
    }

    console.log(
      'scheduleInfo voyageData.voyageLegs > ' +
        JSON.stringify(voyageData.voyageLegs)
    )
    if (voyageData != null && voyageData.voyageLegs != null) {
      const voyageLegs = voyageData.voyageLegs

      console.log('scheduleInfo voyageLegs > ' + JSON.stringify(voyageLegs))

      let cyCutOff = voyageLegs[0]['cyCutoffTime']
      setCyCutOff(cyCutOff)

      let departs = voyageLegs[0]['departureTime']
      setDeparts(departs)

      let arrives = voyageLegs[voyageLegs.length - 1]['arrivalTime']
      setArrives(arrives)

      let departPort = voyageLegs[0]['portCode']
      setDepartPort(departPort)

      let arrivePort = voyageLegs[voyageLegs.length - 1]['portCode']
      setArrivePort(arrivePort)

      let terminal = voyageLegs[voyageLegs.length - 1]['terminal']
      setTerminal(terminal)

      // let depart = moment(voyageLeg.departureTime, DD_MM_YYYY_HH_MM_SS)
      let total = moment(arrives)
        .startOf('day')
        .diff(moment(departs).startOf('day'), 'days')
      setTotalTransitTime(total)
    }
  }, [voyageData])

  return (
    <Card>
      <Box className='view-card-header'>
        {translatedTextsObject.scheduleInformation}
      </Box>

      <Box p={3}>
        <Card style={{ borderWidth: 4 }}>
          <Grid container className='schedule-info-row-header'>
            <Grid container item xs={12} sm={12} spacing={2}>
              <CngGridItem item xs={3} sm={3}>
                {translatedTextsObject.vesselService.toUpperCase()}
              </CngGridItem>
              <CngGridItem item xs={2} sm={2}>
                {translatedTextsObject.transitTime.toUpperCase()}
              </CngGridItem>
              <CngGridItem item xs={2} sm={2}>
                {translatedTextsObject.cyCutOff.toUpperCase()}
              </CngGridItem>
              <CngGridItem item xs={2} sm={2}>
                {translatedTextsObject.departs.toUpperCase()}
              </CngGridItem>
              <CngGridItem item xs={2} sm={2}>
                {translatedTextsObject.arrives.toUpperCase()}
              </CngGridItem>
            </Grid>
          </Grid>
          {/* <Grid container item xs={12} sm={12} spacing={2}> */}

          <Grid
            container
            item
            xs={12}
            sm={12}
            spacing={2}
            className='schedule-info-row-pb'
          >
            <CngGridItem item xs={3} sm={3}>
              <Box className='vs-break-word'>
                <Box>{voyageData.vesselName}</Box>
                <Box>{voyageData.service}</Box>
              </Box>
            </CngGridItem>
            <CngGridItem item xs={2} sm={2}>
              {totalTransitTime + ' ' + translatedTextsObject.days}
            </CngGridItem>
            <CngGridItem item xs={2} sm={2}>
              {/* {cyCutOff}  */}
              {moment(cyCutOff).isValid()
                ? moment(cyCutOff).format('D MMM (ddd) HH:mm')
                : '-'}
            </CngGridItem>
            <CngGridItem item xs={2} sm={2}>
              {/* {departs}   */}
              {moment(departs).isValid()
                ? moment(departs).format('D MMM (ddd) HH:mm')
                : '-'}
              {/* <Box display='flex' justifyContent='flex-start'>
                <Box>
                  <Chip label={<b>{departPort}</b>} size='small' />
                </Box>
                <span className='center-line' style={{marginRight:'-10px'}}>
                                        
                                    </span>
              </Box> */}
            </CngGridItem>
            <CngGridItem item xs={2} sm={2}>
              {/* {arrives} */}
              {moment(arrives).isValid()
                ? moment(arrives).format('D MMM (ddd) HH:mm')
                : ''}
              {/* <Box display='flex' justifyContent='center'>
                <span className='center-line' style={{marginLeft:'-10px'}}>
                                        
                                    </span>
                <Box>
                  <Chip label={<b>{arrivePort}</b>} size='small' />
                </Box>
              </Box> */}
            </CngGridItem>
            <CngGridItem item xs={1} sm={1}>
              <IconButton
                aria-label='expand row'
                size='small'
                onClick={() => expandInfo()}
              >
                {scheduleExpanded ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </IconButton>
            </CngGridItem>

            {/* next row */}
            <CngGridItem item xs={7} sm={7}></CngGridItem>
            <CngGridItem item xs={2} sm={2} style={{ padding: '0px' }}>
              <Box
                display='flex'
                justifyContent='flex-start'
                className='port-connector-left'
              >
                <Box>
                  <Chip label={<b>{departPort}</b>} size='small' />
                </Box>
                <span className='center-line'></span>
                {/* <Box>
                    <Chip label={<b>{arrivePort}</b>} size='small' />
                  </Box> */}
              </Box>
            </CngGridItem>
            <CngGridItem item xs={2} sm={2} style={{ padding: '0px' }}>
              <Box
                display='flex'
                justifyContent='flex-start'
                className='port-connector-right'
              >
                {/* <Box>
                    <Chip label={<b>{departPort}</b>} size='small' />
                  </Box> */}
                <span className='center-line'></span>
                <Box>
                  <Chip label={<b>{arrivePort}</b>} size='small' />
                </Box>
              </Box>
            </CngGridItem>
            <CngGridItem item xs={1} sm={1}></CngGridItem>
          </Grid>

          <Collapse in={scheduleExpanded} timeout='auto' unmountOnExit>
            <Box p={1}>
              <Card style={{ borderWidth: 4 }}>
                <Grid container className='schedule-info-row-header'>
                  <Grid container item xs={12} sm={12} spacing={2}>
                    <CngGridItem item xs={2} sm={2}>
                      {translatedTextsObject.transportVia.toUpperCase()}
                    </CngGridItem>
                    <CngGridItem item xs={2} sm={2}>
                      {translatedTextsObject.cyCutOff.toUpperCase()}
                    </CngGridItem>
                    <CngGridItem item xs={2} sm={2}>
                      {translatedTextsObject.arrival.toUpperCase()}
                    </CngGridItem>
                    <CngGridItem item xs={2} sm={2}>
                      {translatedTextsObject.departure.toUpperCase()}
                    </CngGridItem>
                    <CngGridItem item xs={2} sm={2}>
                      {translatedTextsObject.vesselVoyageDetails.toUpperCase()}
                    </CngGridItem>
                    <CngGridItem item xs={2} sm={2}>
                      {translatedTextsObject.terminal.toUpperCase()}
                    </CngGridItem>
                  </Grid>
                </Grid>

                <Box>
                  {voyageData.voyageLegs.map((x, i) => {
                    return (
                      <Grid
                        container
                        item
                        xs={12}
                        sm={12}
                        spacing={2}
                        className='schedule-info-row schedule-info-row-bg'
                        key={x.scheduleId}
                      >
                        <CngGridItem item xs={2} sm={2}>
                          {/* {x.countryCode} */}
                          {/* <CountryViewField countryCode={x.countryCode} countryList={country}/> */}
                          <PortCountryViewField
                            countryList={country}
                            portCode={x.portCode}
                          />
                        </CngGridItem>
                        <CngGridItem item xs={2} sm={2}>
                          {/* {x.cyCutoffTime} */}
                          {moment(x.cyCutoffTime).isValid()
                            ? moment(x.cyCutoffTime).format('ddd D MMM HH:mm')
                            : ''}
                        </CngGridItem>
                        <CngGridItem item xs={2} sm={2}>
                          {/* {x.arrivalTime}  */}
                          {i != 0 && moment(x.arrivalTime).isValid()
                            ? moment(x.arrivalTime).format('ddd D MMM HH:mm')
                            : ''}
                        </CngGridItem>
                        <CngGridItem item xs={2} sm={2}>
                          {/* {x.departureTime}   */}
                          {i != voyageData.voyageLegs.length - 1 &&
                          moment(x.departureTime).isValid()
                            ? moment(x.departureTime).format('ddd D MMM HH:mm')
                            : ''}
                        </CngGridItem>
                        <CngGridItem item xs={2} sm={2}>
                          {x.transportMode.toUpperCase() +
                            ' ' +
                            (x.transportMode
                              ? translatedTextsObject.transport.toUpperCase()
                              : '')}
                        </CngGridItem>
                        <CngGridItem item xs={2} sm={2}>
                          <TerminalInitial
                            manageSchedule
                            withTooltip={
                              x.terminal ? x.terminal.length > 15 : false
                            }
                            longName={x.terminal}
                            shortName={
                              x.terminal
                                ? x.terminal.length > 15
                                  ? x.terminal.substring(0, 15).trim() + '...'
                                  : x.terminal
                                : ''
                            }
                          />
                        </CngGridItem>
                      </Grid>
                    )
                  })}
                  <Card>
                    <Grid container item xs={12} sm={12} spacing={2}>
                      <CngGridItem item xs={12} sm={12}>
                        <SchedulesStepper
                          countryList={country}
                          voyageLegs={voyageData.voyageLegs}
                        />
                      </CngGridItem>
                    </Grid>
                  </Card>
                </Box>
              </Card>
            </Box>
          </Collapse>

          {/* </Grid> */}
        </Card>
      </Box>

      <Grid container>
        <Grid container item xs={12} sm={12} spacing={2}>
          {/* <LabelValueVertical 
                        label={translatedTextsObject.remarks}
                        value={voyageData.remarks} /> */}
        </Grid>
      </Grid>
    </Card>
  )
}

const ViewSchduleInfoComponent = Object.freeze({
  FormBody: FormBody,
  initialValues: initialValues
})

export default ViewSchduleInfoComponent
