import React, { useContext } from 'react'
import { Yup, components, useServices } from 'cng-web-lib'

import Box from '@material-ui/core/Box'
import CancelButton from 'src/components/button/CancelButton.js'
import CarrierAutocompleteField from 'src/components/autocomplete/CarrierAutocompleteField.js'
import ManageBlacklistedCarriersDialogContext from './ManageBlacklistedCarriersDialogContext.js'
import ManageCarriersTranslationText from 'src/views/vesselschedule/managepreferredcarrier/ManageCarriersTranslationText'
import SubmitButton from 'src/components/button/SubmitButton.js'
import TcalVsManageBlacklistedCarriersApiUrls from 'src/apiUrls/TcalVsManageBlacklistedCarriersApiUrls.js'
import { Typography } from '@material-ui/core'
import check from 'check-types'

const {
  form: {CngViewForm},
  table: { useDefaultNotification }
} = components

function ManageBlacklistedCarriersForm(props) {
  const translatedTextsObject = ManageCarriersTranslationText()
  const { createRecord } = useServices()
  const dialogContext = useContext(ManageBlacklistedCarriersDialogContext)
  const {
    closeDialog,
    showNotification,
    form: { isSubmitting, setSubmitting },
    cngTableRef
  } = dialogContext
  const {
    success: showSuccessNotification,
    error: showErrorNotification
  } = useDefaultNotification(showNotification)

  function onSubmit(data) {
    function onSuccess() {
      setSubmitting(false)
      closeDialog()
      if (cngTableRef.current && cngTableRef.current.performRefresh) {
        cngTableRef.current.performRefresh()
      }
      showSuccessNotification(translatedTextsObject.addBlacklistedSuccess)
    }

    function onError(error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.errorMessages &&
        error.response.data.errorMessages.length > 0
      ) {
        showErrorNotification(error.response.data.errorMessages)
      } else {
        showErrorNotification(error.message)
      }
      console.log('manage-blacklisted-carrier error', error.message)
      setSubmitting(false)
    }

    setSubmitting(true)
    createRecord.execute(
      TcalVsManageBlacklistedCarriersApiUrls.POST,
      {
        ...data
      },
      onSuccess,
      onError
    )
  }

  return (
    <CngViewForm
      formikProps={{
        initialValues: {  ...initialValues},
        validationSchema: validationSchema,
        onSubmit: onSubmit,
        validateOnChange: false
      }}
      bodySection={
        <FormBody closeDialog={closeDialog} isSubmitting={isSubmitting} />
      }
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
    />
  )
}

function FormBody({ closeDialog, isSubmitting }) {
  const translatedTextsObject = ManageCarriersTranslationText()
  return (
    <Box m={1.5}>
      <Box mb={2}>
        <Typography variant='body2' gutterBottom>
          {translatedTextsObject.setBlacklistedTitle}
        </Typography>
      </Box>
      <Box my={1}>
        <CarrierAutocompleteField
          name='carrierPartyId'
          label={translatedTextsObject.carriersNovccParty}
        />
      </Box>
      <Box display='flex' flexDirection={'row-reverse'} mt={2}>
        <SubmitButton isSubmitting={isSubmitting}>
          {translatedTextsObject.add}
        </SubmitButton>
        <Box mr={1}>
          <CancelButton onClick={closeDialog} disabled={isSubmitting} />
        </Box>
      </Box>
    </Box>
  )
}

const initialValues = {
  carrierPartyId: -1,
  blacklisted: 1
}

const validationSchema = Yup.object({
  carrierPartyId: Yup.number()
    .transform((value, original) => {
      if (check.number(value)) {
        return value
      }
      return -1
    })
    .min(0, 'Carrier is Required')
})

export default ManageBlacklistedCarriersForm
