import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import CarrierEnrollKeys from 'src/constants/locale/key/CarrierEnroll'
import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
  DataFlattener,
} from 'cng-web-lib'
import makeValidationSchema from './MakeValidationSchema'
import { useTranslation, constants } from 'cng-web-lib'

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngTextField,
      CngLookupAutocompleteField
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  platformName: "",
  carrierPartyId: 0,
  otherPlatformCode: "",
  excludes: ""
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap }) {
  const { translate } = useTranslation(Namespace.CARRIER_ENROLL)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let carrierEnroll = translate(
      Namespace.CARRIER_ENROLL,
      CarrierEnrollKeys.TITLE
    )
    let platformName = translate(
      Namespace.CARRIER_ENROLL,
      CarrierEnrollKeys.PLATFORM_NAME
    )
    let carrierPartyId = translate(
      Namespace.CARRIER_ENROLL,
      CarrierEnrollKeys.PARTY_ID
    )
    let otherPlatformCode = translate(
      Namespace.CARRIER_ENROLL,
      CarrierEnrollKeys.OTHER_PLATFORM_CODE
    )
    let excludes = translate(
      Namespace.CARRIER_ENROLL,
      CarrierEnrollKeys.EXCLUDES
    )

    return {
      carrierEnroll,
      platformName,
      carrierPartyId,
      otherPlatformCode,
      excludes
    }
  }

  return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.carrierEnroll} />
      <CardContent>
        <Grid container spacing={3}>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.platformName}>
            <CngTextField
              name="platformName"
              label={translatedTextsObject.platformName + ' *'}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.carrierPartyId}>
            <CngLookupAutocompleteField
              name='carrierPartyId'
              label={translatedTextsObject.carrierPartyId + ' *'}
              disabled={disabled}
              lookupProps={{
                url: constants.apiUrl.PartyApiUrls.GET,
                label: 'name',
                value: 'id'
              }}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.otherPlatformCode}>
            <CngTextField
              name="otherPlatformCode"
              label={translatedTextsObject.otherPlatformCode + ' *'}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.excludes}>
            <CngTextField
              name="excludes"
              label={translatedTextsObject.excludes}
              disabled={disabled}
            />
          </CngGridItem>
        </Grid>
      </CardContent>
    </Card>
  )
}

function toClientDataFormat(serverData) {
  let localData = DataFlattener.parse(serverData);
  return localData;
}

function toServerDataFormat(localData) {
  return DataFlattener.unflatten(localData);
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
