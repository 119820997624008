import Namespace from 'src/constants/locale/Namespace'
import TcalVsManageScheduleKeys from 'src/constants/locale/key/TcalVsManageSchedule'
import { useTranslation } from 'cng-web-lib'

const ManageScheduleTranslationText = () => {
  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.TCAL_VS_MANAGE_SCHEDULE
  ])

  let title = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.TITLE
  )
  let manageScheduleTitle = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.MANAGE_SCHEDULE_TITLE
  )
  let addScheduleTitle = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.ADD_SCHEDULE_TITLE
  )
  let viewScheduleTitle = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.VIEW_SCHEDULE_TITLE
  )
  let amendScheduleTitle = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.AMEND_SCHEDULE_TITLE
  )
  let voyageList = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.VOYAGE_LIST
  )
  let submittedFileList = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.SUBMITTED_FILE_LIST
  )
  let filledUpForms = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.FILLED_UP_FORMS
  )
  let submittedFiles = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.SUBMITTED_FILES
  )
  let seqNo = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.SEQNO
  )
  let vesselName = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.VESSEL_NAME
  )
  let voyageNo = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.VOYAGE_NO
  )
  let intVoyageNo = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.INT_VOYAGE_NO
  )
  let remarks = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.REMARKS
  )
  let service = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.SERVICE
  )
  let scheduleNo = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.SCHEDULE_NO
  )
  let user = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.USER
  )
  let lastModified = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.LAST_MODIFIED
  )
  let action = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.ACTION
  )
  let searchDesc = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.SEARCH_DESC
  )

  let addScheduleStep1 = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.ADD_SCHEDULE_STEP1
  )
  let addScheduleStep2 = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.ADD_SCHEDULE_STEP2
  )
  let addScheduleStep3 = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.ADD_SCHEDULE_STEP3
  )
  let amendScheduleStep1 = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.AMEND_SCHEDULE_STEP1
  )
  let amendScheduleStep2 = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.AMEND_SCHEDULE_STEP2
  )
  let amendScheduleStep3 = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.AMEND_SCHEDULE_STEP3
  )
  let vesselVoyageInfo = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.VESSEL_VOYAGE_INFO
  )
  let voyageStatus = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.VOYAGE_STATUS
  )
  let voyageNumber = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.VOYAGE_NUMBER
  )
  let internationalVoyageNo = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.INTERNATIONAL_VOYAGE_NO
  )
  let imoNumber = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.IMO_NUMBER
  )
  let additionalVesselVoyageDetails = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.ADDITIONAL_VESSEL_VOYAGE_DETAILS
  )
  let addRemarks = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.ADD_REMARKS
  )
  let submitSchedule = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.SUBMIT_SCHEDULE
  )
  let dialogDiscardScheduleContent = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.DIALOG_DISCARD_SCHEDULE_CONTENT
  )
  let dialogDiscardAmendScheduleContent = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.DIALOG_DISCARD_AMEND_SCHEDULE_CONTENT
  )
  let dialogDiscardScheduleTitle = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.DIALOG_DISCARD_SCHEDULE_TITLE
  )

  let scheduleInformation = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.SCHEDULE_INFORMATION
  )
  let addScheduleStep2Note = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.ADD_SCHEDULE_STEP2_NOTE
  )
  let wayPoint = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.WAY_POINT
  )
  let startingPoint = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.STARTING_POINT
  )
  let stopOverPoint = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.STOPOVER_POINT
  )
  let finalPoint = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.FINAL_POINT
  )
  let transportationMode = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.TRANSPORTATION_MODE
  )
  let transportMode = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.TRANSPORT_MODE
  )
  let port = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.PORT
  )
  let departDate = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.DEPART_DATE
  )
  let departTime = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.DEPART_TIME
  )
  let arrivalDate = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.ARRIVAL_DATE
  )
  let arrivalTime = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.ARRIVAL_TIME
  )
  let cyCutoffDate = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.CYCUTOFF_DATE
  )
  let cyCutoffTime = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.CYCUTOFF_TIME
  )
  
  // Not too sure how to resolve this
  let terminal = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.TERMINAL
  )
  
  let transitTime = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.TRANSIT_TIME
  )
  let additionalWaypointDetails = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.ADDITIONAL_WAYPOINT_DETAILS
  )
  let tansportationModeTooltips = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.TRANSPORTATION_MODE_TOOTIPS
  )

  let deleteRecords = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.DELETE_RECORDS
  )
  let noTakeMeBack = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.NO_TAKE_ME_BACK
  )
  let yesDelete = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.YES_DELETE
  )
  let d1 = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.D1
  )
  let d2 = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.D2
  )
  let d3 = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.D3
  )
  let items = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.ITEMS
  )
  let deleteSuccess = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.DELETE_SUCESS
  )
  let addSuccess = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.ADD_SUCCESS
  )
  let submitChanges = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.SUBMIT_CHANGES
  )
  let scheduleSubmitted = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.SCHEDULE_SUBMITTED
  )
  let vesselService = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.VESSEL_SERVICE
  )
  let cyCutOff = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.CY_CUTOFF
  )
  let departs = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.DEPARTS
  )
  let arrives = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.ARRIVES
  )
  let transportVia = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.TRANSPORT_VIA
  )
  let arrival = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.ARRIVAL
  )
  let departure = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.DEPARTURE
  )
  let vesselVoyageDetails = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.VESSEL_VOYAGE_DETAILS
  )
  let days = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.DAYS
  )
  let transport = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.TRANSPORT
  )
  let saveScheduleSuccess = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.SAVE_SCHEDULE_SUCCESS
  )
  let amendScheduleSuccess = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.AMEND_SCHEDULE_SUCCESS
  )
  let searchDescScheduleInfo = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.SEARCH_DESC_SCHEDULE_INFO
  )
  let backToManageSchedule = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.BACK_TO_MANAGE_SCHEDULE
  )
  let backToSearchSchedule = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.BACK_TO_SEARCH_SCHEDULE
  )
  let searchDescSubmittedFile = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.SEARCH_DESC_SUB_FILE
  )
  let addFileUploadScheduleStep1 = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.ADD_FILE_UPLOAD_SCHEDULE_STEP_1
  )
  let errorSubmittingSchedule = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.ERROR_SUBMITTING_SCHEDULE
  )
  let documents = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.DOCUMENTS
  )
  let downloadTemplate = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.DOWNLOAD_TEMPLATE
  )
  let noFilesYet = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.NOFILESYET
  )
  let uploadDesc = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.UPLOADESC
  )
  let uploadDetails = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.UPLOADDETAILS
  )
  let uploadFileLimitDetails = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.UPLOADFILELIMITDETAILS
  )
  let open = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.OPEN
  )
  let closed = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.CLOSED
  )
  let suspended = translate(
    Namespace.TCAL_VS_MANAGE_SCHEDULE,
    TcalVsManageScheduleKeys.SUSPENDED
  )
  return {
    title,
    manageScheduleTitle,
    addScheduleTitle,
    viewScheduleTitle,
    amendScheduleTitle,
    voyageList,
    submittedFileList,
    filledUpForms,
    submittedFiles,
    downloadTemplate,
    documents,
    seqNo,
    vesselName,
    voyageNo,
    intVoyageNo,
    remarks,
    service,
    scheduleNo,
    user,
    lastModified,
    action,
    searchDesc,

    addScheduleStep1,
    addScheduleStep2,
    addScheduleStep3,
    amendScheduleStep1,
    amendScheduleStep2,
    amendScheduleStep3,
    vesselVoyageInfo,
    voyageStatus,
    voyageNumber,
    internationalVoyageNo,
    imoNumber,
    additionalVesselVoyageDetails,
    addRemarks,
    submitSchedule,
    addFileUploadScheduleStep1,

    dialogDiscardScheduleContent,
    dialogDiscardAmendScheduleContent,
    dialogDiscardScheduleTitle,

    scheduleInformation,
    addScheduleStep2Note,
    wayPoint,
    startingPoint,
    stopOverPoint,
    finalPoint,
    transportationMode,
    transportMode,
    port,
    departDate,
    departTime,
    arrivalDate,
    arrivalTime,
    cyCutoffDate,
    cyCutoffTime,
    transitTime,
    terminal,
    additionalWaypointDetails,
    tansportationModeTooltips,

    deleteRecords,
    noTakeMeBack,
    yesDelete,
    d1,
    d2,
    d3,
    items,
    deleteSuccess,
    addSuccess,

    submitChanges,
    scheduleSubmitted,
    vesselService,
    cyCutOff,
    departs,
    arrives,
    transportVia,
    arrival,
    departure,
    vesselVoyageDetails,
    days,
    transport,

    saveScheduleSuccess,
    amendScheduleSuccess,
    searchDescScheduleInfo,
    backToManageSchedule,
    searchDescSubmittedFile,
    backToSearchSchedule,

    errorSubmittingSchedule,
    noFilesYet,
    uploadDesc,
    uploadDetails,
    uploadFileLimitDetails,

    open,
    closed,
    suspended
  }
}

export default ManageScheduleTranslationText
