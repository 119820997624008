import { Box, Grid, Paper, Tooltip, Typography } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import { components, useServices } from 'cng-web-lib'
import { useHistory, useLocation } from 'react-router-dom'

import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined'
import DocumentDetailsComponents from './DocumentDetailsComponents'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import GeneralStepperComponent from 'src/views/common/ui/GeneralStepperComponent'
import Loading from 'src/views/vesselschedule/searchschedule/Loading'
import ManageScheduleTranslationText from '../ManageScheduleTranslationText'
import TcalVsManageScheduleApiUrls from 'src/apiUrls/TcalVsManageScheduleApiUrls'
import fileDownload from 'js-file-download'
import moment from 'moment'
import pathMap from 'src/paths/PathMap_VesselSchedule'

const {
  table: { useDefaultNotification },
  button: { CngPrimaryButton }
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES }
}

function FormFields({ disabled, showNotification }) {
  const history = useHistory()
  const location = useLocation()
  const [loading, setLoading] = useState(true)
  const { createRecord } = useServices()
  const errorMsg = useRef('')

  const msTranslatedTextsObject = ManageScheduleTranslationText()

  const {
    success: showSuccessNotification,
    error: showErrorNotification
  } = useDefaultNotification(showNotification)

  const addScheduleStep = [
    msTranslatedTextsObject.addFileUploadScheduleStep1,
    msTranslatedTextsObject.addScheduleStep3
  ]

  const downloadUploadedRowClick = (id) => {
    const doc = location.state.processDocuments[id]
    fileDownload(doc.file, doc.file.name)
  }

  useEffect(() => {
    createRecord.execute(
      TcalVsManageScheduleApiUrls.DOCUMENT_PUBLISH,
      { batchNo: location.state.processDocuments[0].batchNo },
      () => {
        setLoading(false)
      },
      (error) => {
        showErrorNotification(error.message)
        errorMsg.current = error.response.data.message
        console.log(errorMsg.current)
        setLoading(false)
      }
    )
  }, [])

  return (
    <Box>
      <Paper>
        <Box alignItems='center' pr={15} pl={15}>
          <GeneralStepperComponent steps={addScheduleStep} activeStep={1} />
        </Box>
        <Loading loading={loading} />
        {!loading ? (
          <Box p={3} className='page-content'>
            <Box fontWeight={600} fontSize='h3.fontSize'>
              <Box>
                <Box display='flex' flexDirection='row'>
                  {errorMsg.current === '' ? (
                    msTranslatedTextsObject.scheduleSubmitted
                  ) : (
                    <Box className='redText'>
                      {msTranslatedTextsObject.errorSubmittingSchedule}
                    </Box>
                  )}
                  <Box pl={1.5}>
                    {errorMsg.current === '' ? (
                      <CheckCircleOutlineOutlinedIcon className='light-green' />
                    ) : (
                      <Tooltip title={errorMsg.current} placement='top'>
                        <ErrorOutlineIcon className='redText' />
                      </Tooltip>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box display='flex' fontSize='small'>
              {'Submitted on'}&nbsp;
              <Box fontWeight={600}>
                {moment().format(' dddd, DD MMM YYYY')} at{' '}
                {moment().format('HH:mm')} GMT{' '}
              </Box>
            </Box>
            <Box pt={3}>
              <Paper>
                <Box pt={3} ml={2}>
                  <Typography variant='h4' style={{ fontWeight: 'bold' }}>
                    {msTranslatedTextsObject.documents}
                  </Typography>
                </Box>
                <Box m={2}>
                  <DocumentDetailsComponents
                    tile={location.state.processDocuments}
                    hideEdit={true}
                    hideDelete={true}
                    hideStatus={true}
                    downloadRowClick={downloadUploadedRowClick}
                  />
                </Box>
                <Box p={2}></Box>
              </Paper>
            </Box>

            <Box mt={2}>
              <Grid container xs={12} sm={12} justify='flex-end'>
                <Box>
                  <CngPrimaryButton
                    onClick={() => {
                      history.push(pathMap.TCAL_VS_MANAGE_SCHEDULE_LIST)
                    }}
                  >
                    {msTranslatedTextsObject.manageScheduleTitle}
                  </CngPrimaryButton>
                </Box>
              </Grid>
            </Box>
          </Box>
        ) : null}
      </Paper>
    </Box>
  )
}

const AddScheduleFileUploadFinishFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  FormFields: FormFields
})

export default AddScheduleFileUploadFinishFormProperties
