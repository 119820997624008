import React, { useRef, useState } from 'react'
import { components, useServices } from 'cng-web-lib'
import { useHistory, useParams } from 'react-router-dom'

import FreightBookingTranslationText from 'src/views/freightbooking/shared/FreightBookingTranslationText'
import ManageScheduleTranslationText from '../ManageScheduleTranslationText'
import ViewScheduleFormProperties from './ViewScheduleFormProperties'
import pathMap from 'src/paths/PathMap_VesselSchedule'

// const validationSchema = useNewBookingMakeValidationSchema()

const {
  form: { CngViewForm },
  table: { useDefaultNotification }
} = components

function ViewSchedulePage({ showNotification }) {
  const { id } = useParams()
  const history = useHistory()
  const translatedTextsObject = ManageScheduleTranslationText()

  const {
    success: showSuccessNotification,
    error: showErrorNotification
  } = useDefaultNotification(showNotification)

  function onSubmit(data) {
    console.log('submit', data)
    history.push({
      pathname: pathMap.TCAL_VS_MANAGE_SCHEDULE_EDIT,
      state: {scheduleId : data}
    })
  }

  return (
    // use CngViewForm here for customizable button set
    <CngViewForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      history={history}
      showNotification={showNotification}
      bodySection={
        <ViewScheduleFormProperties.FormFields
          // disabled={false}
          showNotification={showNotification}
        />
      }
      formikProps={{
        ...ViewScheduleFormProperties.formikProps,
        // validationSchema,
        onSubmit: onSubmit
      }}
    />
  )
}

export default ViewSchedulePage
