import ValidationMessageTranslationText from 'src/views/common/CommonValidationTranslationText'
import { Yup } from 'cng-web-lib'

function VesselVoyageValidationSchema() {


  const validationMessageTranslation = ValidationMessageTranslationText()

  return Yup.object({

    vesselId: Yup.string().required(validationMessageTranslation.requiredMessage).nullable(),
    voyageStatus: Yup.string().required(validationMessageTranslation.requiredMessage).nullable(),
    voyageNo: Yup.string().required(validationMessageTranslation.requiredMessage).nullable(),
  })
}

export default VesselVoyageValidationSchema

