import { DateTimeFormatter, Yup, components, useServices } from 'cng-web-lib'
import React, { useContext } from 'react'

import CancelButton from 'src/components/button/CancelButton.js'
import ManageCarriersTranslationText from './ManageCarriersTranslationText'
import ManageEditPreferredCarrierDialogContext from './ManageEditPreferredCarrierDialogContext.js'
import SubmitButton from 'src/components/button/SubmitButton.js'
import TcalVsManagePreferredCarrierApiUrls from 'src/apiUrls/TcalVsManagePreferredCarrierApiUrls.js'
import { Typography, Avatar, Box, Paper } from '@material-ui/core'
import moment from 'moment'

const {
  form: {
    CngViewForm,
    adapter: {
      useFormAdapter: { useField, useFormikContext }
    },
    field: { CngCheckboxField, CngDateField }
  },
  table: { useDefaultNotification }
} = components

function ManagePreferredCarrierForm(props) {
  const translatedTextsObject = ManageCarriersTranslationText()
  const { updateRecord } = useServices()
  const dialogContext = useContext(ManageEditPreferredCarrierDialogContext)
  const {
    closeDialog,
    showNotification,
    rowData,
    form: { isSubmitting, setSubmitting },
    cngTableRef
  } = dialogContext
  const { success: showSuccessNotification, error: showErrorNotification } =
    useDefaultNotification(showNotification)

  function onSubmit(data) {
    function onSuccess() {
      setSubmitting(false)
      closeDialog()
      if (cngTableRef.current && cngTableRef.current.performRefresh) {
        cngTableRef.current.performRefresh()
      }
      showSuccessNotification(translatedTextsObject.editPreferredCarrierSuccess)
    }

    function onError(error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.errorMessages &&
        error.response.data.errorMessages.length > 0
      ) {
        showErrorNotification(error.response.data.errorMessages)
      } else {
        showErrorNotification(error.message)
      }
      console.log('manage-edit-preferred-carrier error', error.message)
      setSubmitting(false)
    }

    setSubmitting(true)

    updateRecord.execute(
      TcalVsManagePreferredCarrierApiUrls.PUT,
      {
        ...data,
        contractStartDate: data.contracted ? data.contractStartDate : null,
        contractEndDate: data.contracted ? data.contractEndDate : null,
        sentExpiredNtf:
          data.contracted &&
          moment(DateTimeFormatter.toClientDate(new Date())).isAfter(
            moment(data.contractEndDate)
          )
            ? true
            : false
      },
      onSuccess,
      onError
    )
  }

  return (
    <CngViewForm
      formikProps={{
        initialValues: {
          id: rowData.id,
          version: rowData.version,
          shipperPartyId: rowData.shipperPartyId,
          carrierPartyId: rowData.partyId,
          contracted: rowData.contracted,
          contractStartDate: moment(rowData.contractStartDate).format(
            'YYYY-MM-DD'
          ),
          contractEndDate: moment(rowData.contractEndDate).format('YYYY-MM-DD')
        },
        validationSchema: validationSchema,
        onSubmit: onSubmit
      }}
      renderBodySection={() => (
        <FormBody
          closeDialog={closeDialog}
          isSubmitting={isSubmitting}
          rowData={rowData}
        />
      )}
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
    />
  )
}

function FormBody({ closeDialog, isSubmitting, rowData }) {
  const translatedTextsObject = ManageCarriersTranslationText()
  const [contractedField, , { setValue: setContracted }] =
    useField('contracted')
  const [contractStartDateField, , { setValue: setContractStartDate }] =
    useField('contractStartDate')
  const [, , { setValue: setContractEndDate }] = useField('contractEndDate')
  const { errors } = useFormikContext()

  const getFieldError = (fieldPath) => {
    if (errors) {
      if (errors[fieldPath]) {
        return errors[fieldPath]
      }
    }
    return null
  }

  return (
    <Box m={1.5}>
      <Box display='flex' alignItems='center' my={2}>
        <Box>
          <Avatar
            classes={{
              img: 'vs-carrier-image'
            }}
            alt='Carrier'
            src={`${process.env.PUBLIC_URL}/static/images/carriers/${rowData.code}.svg`}
          >
            <img
              className={'search-button'}
              src={`${process.env.PUBLIC_URL}/static/images/carriers/genericCarrier.svg`}
            ></img>
          </Avatar>
        </Box>
        <Box ml={1.5} flexGrow={1}>
          <b>{rowData.name}</b>
        </Box>
      </Box>
      <Paper
        style={{
          backgroundColor: contractedField.value ? '#FAFBFC' : '#FFFFFF'
        }}
      >
        <Box m={1}>
          <Box mt={1}>
            <CngCheckboxField
              disabled={isSubmitting}
              name='contracted'
              label={
                <Typography variant='body2' gutterBottom>
                  {translatedTextsObject.contracted}
                </Typography>
              }
              onChange={() => {
                setContracted(!contractedField.value)
                setContractStartDate(DateTimeFormatter.toClientDate(new Date()))
                setContractEndDate(
                  DateTimeFormatter.toClientDate(
                    moment().add(30, 'days').toDate()
                  )
                )
              }}
            />
          </Box>
          {contractedField.value && (
            <Box my={1}>
              <Box display='flex'>
                <Box flexGrow={1} />
                <Box width={0.45} mr={1.5} mb={1.5}>
                  <CngDateField
                    name='contractStartDate'
                    label={translatedTextsObject.startingDate}
                    onChange={(date) => {
                      if (moment(date, 'YYYY-MM-DD').isValid()) {
                        setContractStartDate(
                          DateTimeFormatter.toClientDate(date.toDate())
                        )
                      } else {
                        setContractStartDate(date)
                      }
                    }}
                    error={getFieldError(`contractStartDate`)}
                    helperText={getFieldError(`contractStartDate`)}
                  />
                </Box>
                <Box width={0.45}>
                  <CngDateField
                    name='contractEndDate'
                    label={translatedTextsObject.endingDate}
                    shouldDisableDate={(date) => {
                      return moment(date).isBefore(
                        moment(contractStartDateField.value)
                      )
                    }}
                    error={getFieldError(`contractEndDate`)}
                    helperText={getFieldError(`contractEndDate`)}
                  />
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Paper>
      <Box display='flex' flexDirection={'row-reverse'} mt={2}>
        <SubmitButton isSubmitting={isSubmitting}>
          {translatedTextsObject.apply}
        </SubmitButton>
        <Box mr={1}>
          <CancelButton onClick={closeDialog} disabled={isSubmitting} />
        </Box>
      </Box>
    </Box>
  )
}

const validationSchema = Yup.object({
  contractStartDate: Yup.date().when('contracted', {
    is: true,
    then: Yup.date()
      .validFormat()
      .typeError('Invalid date format. Only "YYYY-MM-DD" format is valid.'),
    otherwise: Yup.date()
  }),
  contractEndDate: Yup.date().when('contracted', {
    is: true,
    then: Yup.date()
      .validFormat()
      .typeError('Invalid date format. Only "YYYY-MM-DD" format is valid.')
      .min(
        Yup.ref('contractStartDate'),
        "Contract end date can't be before Contract start date."
      ),
    otherwise: Yup.date()
  })
})

export default ManagePreferredCarrierForm
