import { DateTimeFormatter, Yup, components, useServices } from 'cng-web-lib'
import React, { useContext } from 'react'

import Box from '@material-ui/core/Box'
import CancelButton from 'src/components/button/CancelButton.js'
import CarrierAutocompleteField from 'src/components/autocomplete/CarrierAutocompleteField.js'
import ManageCarriersTranslationText from './ManageCarriersTranslationText'
import ManagePreferredCarrierDialogContext from './ManagePreferredCarrierDialogContext.js'
import SubmitButton from 'src/components/button/SubmitButton.js'
import TcalVsManagePreferredCarrierApiUrls from 'src/apiUrls/TcalVsManagePreferredCarrierApiUrls.js'
import { Typography, Paper } from '@material-ui/core'
import check from 'check-types'
import moment from 'moment'

const {
  form: {
    CngViewForm,
    adapter: {
      useFormAdapter: { useField, useFormikContext }
    },
    field: { CngCheckboxField, CngDateField }
  },
  table: { useDefaultNotification }
} = components

function ManagePreferredCarrierForm(props) {
  const translatedTextsObject = ManageCarriersTranslationText()
  const { createRecord } = useServices()
  const dialogContext = useContext(ManagePreferredCarrierDialogContext)
  const {
    closeDialog,
    showNotification,
    form: { isSubmitting, setSubmitting },
    cngTableRef
  } = dialogContext
  const { success: showSuccessNotification, error: showErrorNotification } =
    useDefaultNotification(showNotification)

  function onSubmit(data) {
    function onSuccess() {
      setSubmitting(false)
      closeDialog()
      if (cngTableRef.current && cngTableRef.current.performRefresh) {
        cngTableRef.current.performRefresh()
      }
      showSuccessNotification(translatedTextsObject.addPreferredCarrierSuccess)
    }

    function onError(error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.errorMessages &&
        error.response.data.errorMessages.length > 0
      ) {
        showErrorNotification(error.response.data.errorMessages)
      } else {
        showErrorNotification(error.message)
      }
      console.log('manage-preferred-carrier error', error.message)
      setSubmitting(false)
    }

    setSubmitting(true)

    createRecord.execute(
      TcalVsManagePreferredCarrierApiUrls.POST,
      {
        ...data,
        contractStartDate: data.contracted ? data.contractStartDate : null,
        contractEndDate: data.contracted ? data.contractEndDate : null,
        sentExpiredNtf:
          data.contracted &&
          moment(DateTimeFormatter.toClientDate(new Date())).isAfter(
            moment(data.contractEndDate)
          )
            ? true
            : false
      },
      onSuccess,
      onError
    )
  }

  return (
    <CngViewForm
      formikProps={{
        initialValues: {
          ...initialValues,
          contractStartDate: DateTimeFormatter.toClientDate(new Date()),
          contractEndDate: DateTimeFormatter.toClientDate(
            moment().add(30, 'days').toDate()
          ),
          sentExpiredNtf: false
        },
        validationSchema: validationSchema,
        onSubmit: onSubmit,
        validateOnChange: false
      }}
      renderBodySection={() => (
        <FormBody closeDialog={closeDialog} isSubmitting={isSubmitting} />
      )}
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
    />
  )
}

function FormBody({ closeDialog, isSubmitting }) {
  const translatedTextsObject = ManageCarriersTranslationText()
  const [contractedField, , { setValue: setContracted }] =
    useField('contracted')
  const [contractStartDateField, , { setValue: setContractStartDate }] =
    useField('contractStartDate')
  const [, , { setValue: setContractEndDate }] = useField('contractEndDate')

  return (
    <Box m={1.5} mt={2} >
      <Box mb={2}>
        <Typography variant='body2' gutterBottom>
          {translatedTextsObject.setPreferredTitle}
        </Typography>
      </Box>
      <Box my={1}>
        <CarrierAutocompleteField
          name='carrierPartyId'
          label={translatedTextsObject.carriersNovccParty}
        />
      </Box>
      <Paper
        style={{
          backgroundColor: contractedField.value ? '#FAFBFC' : '#FFFFFF'
        }}
      >
        <Box m={1}>
          <Box my={1}>
            <CngCheckboxField
              disabled={isSubmitting}
              name='contracted'
              label={
                <Typography variant='body2' gutterBottom>
                  {translatedTextsObject.contracted}
                </Typography>
              }
              onChange={() => {
                setContracted(!contractedField.value)
                setContractStartDate(DateTimeFormatter.toClientDate(new Date()))
                setContractEndDate(
                  DateTimeFormatter.toClientDate(
                    moment().add(30, 'days').toDate()
                  )
                )
              }}
            />
          </Box>
          {contractedField.value && (
            <Box my={1}>
              <Box display='flex'>
                <Box flexGrow={1} />
                <Box width={0.45} mr={1.5} mb={1}>
                  <CngDateField
                    style={{ backgroundColor: '#FFFFFF' }}
                    name='contractStartDate'
                    label={translatedTextsObject.startingDate}
                    onChange={(date) => {
                      if (moment(date, 'YYYY-MM-DD').isValid()) {
                        setContractStartDate(
                          DateTimeFormatter.toClientDate(date.toDate())
                        )
                      } else {
                        setContractStartDate(date)
                      }
                    }}
                  />
                </Box>
                <Box width={0.45}>
                  <CngDateField
                    style={{ backgroundColor: '#FFFFFF' }}
                    name='contractEndDate'
                    label={translatedTextsObject.endingDate}
                    shouldDisableDate={(date) => {
                      return moment(date).isBefore(
                        moment(contractStartDateField.value)
                      )
                    }}
                  />
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Paper>
      <Box display='flex' flexDirection={'row-reverse'} mt={2}>
        <SubmitButton isSubmitting={isSubmitting}>
          {translatedTextsObject.add}
        </SubmitButton>
        <Box mr={1}>
          <CancelButton onClick={closeDialog} disabled={isSubmitting} />
        </Box>
      </Box>
    </Box>
  )
}

const initialValues = {
  carrierPartyId: -1,
  contracted: true,
  contractStartDate: '',
  contractEndDate: '',
  sentExpiredNtf: false
}

const validationSchema = Yup.object({
  carrierPartyId: Yup.number()
    .transform((value, original) => {
      if (check.number(value)) {
        return value
      }
      return -1
    })
    .min(0, 'Carrier is Required.'),
  contractStartDate: Yup.date().when('contracted', {
    is: true,
    then: Yup.date()
      .validFormat()
      .typeError('Invalid date format. Only "YYYY-MM-DD" format is valid.'),
    otherwise: Yup.date()
  }),
  contractEndDate: Yup.date().when('contracted', {
    is: true,
    then: Yup.date()
      .validFormat()
      .typeError('Invalid date format. Only "YYYY-MM-DD" format is valid.')
      .min(
        Yup.ref('contractStartDate'),
        "Contract end date can't be before Contract start date."
      ),
    otherwise: Yup.date()
  })
})

export default ManagePreferredCarrierForm
