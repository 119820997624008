import AddScheduleDetailsPage from './addschedule/AddScheduleDetailsPage'
import AddScheduleFileUploadFinishPage from './addscheduleFileUpload/AddScheduleFileUploadFinishPage'
import AddScheduleFileUploadPage from './addscheduleFileUpload/AddScheduleFileUploadPage'
import AddScheduleFinishPage from 'src/views/vesselschedule/manageschedule/addschedule/AddScheduleFinishPage'
import AddSchedulePage from './addschedule/AddSchedulePage'
import { BasePage as CngBasePage } from 'cng-web-lib'
import ManageScheduleTable from './ManageScheduleTable'
import React from 'react'
import TranslationText from 'src/views/vesselschedule/searchschedule/SearchScheduleTranslationText'
import ViewSchedulePage from 'src/views/vesselschedule/manageschedule/viewschedule/ViewSchedulePage'
import useBreadcrumbNameMap from 'src/navigations/breadcrumbs/Breadcrumb_VesselSchedule'

function WrappedManageScheduleTablePage(props) {
  const translatedTextsObject = TranslationText()
  const breadcrumbNameMap = useBreadcrumbNameMap()

  return (
    <CngBasePage
      moduleTitle={translatedTextsObject.vesselSchedule}
      renderPage={(showSnackbar) => (
        <ManageScheduleTable showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

function WrappedAddSchedulePage(props) {
  const translatedTextsObject = TranslationText()
  const breadcrumbNameMap = useBreadcrumbNameMap()
  return (
    <CngBasePage
      moduleTitle={translatedTextsObject.vesselSchedule}
      renderPage={(showSnackbar) => (
        <AddSchedulePage showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

function WrappedAddScheduleDetailsPage(props) {
  const translatedTextsObject = TranslationText()
  const breadcrumbNameMap = useBreadcrumbNameMap()
  return (
    <CngBasePage
      moduleTitle={translatedTextsObject.vesselSchedule}
      renderPage={(showSnackbar) => (
        <AddScheduleDetailsPage showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

function WrappedAddScheduleFinishPage(props) {
  const translatedTextsObject = TranslationText()
  const breadcrumbNameMap = useBreadcrumbNameMap()
  return (
    <CngBasePage
      moduleTitle={translatedTextsObject.vesselSchedule}
      renderPage={(showSnackbar) => (
        <AddScheduleFinishPage showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

function WrappedViewSchedulePage(props) {
  const translatedTextsObject = TranslationText()
  const breadcrumbNameMap = useBreadcrumbNameMap()
  return (
    <CngBasePage
      moduleTitle={translatedTextsObject.vesselSchedule}
      renderPage={(showSnackbar) => (
        <ViewSchedulePage showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

function WrappedAmendSchedulePage(props) {
  const translatedTextsObject = TranslationText()
  const breadcrumbNameMap = useBreadcrumbNameMap()
  return (
    <CngBasePage
      moduleTitle={translatedTextsObject.vesselSchedule}
      renderPage={(showSnackbar) => (
        <AddSchedulePage showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

function WrappedAmendScheduleDetailsPage(props) {
  const translatedTextsObject = TranslationText()
  const breadcrumbNameMap = useBreadcrumbNameMap()
  return (
    <CngBasePage
      moduleTitle={translatedTextsObject.vesselSchedule}
      renderPage={(showSnackbar) => (
        <AddScheduleDetailsPage showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

function WrappedAmendScheduleFinishPage(props) {
  const translatedTextsObject = TranslationText()
  const breadcrumbNameMap = useBreadcrumbNameMap()
  return (
    <CngBasePage
      moduleTitle={translatedTextsObject.vesselSchedule}
      renderPage={(showSnackbar) => (
        <AddScheduleFinishPage showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

function WrappedAddScheduleFileUploadPage(props) {
  const translatedTextsObject = TranslationText()
  const breadcrumbNameMap = useBreadcrumbNameMap()
  return (
    <CngBasePage
      moduleTitle={translatedTextsObject.vesselSchedule}
      renderPage={(showSnackbar) => (
        <AddScheduleFileUploadPage showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

function WrappedAddScheduleFileUploadFinishPage(props) {
  const translatedTextsObject = TranslationText()
  const breadcrumbNameMap = useBreadcrumbNameMap()
  return (
    <CngBasePage
      moduleTitle={translatedTextsObject.vesselSchedule}
      renderPage={(showSnackbar) => (
        <AddScheduleFileUploadFinishPage
          showNotification={showSnackbar}
          {...props}
        />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

function WrappedViewUploadScheduleTablePage(props) {
  const translatedTextsObject = TranslationText()
  const breadcrumbNameMap = useBreadcrumbNameMap()

  return (
    <CngBasePage
      moduleTitle={translatedTextsObject.vesselSchedule}
      renderPage={(showSnackbar) => (
        <ManageScheduleTable showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

export {
  WrappedManageScheduleTablePage as ManageScheduleTablePage,
  WrappedAddSchedulePage as AddSchedulePage,
  WrappedAddScheduleDetailsPage as AddScheduleDetailsPage,
  WrappedAddScheduleFinishPage as AddScheduleFinishPage,
  WrappedViewSchedulePage as ViewSchedulePage,
  WrappedAmendSchedulePage as AmendSchedulePage,
  WrappedAmendScheduleDetailsPage as AmendScheduleDetailsPage,
  WrappedAmendScheduleFinishPage as AmendScheduleFinishPage,
  WrappedViewUploadScheduleTablePage as ViewUploadScheduleTablePage,
  WrappedAddScheduleFileUploadPage as AddScheduleFileUploadPage,
  WrappedAddScheduleFileUploadFinishPage as AddScheduleFileUploadFinishPage
}
