import {
  Box,
  Card,
  Chip,
  Collapse,
  Grid,
  Table,
  TableContainer,
  TableFooter,
  TableRow
} from '@material-ui/core'
import {
  DateTimeFormatter,
  components,
  constants,
  useServices
} from 'cng-web-lib'
import React, { useEffect, useRef, useState } from 'react'

import CngTableBody from 'src/components/table/CngTableBody'
import CngTableHeader from 'src/components/table/CngTableHeader'
import CngToolbarTable from 'src/components/table/CngToolbarTable'
import CodeMaintainanceApi from 'src/views/common/api/CodeMatainanceApi'
import ManageScheduleTranslationText from '../ManageScheduleTranslationText'
import PortCountryViewField from 'src/components/field/PortCountryViewField'
import PortTransportModeStepper from 'src/components/field/PortTransportModeStepper'
import ResultPageTablePagination from 'src/views/vesselschedule/searchschedule/ResultPageTablePagination'
import { makeStyles } from '@material-ui/core'
import moment from 'moment'
import { v4 as uuid } from 'uuid'

const {
  form: {
    field: { CngTextField }
  },
  CngGridItem
} = components

const { CodeMaintenanceType } = constants

const initialValues = Object.freeze({
  remarks: ''
})

const useStyles = makeStyles((theme) => ({
  div: {
    '& .MuiTable-root': {
      borderSpacing: '0 5px',
      borderCollapse: 'separate',
      '& .MuiTableHead-root .MuiTableRow-head .MuiTableCell-head': {
        backgroundColor: '#fafbfc',
        borderBottom: 'none',
        '&:nth-child(1)': {
          // width: '5% !important'
          width: 'auto !important'
        },
        '&:nth-child(n + 2):nth-last-child(n + 2)': {
          // width: '20% !important'
          width: 'auto !important'
        }
      },
      '& .MuiTableRow-root[index]': {
        '&:nth-child(even)': {
          backgroundColor: '#fafbfc'
        },
        '& td[class*="MuiTableCell-root"]': {
          border: '1px solid #f1f4f9 !important',
          borderLeft: 'none !important',
          borderRight: 'none !important',
          fontSize: '130px !important',
          fontWeight: '600 !important',
          '&:nth-child(1)': {
            borderLeft: '1px solid #f1f4f9 !important',
            borderRadius: '5px 0 0 5px !important',
            width: 'auto !important'
          },
          '&:nth-child(n + 2):nth-last-child(n + 2)': {
            width: '20% !important'
          },
          '&:nth-last-child(1)': {
            borderRight: '1px solid #f1f4f9 !important',
            borderRadius: '0 5px 5px 0 !important',
            '& .MuiIconButton-root': {
              border: '1px solid #f4f4f7',
              borderRadius: '10px'
            }
          }
        }
      }
    }
  },
  trashIcon: {
    color: theme.palette.text.error,
    width: theme.spacing(2),
    height: theme.spacing(2)
  }
}))

const FormBody = (props) => {
  const { showNotification } = props
  const classes = useStyles()
  const { voyageData } = props
  const translatedTextsObject = ManageScheduleTranslationText()
  const [country, setCountry] = useState([])
  const { fetchRecords } = useServices()
  const [voyageLegs, setVoyageLegs] = useState([])
  const [originalVoyageLegs, setOriginalVoyageLegs] = useState([])
  const [search, setSearch] = useState('')
  const [openSearchPanel, setOpenSearchPanel] = useState(false)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [columns, setColumns] = useState([
    // {
    //   title: translatedTextsObject.seqNo.toUpperCase(),
    //   active: false,
    //   field: 'seqNo',
    //   show: true,
    // },
    {
      title: translatedTextsObject.port.toUpperCase(),
      active: false,
      field: 'portCode',
      show: true,
      type: 'portCountry'
    },
    {
      title: translatedTextsObject.wayPoint.toUpperCase(),
      active: false,
      field: 'waypoint',
      show: true
    },
    {
      title: translatedTextsObject.transportMode.toUpperCase(),
      active: false,
      field: 'transportMode',
      show: true,
      type: 'portTransportStepper'
    },
    {
      title: translatedTextsObject.arrival.toUpperCase(),
      active: false,
      field: 'arrivalTime',
      show: true,
      type: 'date',
      dateFormat: 'D MMM YYYY HH:mm'
    },
    {
      title: translatedTextsObject.departure.toUpperCase(),
      active: false,
      field: 'departureTime',
      show: true,
      type: 'date',
      dateFormat: 'D MMM YYYY HH:mm'
    },
    {
      title: translatedTextsObject.cyCutOff.toUpperCase(),
      active: false,
      field: 'cyCutoffTime',
      show: true,
      type: 'date',
      dateFormat: 'D MMM YYYY HH:mm'
    },
    {
      title: translatedTextsObject.terminal.toUpperCase(),
      active: false,
      field: 'terminal',
      show: true,
      type: 'truncate'
    }
  ])

  useEffect(() => {
    CodeMaintainanceApi.fetchCodeMaintainance(
      fetchRecords,
      CodeMaintenanceType.COUNTRY,
      [],
      false,
      onLoadCountrySuccess
    )

    function onLoadCountrySuccess(data) {
      setCountry(data)
    }

    let voyLegs = voyageData.voyageLegs
      .sort((a, b) => (a.seqNo > b.seqNo ? 1 : -1))
      .map((v, index) => ({
        ...v,
        //The port with the smallest sequence number should be considered as the starting point and the largest should be the final point, the rest should be stop over point.
        waypoint:
          index === 0
            ? translatedTextsObject.startingPoint
            : index === voyageData.voyageLegs.length - 1
            ? translatedTextsObject.finalPoint
            : translatedTextsObject.stopOverPoint,
        arrivalTime: index === 0 ? '' : v.arrivalTime,
        departureTime:
          index === voyageData.voyageLegs.length - 1 ? '' : v.departureTime
      }))
    console.log('voyLegs >' + JSON.stringify(voyLegs))
    setVoyageLegs(voyLegs)
    setOriginalVoyageLegs(voyLegs)
    setRowsPerPage(voyLegs.length)
  }, [])

  const onHeaderCheck = (event) => {
    let newVoyageLegs = [...voyageLegs]
    newVoyageLegs = newVoyageLegs.map((item) => ({
      ...item,
      checked: event.target.checked
    }))
    setVoyageLegs(newVoyageLegs)
  }

  const onRowCheck = (id) => () => {
    //do nothing
  }
  const onRowClick = (id) => () => {
    //do nothing
  }

  const onSortClick = (fieldName) => () => {
    //do nothing
  }

  const onCancel = () => {
    // do nothing
  }

  // const handleChangePage = (event, newPage) => {
  //     setPage(newPage)
  // }

  // const handleChangeRowsPerPage = (event) => {
  //     setRowsPerPage(parseInt(event.target.value, 10))
  //     setPage(0)
  // }

  const onSearchChange = (e) => {
    setSearch(e.target.value)
    filterTextImplementation(e.target.value)
  }

  function filterTextImplementation(filterText) {
    let newVoyageLegs = [...originalVoyageLegs]
    // alert(JSON.stringify(newVoyageLegs))
    newVoyageLegs = newVoyageLegs.filter(
      (item) =>
        !filterText ||
        item.portCode.toUpperCase().includes(filterText.toUpperCase()) ||
        (item.portName !== null &&
          item.portName.toUpperCase().includes(filterText.toUpperCase()))
      //   ||
      //   (item.voyageNo !== null && item.voyageNo.includes(filterText))
    )
    //   .map((v, index) => ({ ...v, seqNo: index + 1 }))

    setVoyageLegs(newVoyageLegs)
  }

  const onClickSearchPanel = () => {
    setOpenSearchPanel(true)
  }

  const onCloseSearchPanel = () => {
    setSearch('')
    filterTextImplementation('')
    setOpenSearchPanel(false)
    setColumns(
      columns.map((column) => ({
        ...column,
        active: column.field === 'name',
        order: column.field === 'name' ? 'desc' : undefined
      }))
    )
  }

  const onCheckBoxViewColumnChange =
    (index) =>
    ({ target: { checked } }) => {
      let newColumns = [...columns]
      const column = columns[index]
      newColumns[index] = { ...column, show: !column.show }
      setColumns(newColumns)
    }

  return (
    <Card>
      {/* <Box p={3}> */}
      <Card style={{ borderWidth: 4 }}>
        <Box m='8px' className={classes.div}>
          <CngToolbarTable
            data={voyageLegs}
            dataType='Schedule' // to specific export data in ExportComponent
            onCancel={onCancel}
            openSearchPanel={openSearchPanel}
            searchDesc={translatedTextsObject.searchDescScheduleInfo}
            search={search}
            onSearchChange={onSearchChange}
            onCloseSearchPanel={onCloseSearchPanel}
            page={page}
            rowsPerPage={rowsPerPage}
            onClickSearchPanel={onClickSearchPanel}
            columns={columns}
            onCheckBoxViewColumnChange={onCheckBoxViewColumnChange}
            classes={classes}
            showNotification={showNotification}
            viewOnly={true}
            disableSearch={true}
            // addButtonText={translatedTextsObject.addScheduleTitle}
            // onAddButtonClick={onAddButtonClick}
            // onOpenConfirmDialog={onOpenConfirmDialog}
            // user={user}
            // userProfile={userProfile}
          />
          <TableContainer>
            <Table>
              <CngTableHeader
                data={voyageLegs}
                onHeaderCheck={onHeaderCheck}
                columns={columns}
                onSortClick={onSortClick}
                viewOnly={true}
              />
              <CngTableBody
                data={voyageLegs}
                page={page}
                rowsPerPage={rowsPerPage}
                columns={columns}
                onRowCheck={onRowCheck}
                onRowClick={onRowClick}
                // onCheckBoxChange={onCheckBoxChange}
                // user={user}
                // userProfile={userProfile}
                // showCheckbox
                // showAction
                customClassName='schedule-info-row'
                viewOnly={true}
              />
              {/* <TableFooter>
                                    <TableRow>
                                    <ResultPageTablePagination
                                        recordLength={voyageLegs.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        handleChangePage={handleChangePage}
                                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                                    />
                                    </TableRow>
                                </TableFooter> */}
            </Table>
          </TableContainer>
        </Box>
      </Card>
      {/* </Box> */}
    </Card>
  )
}

const ViewSchduleInfoTableComponent = Object.freeze({
  FormBody: FormBody,
  initialValues: initialValues
})

export default ViewSchduleInfoTableComponent
