import React, { useContext } from 'react'

import Box from '@material-ui/core/Box'
import CancelButton from 'src/components/button/CancelButton.js'
import ManageCarriersTranslationText from 'src/views/vesselschedule/managepreferredcarrier/ManageCarriersTranslationText'
import ManagePreferredPortPairsDialogContext from './ManagePreferredPortPairsDialogContext.js'
import StepperContext from 'src/views/vesselschedule/searchschedule/cngcomponent/StepperContext.js'
import SubmitButton from 'src/components/button/SubmitButton.js'
import { components } from 'cng-web-lib'
import moment from 'moment'
import { useFieldArray , useFormContext} from 'react-hook-form'

const {
  form : {
    adapter: {
      useFormAdapter:{ useField }
    }
  },
  button: { CngPrimaryButton }
} = components

function SelectPreferredCarrierStepContainer({ children }) {
  return (
    <Box mt={1}>
      <Box>{children}</Box>
      <StepButtonContainer />
    </Box>
  )
}

function StepButtonContainer() {
  const translatedTextsObject = ManageCarriersTranslationText()
  const stepperContext = useContext(StepperContext)
  const dialogContext = useContext(ManagePreferredPortPairsDialogContext)
  const { handleNext, isLastStep } = stepperContext
  const {
    closeDialog,
    form: { isSubmitting },
    wizard: { isStepOnTransitioning }
  } = dialogContext

  const [polCodeField, ,] = useField('polCode')
  const [podCodeField, , ] = useField('podCode')
  const [portPairTableField, , ] = useField('portPairTableArray')
  // const [prefPcarrier, , ] = useField('prefPcarrier')
  const { getValues, watch } = useFormContext()
  const checkBoxValue = getValues("prefPcarrier")
 // const checkBoxWatch = watch("prefPcarrier")
  
  // const [newPreferredCarrierList, , ] = useField('newPreferredCarrier')
  
  //const { fields: prefPcarrier } = useFieldArray({name: "prefPcarrier"})
  const { fields: newPreferredCarrierList } = useFieldArray({name: "newPreferredCarrier"})

  function shouldDisableContinueButton() {
    return !(polCodeField.value && podCodeField.value)
      ? true
      : portPairTableField.value.includes(polCodeField.value + "_" + podCodeField.value)
      ? true
      : podCodeField.value == polCodeField.value
      ? true
      : false
  }

  function shouldDisableAddButton() {
    console.log("checkBoxValue.length", checkBoxValue.length)
    return (checkBoxValue.length == 0)
      ? true
      : checkNewCarrierFields()
      ? true
      : false
  }

  function checkNewCarrierFields() {
    console.log('prefPcarrier', checkBoxValue)
    let newCarrierList = checkBoxValue.filter((carrier) => /^new_\d+$/.test(carrier))
    if (newCarrierList.length > 0) {
      for (const newCarrier of newCarrierList) {
        let index = newCarrier.split('_')[1]
        let carrierData = newPreferredCarrierList[index]
        if(!carrierData.carrierPartyId){
          return true
        }
        if(carrierData.contracted){
          if(!(
            moment(carrierData.contractStartDate, 'YYYY-MM-DD').isValid() &&
            moment(carrierData.contractEndDate, 'YYYY-MM-DD').isValid() &&
            ( moment(carrierData.contractStartDate, 'YYYY-MM-DD').isSame(
              moment(carrierData.contractEndDate, 'YYYY-MM-DD')) || 
              moment(carrierData.contractStartDate, 'YYYY-MM-DD').isBefore(
              moment(carrierData.contractEndDate, 'YYYY-MM-DD'))))
          ){
            return true
          }
        }
      }
    }
    return false
  }

  return isStepOnTransitioning ? null : (
    <Box display='flex' flexDirection={'row-reverse'} mt={1}>
      {isLastStep() ? (
        <SubmitButton 
          disabled={shouldDisableAddButton()} 
          isSubmitting={isSubmitting}
        >
          {translatedTextsObject.add}
        </SubmitButton>
      ) : (
        <CngPrimaryButton
          onClick={handleNext}
          disabled={shouldDisableContinueButton()}
        >
          {translatedTextsObject.continued}
        </CngPrimaryButton>
      )}
      <Box mr={1}>
        <CancelButton onClick={closeDialog} disabled={isSubmitting} />
      </Box>
    </Box>
  )
}

export default SelectPreferredCarrierStepContainer


