import ManageCarriersKeys from 'src/constants/locale/key/TcalVsManageCarriers'
import Namespace from 'src/constants/locale/Namespace'
import { useTranslation } from 'cng-web-lib'

const ManageCarriersTranslationText = () => {
  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.TCAL_VS_MANAGE_CARRIERS
  ])

  let preferredCarriersTitle = translate(
    Namespace.TCAL_VS_MANAGE_CARRIERS,
    ManageCarriersKeys.PREFERRED_CARRIERS_TITLE
  )
  let blacklistedCarriersTitle = translate(
    Namespace.TCAL_VS_MANAGE_CARRIERS,
    ManageCarriersKeys.BLACKLISTED_CARRIERS_TITLE
  )
  let preferredPortPairsTitle = translate(
    Namespace.TCAL_VS_MANAGE_CARRIERS,
    ManageCarriersKeys.PREFERRED_PORT_PAIRS_TITLE
  )
  let carriersNovccParty = translate(
    Namespace.TCAL_VS_MANAGE_CARRIERS,
    ManageCarriersKeys.CARRIERS_NOVCC_PARTY
  )
  let startingDate = translate(
    Namespace.TCAL_VS_MANAGE_CARRIERS,
    ManageCarriersKeys.STARTING_DATE
  )
  let endingDate = translate(
    Namespace.TCAL_VS_MANAGE_CARRIERS,
    ManageCarriersKeys.ENDING_DATE
  )
  let addCarrier = translate(
    Namespace.TCAL_VS_MANAGE_CARRIERS,
    ManageCarriersKeys.ADD_CARRIER
  )
  let contracted = translate(
    Namespace.TCAL_VS_MANAGE_CARRIERS,
    ManageCarriersKeys.CONTRACTED
  )
  let remove = translate(
    Namespace.TCAL_VS_MANAGE_CARRIERS,
    ManageCarriersKeys.REMOVE
  )
  let setPreferredTitle = translate(
    Namespace.TCAL_VS_MANAGE_CARRIERS,
    ManageCarriersKeys.SET_PREFERRED_TITLE
  )
  let add = translate(Namespace.TCAL_VS_MANAGE_CARRIERS, ManageCarriersKeys.ADD)
  let download = translate(Namespace.TCAL_VS_MANAGE_CARRIERS, ManageCarriersKeys.DOWNLOAD)
  let apply = translate(Namespace.TCAL_VS_MANAGE_CARRIERS, ManageCarriersKeys.APPLY)
  let exportTable = translate(Namespace.TCAL_VS_MANAGE_CARRIERS, ManageCarriersKeys.EXPORT_TABLE)
  let carrierIsRequired = translate(
    Namespace.TCAL_VS_MANAGE_CARRIERS,
    ManageCarriersKeys.CARRIER_IS_REQUIRED
  )
  let setBlacklistedTitle = translate(
    Namespace.TCAL_VS_MANAGE_CARRIERS,
    ManageCarriersKeys.SET_BLACKLISTED_TITLE
  )
  let addPreferredCarrierSuccess = translate(
    Namespace.TCAL_VS_MANAGE_CARRIERS,
    ManageCarriersKeys.ADD_PREFERRED_CARRIER_SUCCESS
  )
  let addBlacklistedSuccess = translate(
    Namespace.TCAL_VS_MANAGE_CARRIERS,
    ManageCarriersKeys.ADD_BLACKLISTED_SUCCESS
  )
  let editPreferredCarrierSuccess = translate(
    Namespace.TCAL_VS_MANAGE_CARRIERS,
    ManageCarriersKeys.EDIT_PREFERRED_CARRIER_SUCCESS
  )
  let portOfLoading = translate(
    Namespace.TCAL_VS_MANAGE_CARRIERS,
    ManageCarriersKeys.PORT_OF_LOADING
  )
  let portOfDischarge = translate(
    Namespace.TCAL_VS_MANAGE_CARRIERS,
    ManageCarriersKeys.PORT_OF_DISCHARGE
  )
  let addPreferredPortPairSuccess = translate(
    Namespace.TCAL_VS_MANAGE_CARRIERS,
    ManageCarriersKeys.ADD_PREFERRED_PORT_PAIR_SUCCESS
  )
  let editPreferredPortPairSuccess = translate(
    Namespace.TCAL_VS_MANAGE_CARRIERS,
    ManageCarriersKeys.EDIT_PREFERRED_PORT_PAIR_SUCCESS
  )
  let deletePreferredPortPairSuccess = translate(
    Namespace.TCAL_VS_MANAGE_CARRIERS,
    ManageCarriersKeys.DELETE_PREFERRED_PORT_PAIR_SUCCESS
  )
  let exportPreferredCarrier = translate(
    Namespace.TCAL_VS_MANAGE_CARRIERS,
    ManageCarriersKeys.EXPORT_PREFERRED_CARRIER
  )
  let addPreferredCarrier = translate(
    Namespace.TCAL_VS_MANAGE_CARRIERS,
    ManageCarriersKeys.ADD_PREFERRED_CARRIER
  )
  let editPreferredCarrier = translate(
    Namespace.TCAL_VS_MANAGE_CARRIERS,
    ManageCarriersKeys.EDIT_PREFERRED_CARRIER
  )
  let addPreferredPortPair = translate(
    Namespace.TCAL_VS_MANAGE_CARRIERS,
    ManageCarriersKeys.ADD_PREFERRED_PORT_PAIR
  )
  let editPreferredPortPair = translate(
    Namespace.TCAL_VS_MANAGE_CARRIERS,
    ManageCarriersKeys.EDIT_PREFERRED_PORT_PAIR
  )
  let addBlacklistedCarrier = translate(
    Namespace.TCAL_VS_MANAGE_CARRIERS,
    ManageCarriersKeys.ADD_BLACKLISTED_CARRIER
  )
  let selectCarrier = translate(
    Namespace.TCAL_VS_MANAGE_CARRIERS,
    ManageCarriersKeys.SELECT_CARRIER
  )
  let definePortPairs = translate(
    Namespace.TCAL_VS_MANAGE_CARRIERS,
    ManageCarriersKeys.DEFINE_PORT_PAIRS
  )
  let setPrefCarrierOrAdd = translate(
    Namespace.TCAL_VS_MANAGE_CARRIERS,
    ManageCarriersKeys.SET_PREF_CARRIER_OR_ADD
  )
  let editPrefCarrierOrAdd = translate(
    Namespace.TCAL_VS_MANAGE_CARRIERS,
    ManageCarriersKeys.EDIT_PREF_CARRIER_OR_ADD
  )
  let setPortPairTitle = translate(
    Namespace.TCAL_VS_MANAGE_CARRIERS,
    ManageCarriersKeys.SET_PORT_PAIR_TITLE
  )
  let origin = translate(
    Namespace.TCAL_VS_MANAGE_CARRIERS,
    ManageCarriersKeys.ORIGIN
  )
  let destination = translate(
    Namespace.TCAL_VS_MANAGE_CARRIERS,
    ManageCarriersKeys.DESTINATION
  )
  let continued = translate(
    Namespace.TCAL_VS_MANAGE_CARRIERS,
    ManageCarriersKeys.CONTINUE
  )
  let addNewCarrier = translate(
    Namespace.TCAL_VS_MANAGE_CARRIERS,
    ManageCarriersKeys.ADD_NEW_CARRIER
  )
  let addNew = translate(
    Namespace.TCAL_VS_MANAGE_CARRIERS,
    ManageCarriersKeys.ADD_NEW
  )
  let portOfLoadingIsReq = translate(
    Namespace.TCAL_VS_MANAGE_CARRIERS,
    ManageCarriersKeys.PORT_OF_LOADING_IS_REQ
  )
  let portOfDischargeIsReq = translate(
    Namespace.TCAL_VS_MANAGE_CARRIERS,
    ManageCarriersKeys.PORT_OF_DISCHARGE_IS_REQ
  )
  let fileFormat = translate(
    Namespace.TCAL_VS_MANAGE_CARRIERS,
    ManageCarriersKeys.FILE_FORMAT
  )
  let portPairExist = translate(
    Namespace.TCAL_VS_MANAGE_CARRIERS,
    ManageCarriersKeys.PORT_PAIR_EXIST
  )
  

  return {
    preferredCarriersTitle,
    blacklistedCarriersTitle,
    preferredPortPairsTitle,
    carriersNovccParty,
    startingDate,
    endingDate,
    addCarrier,
    exportTable,
    contracted,
    remove,
    setPreferredTitle,
    add,
    download,
    apply,
    carrierIsRequired,
    setBlacklistedTitle,
    addPreferredCarrierSuccess,
    editPreferredCarrierSuccess,
    addBlacklistedSuccess,
    portOfLoading,
    portOfDischarge,
    addPreferredPortPairSuccess,
    editPreferredPortPairSuccess,
    deletePreferredPortPairSuccess,
    addPreferredCarrier,
    exportPreferredCarrier,
    editPreferredCarrier,
    addPreferredPortPair,
    editPreferredPortPair,
    addBlacklistedCarrier,
    selectCarrier,
    definePortPairs,
    setPrefCarrierOrAdd,
    editPrefCarrierOrAdd,
    setPortPairTitle,
    origin,
    destination,
    continued,
    addNewCarrier,
    addNew,
    portOfLoadingIsReq,
    portOfDischargeIsReq,
    fileFormat,
    portPairExist
  }
}

export default ManageCarriersTranslationText
