import AddVesselPage from './addvessels/AddVesselPage.js'
import { BasePage as CngBasePage } from 'cng-web-lib'
import ManageVesselsTable from './ManageVesselsTable.js'
import React from 'react'
import TranslationText from 'src/views/vesselschedule/searchschedule/SearchScheduleTranslationText'
import ViewVesselForm from './viewamendvessel/ViewVesselForm.js'
import useBreadcrumbNameMap from 'src/navigations/breadcrumbs/Breadcrumb_VesselSchedule'

function WrappedManageVesselsTablePage(props) {
  const translatedTextsObject = TranslationText()
  const breadcrumbNameMap = useBreadcrumbNameMap()

  return (
    <CngBasePage
      moduleTitle={translatedTextsObject.vesselSchedule}
      renderPage={(showSnackbar) => (
        <ManageVesselsTable showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

function WrappedAddVesselPage(props) {
  const translatedTextsObject = TranslationText()
  const breadcrumbNameMap = useBreadcrumbNameMap()
  return (
    <CngBasePage
      moduleTitle={translatedTextsObject.vesselSchedule}
      renderPage={(showSnackbar) => (
        <AddVesselPage showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

function WrappedViewAmendVesselPage(props) {
  const translatedTextsObject = TranslationText()
  const breadcrumbNameMap = useBreadcrumbNameMap()
  return (
    <CngBasePage
      moduleTitle={translatedTextsObject.vesselSchedule}
      renderPage={(showSnackbar) => (
        <ViewVesselForm showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

export {
  WrappedManageVesselsTablePage as ManageVesselsTablePage,
  WrappedAddVesselPage as AddVesselPage,
  WrappedViewAmendVesselPage as ViewVesselForm
}
