import { Avatar, Box, Typography } from '@material-ui/core'
import { components, constants } from 'cng-web-lib'

import ManageCarriersTranslationText from 'src/views/vesselschedule/managepreferredcarrier/ManageCarriersTranslationText'
import React, { useRef, useEffect, useState }  from 'react'
import PortSelectAutoComplete from 'src/views/vesselschedule/searchschedule/PortSelectAutoComplete'
import { v4 as uuid } from 'uuid'

const { CodeMaintenanceType } = constants
const {
  form: {
    adapter: {
      useFormAdapter:{ useField, useFormikContext }
    },
    field: { CngServerSideAutocompleteField }
  }
} = components

function DefinePortPairsStep() {
  const translatedTextsObject = ManageCarriersTranslationText()
  const [polCodeField, ,] = useField('polCode')
  const [podCodeField, ,] = useField('podCode')
  const [portPairTableField, , ] = useField('portPairTableArray')

  const [portPairExist, setPortPairExist] = useState(false)

  const polKeyRef = useRef(uuid())
  const podKeyRef = useRef(uuid())

  useEffect(() => {
    let polCode = polCodeField.value
    let podCode = podCodeField.value
    
    if(polCode && podCode && portPairTableField.value.includes(polCode + "_" + podCode)){
      setPortPairExist(true)
    }else{
      setPortPairExist(false)
    }

  }, [polCodeField.value, podCodeField.value])

  return (
    <div>
      <Box display='flex' flexWrap='wrap' my={1}>
        <Box>
          <Typography variant='body2' gutterBottom>
            {translatedTextsObject.setPortPairTitle}{' '}
          </Typography>
        </Box>
      </Box>

      <Box display='flex' flexWrap='wrap' justifyContent='space-between'>
        <Box width={0.48} my={1}>
        <PortSelectAutoComplete
          name='polCode'
          label={translatedTextsObject.origin}
          keyRef={polKeyRef}
          value={polCodeField.value}
          hideDesc
        />
        </Box>
        <Box width={0.48} my={1}>
        <PortSelectAutoComplete
          name='podCode'
          label={translatedTextsObject.destination}
          keyRef={podKeyRef}
          value={podCodeField.value}
          hideDesc
        />
        </Box>
      </Box>
      <Box style={{display: portPairExist ? "flex" : "none" }} my={1} flexWrap='wrap'>
        <Typography variant='body2' color='primary' gutterBottom>
          {translatedTextsObject.portPairExist}
        </Typography>
      </Box>
    </div>
  )
}

export default DefinePortPairsStep
