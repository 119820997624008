import React, { Fragment, useState } from 'react'

import CloseDialogIconButton from 'src/components/iconbutton/CloseDialogIconButton.js'
import CancelButton from 'src/components/button/CancelButton.js'
import ManageCarriersTranslationText from '../managepreferredcarrier/ManageCarriersTranslationText'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Paper, Box, TextField, Icon } from '@material-ui/core'
import ExportPdf from './ExportPdf'
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined'
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined'
import { components } from 'cng-web-lib'
import moment from 'moment'
import Autocomplete from '@material-ui/lab/Autocomplete'
import ExcelJS from 'exceljs/dist/es5/exceljs.browser'
import { saveAs } from 'file-saver'

const {
  CngDialog,
  button: { CngPrimaryButton },
  table: { useDefaultNotification }
} = components

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  textStyle: {
    border: '1px solid #000000',
    overflow: 'hidden',
    borderRadius: 4,
    backgroundColor: '#ffffff'
  }
}))

function ExportPreferredDialog({
  isDialogOpen,
  closeDialog,
  tableData,
  portName,
  columns,
  fileName,
  showNotification,
  cngTableRef
}) {
  const { success: showSuccessNotification, error: showErrorNotification } =
    useDefaultNotification(showNotification)

  const translatedTextsObject = ManageCarriersTranslationText()
  const classes = useStyles()
  const [fileType, setFileType] = useState('')

  const exportTable = () => {
    let exportData

    if (fileName === 'Preferred Carriers') {
      exportData = []
      tableData.forEach((carrier) => {
        exportData.push({
          name: carrier.name,
          contracted: carrier.contracted ? 'Yes' : 'No',
          contractStartDate: carrier.contractStartDate
            ? moment(carrier.contractStartDate).format('DD MMM YYYY')
            : '',
          contractEndDate: carrier.contractEndDate
            ? moment(carrier.contractEndDate).format('DD MMM YYYY')
            : ''
        })
      })
    }
    if (fileName === 'Preferred Carrier Ports') {
      exportData = []
      tableData.forEach((row) => {
        row.carrierList.map((record) => {
          let pol_descriptionEn = portName
            .filter((pol) => pol.code == record.polCode)[0]
            .descriptionEn.toLowerCase()
            .replace(/(^\w|[\s(\.-\\\/]\w)/g, (m) => m.toUpperCase())
          let pod_descriptionEn = portName
            .filter((pod) => pod.code == record.podCode)[0]
            .descriptionEn.toLowerCase()
            .replace(/(^\w|[\s(\.-\\\/]\w)/g, (m) => m.toUpperCase())
          exportData.push({
            polCode: `${pol_descriptionEn} (${record.polCode})`,
            podCode: `${pod_descriptionEn} (${record.podCode})`,
            name: record.name,
            contracted: record.contracted ? 'Yes' : 'No',
            contractStartDate: record.contractStartDate
              ? moment(record.contractStartDate).format('DD MMM YYYY')
              : '',
            contractEndDate: record.contractEndDate
              ? moment(record.contractEndDate).format('DD MMM YYYY')
              : ''
          })
        })
      })
    }

    try {
      fileType === 'excel'
        ? ExportExcel(columns, exportData, fileName)
        : ExportPdf(columns, exportData, fileName, null)
      showSuccessNotification('Successfully export data to ' + fileType)
    } catch (err) {
      console.log(JSON.stringify(err))
      showErrorNotification(err.message)
    }
    setFileType('')
    closeDialog()
  }

  function ExportExcel(columns, exportData, fileName) {
    const excelWorkbook = new ExcelJS.Workbook()
    const worksheet = excelWorkbook.addWorksheet(fileName)

    worksheet.columns = columns.map((col) => ({
      header: col.title,
      key: col.field
    }))
    exportData.forEach((row) => {
      worksheet.addRow(row)
    })

    worksheet.eachRow({ includeEmpty: false }, function (row, rowNumber) {
      row.eachCell(function (cell, colNumber) {
        if (rowNumber <= 1) {
          row.height = 20
          cell.font = { bold: true }
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'E6E6FA' }
          }
        }

        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        }
      })
    })

    excelWorkbook.xlsx.writeBuffer().then(function (buffer) {
      saveAs(
        new Blob([buffer], { type: 'application/octet-stream' }),
        `${fileName}.xlsx`
      )
    })
  }

  const availableFileTypes = [
    { label: 'XLS - Microsoft Excel', value: 'excel' },
    { label: 'PDF - Adobe Acrobat', value: 'pdf' }
  ]

  const renderFileTypeIcon = (val) => {
    if (val === 'excel') {
      return <InsertDriveFileOutlinedIcon />
    } else if (val === 'pdf') {
      return <DescriptionOutlinedIcon />
    }
  }

  return (
    <CngDialog
      customDialogContent={
        <Paper>
          <Box m={1.5}>
            <Box mb={2}>
              <Typography variant='body2' gutterBottom>
                {translatedTextsObject.fileFormat}
              </Typography>
            </Box>
            <Box my={1}>
              <Autocomplete
                id='fileTypes-autocomplete'
                style={{ width: 300 }}
                options={availableFileTypes}
                classes={{
                  option: classes.option
                }}
                autoHighlight
                getOptionLabel={(option) => option.label}
                getOptionSelected={(option, val) => option.value === val.value}
                onChange={(evt, val) => setFileType(val ? val.value : '')}
                renderOption={(option) => {
                  return (
                    <Fragment>
                      <Icon className={classes.icon}>
                        {renderFileTypeIcon(option.value)}
                      </Icon>
                      {option.label}
                    </Fragment>
                  )
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className={classes.textStyle}
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true
                    }}
                    label='File type'
                    variant='filled'
                    fullWidth
                    style={{ minWidth: 420 }}
                  />
                )}
              />
            </Box>
            <Box display='flex' flexDirection={'row-reverse'} mt={2}>
              <CngPrimaryButton
                onClick={exportTable}
                disabled={fileType != '' ? false : true}
              >
                {translatedTextsObject.download}
              </CngPrimaryButton>
              <Box mr={1}>
                <CancelButton onClick={closeDialog} />
              </Box>
            </Box>
          </Box>
        </Paper>
      }
      dialogTitle={
        <>
          <b>{translatedTextsObject.exportPreferredCarrier}</b>
          <CloseDialogIconButton onClick={closeDialog} />
        </>
      }
      open={isDialogOpen}
      fullWidth
      maxWidth='xs'
    />
  )
}

export default ExportPreferredDialog
