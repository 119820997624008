import { Box, Grid, Paper, Typography } from '@material-ui/core'
import { DropzoneArea, DropzoneAreaBase } from 'material-ui-dropzone'

import DocumentDetails from './DocumentDetails'
import DocumentDetailsComponents from './DocumentDetailsComponents'
import ManageScheduleTranslationText from '../ManageScheduleTranslationText'
import React from 'react'
import { components } from 'cng-web-lib'

const { CngGridItem, CngFilesDropzone } = components

const FormBody = (props) => {
  const msTranslatedTextsObject = ManageScheduleTranslationText()

  return (
    <Box>
      <Box mb={2}>
        {props.uploadedDocs.length > 0 ? (
          <DocumentDetailsComponents
            tile={props.uploadedDocs}
            hideEdit={true}
            hideDelete={false}
            removeRowClick={props.removeUploadedRowClick}
            downloadRowClick={props.downloadUploadedRowClick}
            downloadLogClick={props.downloadLogClick}
          />
        ) : (
          <Paper style={{ backgroundColor: '#fafbfc' }}>
            <Box className={'vs-nofile'}>
              <Box mt={3}>
                <img
                  src={
                    process.env.PUBLIC_URL +
                    '/static/images/vesselSchedule/FolderEmpty.svg'
                  }
                  alt='No Result'
                ></img>
              </Box>
              <Box mt={1}>
                <Typography variant='h4' style={{ fontWeight: 'bold' }}>
                  {msTranslatedTextsObject.noFilesYet}
                </Typography>
              </Box>
              <Box mb={3}>
                <Typography
                  variant='caption'
                  style={{ padding: '10px', opacity: '0.5' }}
                >
                  {msTranslatedTextsObject.uploadDesc}
                </Typography>
              </Box>
            </Box>
          </Paper>
        )}
      </Box>
      <Box>
        <Grid container xs={12} sm={12}>
          <CngGridItem xs={12} sm={12}>
            <Box>
              <DropzoneAreaBase
                acceptedFiles={['.csv']}
                maxFileSize={10485760}
                filesLimit={5}
                onAdd={(newFileObjs) => {
                  console.log(newFileObjs)

                  newFileObjs = newFileObjs.map((item) => ({
                    ...item,
                    docType: '',
                    description: ''
                  }))

                  props.setItem(newFileObjs)
                }}
              />
            </Box>
          </CngGridItem>
          <CngGridItem xs={12} sm={12}>
            <Box mt={1}>
              {' '}
              <Typography variant='caption' style={{ opacity: '0.5' }}>
                {msTranslatedTextsObject.uploadDetails}
                <br />
                {msTranslatedTextsObject.uploadFileLimitDetails}
              </Typography>
            </Box>
          </CngGridItem>
        </Grid>
      </Box>
      <Box mt={2}>
        <DocumentDetails
          tile={props.pendingDocs}
          removeRowClick={props.removeRowClick}
          upload={props.upload}
        />
      </Box>
    </Box>
  )
}

const DocumentComponent = Object.freeze({
  FormBody: FormBody
})

export default DocumentComponent
