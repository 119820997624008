import { Box, Card, Paper } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import { components, constants, useServices } from 'cng-web-lib'
import { useHistory, useLocation } from 'react-router-dom'

import AddScheduleDetailsButtonComponent from './AddScheduleDetailsButtonComponent'
import Alert from '@material-ui/lab/Alert'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import CngBackdrop from '../../searchschedule/cngcomponent/CngBackDrop'
import ConfirmDialog from 'src/components/dialog/ConfirmDialog'
import GeneralStepperComponent from 'src/views/common/ui/GeneralStepperComponent'
import ManageScheduleTranslationText from '../ManageScheduleTranslationText'
import RemarksComponent from './RemarksComponent'
import ScheduleInfoComponent from './ScheduleInfoComponent'
import Utils from 'src/views/common/utils/Utils'
import VesselVoyageComponent from './VesselVoyageComponent'
import WarningDialog from 'src/components/dialog/WarningDialog'
import pathMap from 'src/paths/PathMap_VesselSchedule'
import { v4 as uuid } from 'uuid'
import { useFieldArray } from 'react-hook-form'

// import Api from '../shared/api'

const {
  form : {
    adapter: {
      useFormAdapter:{ useField, useFormikContext }
    }
  },
  table: { useDefaultNotification },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  ...ScheduleInfoComponent.initialValues
  // saveDraft: true,
  // bookingId: '',
  // setFreightBookingId: '',
  // bookingStatus: '',
  // ...BookingTypeComponent.initialValues,
  // ...ScheduleComponent.initialValues,
  // ...ShipmentComponent.initialValues,
  // ...ContainerComponent.initialValues,
  // ...ContactDetailsComponent.initialValues,
  // ...PaymentInstructionComponent.initialValues,
  // ...CargoComponent.initialValues
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES }
  // makeValidationSchema: DEFAULT_VALIDATION_SCHEMA
  //makeValidationSchema: makeValidationSchema}
}

const { CodeMaintenanceType } = constants

function FormFields({ disabled, showNotification, loading }) {
  const history = useHistory()
  const location = useLocation()
  const voyageData = location.state.voyageData
  const [searchCriteria, ,] = useField('searchCriteria')

  const msTranslatedTextsObject = ManageScheduleTranslationText()
  const uiTranslatedTextsObject = CalistaUiComponentTranslationText()

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [warningDialogOpen, setWarningDialogOpen] = useState(false)

  const { errors, submitForm, setFieldValue } = useFormikContext()
  const { fields: stateVoyageLegs } = useFieldArray({name: "stateVoyageLegs"})
  // const [stateVoyageLegs, , { setValue: setStateVoyageLegs }] = useField(
  //   'stateVoyageLegs'
  // )
  const [voyageId, setVoyageId] = useState(0)

  const setStateVoyageLegs =(list) =>{
    setFieldValue('stateVoyageLegs', list)
  }

  const addScheduleStep = [
    msTranslatedTextsObject.addScheduleStep1,
    msTranslatedTextsObject.addScheduleStep2,
    msTranslatedTextsObject.addScheduleStep3
  ]

  const amendScheduleStep = [
    msTranslatedTextsObject.amendScheduleStep1,
    msTranslatedTextsObject.amendScheduleStep2,
    msTranslatedTextsObject.amendScheduleStep3
  ]

  function isEmpty(obj) {
    return Object.keys(obj).length === 0
  }

  const onSubmitSchedule = async () => {
    if (!isEmpty(errors)) {
      setWarningDialogOpen(
        errors.voyageLegs.length === stateVoyageLegs.length
      )
      //alert('There is error in your form. Please correct the error before submit the form')
    }
    submitForm()
  }

  const onDiscard = () => {
    setConfirmDialogOpen(true)
  }

  const cancelSchedule = () => {
    if (searchCriteria.value != null) {
      //amend from carrier search schedule, return to search schedule with searchCriteria
      history.push({
        pathname: pathMap.CARRIER_SEARCH_SCHEDULE_RESULT,
        state: searchCriteria.value
      })
    } else if (voyageId > 0) {
      //amend from view schedule, return to view schedule
      history.push({
        pathname: pathMap.TCAL_VS_MANAGE_SCHEDULE_VIEW,
        state: {
          voyageId: voyageData.id,
          hideAmend: false
        }
      })
    } else {
      //add schedule, return to manage schedule table list
      history.push(pathMap.TCAL_VS_MANAGE_SCHEDULE_LIST)
    }
  }

  const onBack = () => {
   // console.log('onBack stateVoyageLegs > ' + JSON.stringify(stateVoyageLegs))
    history.push({
      pathname: pathMap.TCAL_VS_MANAGE_SCHEDULE_ADD,
      // state: {voyageData: voyageData}
      state: {
        voyageData: { ...voyageData, voyageLegs: stateVoyageLegs }
      }
    })
  }

  useEffect(() => {
    setFieldValue(
      'searchCriteria',
      location.state
        ? location.state.hasOwnProperty('searchCriteria')
          ? location.state.searchCriteria
          : null
        : null
    )

    setVoyageId(
      voyageData
        ? voyageData.hasOwnProperty('id')
          ? voyageData.id
          : null
        : null
    )
  }, [])

  return (
    <Box>
      {/* {'Error :' + JSON.stringify(errors)} */}
      {/* <br/> */}
      {/* {'Touched :' + JSON.stringify(touched)} */}

      <CngGridItem xs={12} sm={9} shouldHide={loading ? false : true}>
        <CngBackdrop loading={loading} />
      </CngGridItem>

      <Paper>
        <Box alignItems='center' pr={15} pl={15}>
          <GeneralStepperComponent
            steps={voyageId > 0 ? amendScheduleStep : addScheduleStep}
            activeStep={1}
          />
        </Box>

        <Box p={5} className='page-content'>
          <Box>
            <ScheduleInfoComponent.FormBody
              setStateVoyageLegs={setStateVoyageLegs}
            />
          </Box>
        </Box>
      </Paper>
      <Paper className='sticky-footer'>
        <Box alignItems='center'>
          <AddScheduleDetailsButtonComponent
            onSubmitSchedule={onSubmitSchedule}
            onDiscard={onDiscard}
            onBack={onBack}
            isSave={voyageData.isSave}
          />
        </Box>
      </Paper>
      <ConfirmDialog
        isConfirmDialogOpen={confirmDialogOpen}
        closeDialog={() => setConfirmDialogOpen(false)}
        confirmDialog={cancelSchedule}
        content={msTranslatedTextsObject.dialogDiscardScheduleContent}
        okMsg={uiTranslatedTextsObject.ok}
        cancelMsg={uiTranslatedTextsObject.cancel}
        title={msTranslatedTextsObject.dialogDiscardScheduleTitle}
      />

      <WarningDialog
        isDialogOpen={warningDialogOpen}
        closeDialog={() => setWarningDialogOpen(false)}
        okDialog={() => setWarningDialogOpen(false)}
        content={uiTranslatedTextsObject.formErrorWarning}
        okMsg={uiTranslatedTextsObject.ok}
      />
    </Box>
  )
}

const AddScheduleDetailsFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  FormFields: FormFields
  // toClientDataFormat: toClientDataFormat,
  // toServerDataFormat: toServerDataFormat
})

export default AddScheduleDetailsFormProperties
