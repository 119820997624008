import React, { useContext } from 'react'

import CngWizard from 'src/views/vesselschedule/searchschedule/cngcomponent/CngWizard.js'
import DefinePortPairsStep from './DefinePortPairsStep.js'
import ManageCarriersTranslationText from 'src/views/vesselschedule/managepreferredcarrier/ManageCarriersTranslationText'
import ManagePreferredPortPairsDialogContext from './ManagePreferredPortPairsDialogContext.js'
import SelectPreferredCarrierStep from './SelectPreferredCarrierStep.js'
import StepContainer from './SelectPreferredCarrierStepContainer.js'

function ManagePreferredPortPairsWizard() {
  const dialogContext = useContext(ManagePreferredPortPairsDialogContext)
  const {
    wizard: { setStepOnTransitioning }
  } = dialogContext
  const translatedTextsObject = ManageCarriersTranslationText()

  const steps = [
    {
      label: translatedTextsObject.definePortPairs,
      content: (
        <StepContainer>
          <DefinePortPairsStep />
        </StepContainer>
      )
    },
    {
      label: translatedTextsObject.selectCarrier,
      content: (
        <StepContainer>
          <SelectPreferredCarrierStep />
        </StepContainer>
      )
    }
  ]

  return (
    <CngWizard
      steps={steps}
      stepperProps={{
        orientation: 'vertical'
      }}
      stepContentProps={{
        TransitionProps: {
          onEnter: () => setStepOnTransitioning(true),
          onEntered: () => setStepOnTransitioning(false)
        }
      }}
      hideCompleted
    />
  )
}

export default ManagePreferredPortPairsWizard
