import {
  components,
  useTranslation
} from 'cng-web-lib'

import PollingSourceFormProperties from './PollingSourceFormProperties'
import PollingSourceApiUrls from 'src/apiUrls/PollingSourceApiUrls'
import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import PollingKeys from 'src/constants/locale/key/Polling'
import {
  DateTimeFormatter,
} from 'cng-web-lib'


const {
  table: {
    DateRangeFilter: CngDateRangeFilter,
    CngServerModeDialogFormTable,
  }
} = components


function PollingSourceCrudTable({
  showNotification,
  pollingId,
  isViewOnly = false
}) {
  const { translate } = useTranslation([Namespace.POLLING])
  const translatedTextsObject = makeTranslatedTextsObject()

  let create = { url: PollingSourceApiUrls.POST }
  let update = { url: PollingSourceApiUrls.PUT }
  let del = { url: PollingSourceApiUrls.DELETE }
  if (isViewOnly) {
    create = undefined
    update = undefined
    del = undefined
  }

  function makeTranslatedTextsObject() {
    let source = translate(
      Namespace.POLLING,
      PollingKeys.PollingSource.SOURCE
    )
    let status = translate(
      Namespace.POLLING,
      PollingKeys.PollingSource.STATUS
    )
    let runDate = translate(
      Namespace.POLLING,
      PollingKeys.PollingSource.RUN_DATE
    )
    let completedDate = translate(
      Namespace.POLLING,
      PollingKeys.PollingSource.COMPLETED_DATE
    )
    let errorMsg = translate(
      Namespace.POLLING,
      PollingKeys.PollingSource.ERROR_MSG
    )

    return {
      source,
      status,
      runDate,
      completedDate,
      errorMsg
    }
  }

  const columns = [
    {
      field: 'source',
      title: translatedTextsObject.source,
    },
    {
      field: 'status',
      title: translatedTextsObject.status,
    },
    {
      field: 'runDate',
      title: translatedTextsObject.runDate,
      type: 'datetime',
      filterComponent: CngDateRangeFilter,
    },
    {
      field: 'completedDate',
      title: translatedTextsObject.completedDate,
      type: 'datetime',
      filterComponent: CngDateRangeFilter,
    },
    {
      field: 'errorMsg',
      title: translatedTextsObject.errorMsg,
    }
  ]

  return (
    <CngServerModeDialogFormTable
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      disabled={isViewOnly}
      showNotification={showNotification}
      tableColumns={columns}
      formProperties={{
        ...PollingSourceFormProperties,
        formikProps: {
          ...PollingSourceFormProperties.formikProps,
          initialValues: {
            ...PollingSourceFormProperties.formikProps.initialValues,
            pollingId: pollingId
          }
        }
      }}
      toClientDataFormat={(serverData) => {
        serverData.forEach((datum) => {
          datum.runDate = DateTimeFormatter.toClientDateTime(
            datum.runDate
          );
          datum.completedDate = DateTimeFormatter.toClientDateTime(
            datum.completedDate
          );
        });
        return serverData;
      }}
      toServerDataFormat={(localDatum) => {
        localDatum.runDate = DateTimeFormatter.toServerDateTime(
          localDatum.runDate
        );
        localDatum.completedDate = DateTimeFormatter.toServerDateTime(
          localDatum.completedDate
        );
        return localDatum;
      }}
      fetch={{
        url: PollingSourceApiUrls.GET,
        body: {
          pollingId: pollingId
        }
      }}
      create={create}
      update={update}
      del={del}
      idAccessor='id'
      // tableConfigurationCode=''
    />
  )
}

export default PollingSourceCrudTable
