import Namespace from 'src/constants/locale/Namespace'
import { Yup } from 'cng-web-lib'
import { constants } from 'cng-web-lib'

const {
  locale: {
    key: { CommonValidationMessageKeys }
  }
} = constants

function makeValidationSchema(translate) {
  return Yup.object({
    platformName: Yup.string(),
    carrierPartyId: Yup.number(),
    otherPlatformCode: Yup.string(),
    excludes: Yup.string()
  })
}

export default makeValidationSchema
