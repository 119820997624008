import { DateTimeFormatter, components, useServices, Yup } from 'cng-web-lib'
import React, { useContext, useState, useEffect } from 'react'

import ManageCarriersTranslationText from 'src/views/vesselschedule/managepreferredcarrier/ManageCarriersTranslationText'
import ManageEditPreferredPortPairsDialogContext from './ManageEditPreferredPortPairsDialogContext.js'
import SelectPreferredCarrierStep from './SelectPreferredCarrierStep.js'
import TcalVsManagePreferredCarrierApiUrls from 'src/apiUrls/TcalVsManagePreferredCarrierApiUrls.js'
import TcalVsManagePreferredPortPairsApiUrls from 'src/apiUrls/TcalVsManagePreferredPortPairsApiUrls.js'
import { Box, Grid, Card, Chip, Typography } from '@material-ui/core'
import SubmitButton from 'src/components/button/SubmitButton.js'
import CancelButton from 'src/components/button/CancelButton.js'
import moment from 'moment'
import { useFieldArray, useFormContext } from 'react-hook-form'


const {
    form : {
        CngViewForm,
        adapter: {
            useFormAdapter:{ useField, useFormikContext }
        }
    },
  table: { useDefaultNotification }
} = components

function ManageEditPreferredPortPairsDialogForm(props) {
  const translatedTextsObject = ManageCarriersTranslationText()
  const { createRecord, deleteRecord } = useServices()
  const dialogContext = useContext(ManageEditPreferredPortPairsDialogContext)
  const {
    closeDialog,
    rowData,
    portName,
    showNotification,
    form: { isSubmitting, setSubmitting },
    cngTableRef
  } = dialogContext
  const {
    success: showSuccessNotification,
    error: showErrorNotification
  } = useDefaultNotification(showNotification)

  const [initialPrefPcarrier, setInitialPrefPcarrier] = useState([])

  useEffect(() => {
    let tempArr =[]
    rowData.carrierList.forEach(record =>{tempArr.push(record.prefCarrierId + '')})
    setInitialPrefPcarrier(tempArr)
    },[])

  function onSubmit(data) {

    let newPortPairRecords = []
    let newPrefCarrierRecords = []
    let deletePortPairRecords = []

    function onSuccess() {
      //console.log("sucess in editing records ")
      setSubmitting(false)
      closeDialog()
      if (cngTableRef.current && cngTableRef.current.performRefresh) {
        cngTableRef.current.performRefresh()
      }
      showSuccessNotification(translatedTextsObject.editPreferredPortPairSuccess)
    }

    function onError(error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.errorMessages &&
        error.response.data.errorMessages.length > 0
      ) {
        showErrorNotification(error.response.data.errorMessages)
      } else {
        showErrorNotification(error.message)
      }
      console.log('manage-preferred-port-pair error', error.message)
      setSubmitting(false)
    }

    function onSuccessThenPost(carrierDataList) {
      let portPairRecords = []
      carrierDataList.content.map(carrierData => {
        portPairRecords.push({
          preferredCarrier: { id: carrierData.id },
          polCode: data.polCode,
          podCode: data.podCode
        })
      })
      let mergedRecords = newPortPairRecords.concat(portPairRecords)
      createRecord.execute(
        TcalVsManagePreferredPortPairsApiUrls.MULTIPLE_POST,
        mergedRecords,
        onSuccessThenDelete,
        onError
      )
    }

    function onSuccessThenDelete(successData) {
        //console.log("sucess in adding record: " + JSON.stringify(successData))
        if(deletePortPairRecords.length == 0){
            onSuccess()
        }else{
            deleteRecord.execute(
                TcalVsManagePreferredPortPairsApiUrls.MULTIPLE_DELETE,
                deletePortPairRecords,
                onSuccess,
                onError
            )
        }
      }

    setSubmitting(true)

    data['prefPcarrier'].forEach(partyId => {
      let index = partyId.split('_')[1]
      if(index){
        let newPartyData = data['newPreferredCarrier'][index]
        let temp_record = {
          carrierPartyId: newPartyData.carrierPartyId,
          contracted: newPartyData.contracted,
          contractStartDate: newPartyData.contracted ? newPartyData.contractStartDate : null,
          contractEndDate: newPartyData.contracted ? newPartyData.contractEndDate : null,
          sentExpiredNtf: newPartyData.contracted && moment(DateTimeFormatter.toClientDate(new Date())).isAfter(moment(newPartyData.contractEndDate)) ? true : false
        }
        newPrefCarrierRecords.push(temp_record)
      }else{
        if(!initialPrefPcarrier.includes(partyId)){ 
            // add if dont exist in initial preferred carrierPorts
            let partyData = data['preferredCarrier'].find((carrier) => carrier.value === partyId)
            let temp_record = {
              prefCarrierId: partyData.value,
              preferredCarrier: { id: partyData.value },
              polCode: data.polCode,
              podCode: data.podCode
            }
            newPortPairRecords.push(temp_record)
        }
      }
    })

    initialPrefPcarrier.forEach(initialCarrier =>{
        if(!data['prefPcarrier'].includes(initialCarrier)){
            // delete if initial record dont exist in new list of carrierPorts
            let initialRecord = rowData.carrierList.find((carrier) => carrier.prefCarrierId == initialCarrier)
            let temp_record = {id: initialRecord.prefPortId, version: initialRecord.version }
            deletePortPairRecords.push(temp_record)
        }
    })

    if(newPrefCarrierRecords.length == 0 && newPortPairRecords.length == 0){
        onSuccessThenDelete('N/A')
    }else if(newPrefCarrierRecords.length == 0 ){
        createRecord.execute(
            TcalVsManagePreferredPortPairsApiUrls.MULTIPLE_POST,
            newPortPairRecords,
            onSuccessThenDelete,
            onError
        )
    }else{
        createRecord.execute(
          TcalVsManagePreferredCarrierApiUrls.MULTIPLE_POST,
          newPrefCarrierRecords,
          onSuccessThenPost,
          onError
        )
    }

  }

  return (

     <CngViewForm
      formikProps={{
      initialValues: {  
        ...initialValues,
        prefPcarrier: rowData.carrierList.map(record => record.prefCarrierId + ''), 
        polCode: rowData.polCode,
        podCode: rowData.podCode
      },
      validationSchema: validationSchema,
      onSubmit: onSubmit,
      validateOnChange: true
    }}
    renderBodySection={() => (

        <FormBody 
        closeDialog={closeDialog} 
        isSubmitting={isSubmitting} 
        initialPrefPcarrier={initialPrefPcarrier} 
        rowData={rowData}
        portName={portName}/>
    )}
    fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
  />
  )
}

function FormBody({ closeDialog, isSubmitting, initialPrefPcarrier, rowData, portName }) {

    const translatedTextsObject = ManageCarriersTranslationText()
    // const [prefPcarrier, , ] = useField('prefPcarrier')
    // const [newPreferredCarrierList, , ] = useField('newPreferredCarrier')
    const { getValues } = useFormContext()
    const checkBoxValue = getValues("prefPcarrier")
    const { fields: newPreferredCarrierList } = useFieldArray({name: "newPreferredCarrier"})

    const polDescriptionEn = portName.filter((pol) => pol.code == rowData.polCode)[0].descriptionEn.toLowerCase().replace(/(^\w|[\s(\.-\\\/]\w)/g, m => m.toUpperCase())
    const podDescriptionEn = portName.filter((pod) => pod.code == rowData.podCode)[0].descriptionEn.toLowerCase().replace(/(^\w|[\s(\.-\\\/]\w)/g, m => m.toUpperCase())

    function checkNewCarrierFields() {
      let newCarrierList = checkBoxValue.filter((carrier) => /^new_\d+$/.test(carrier))
      if (newCarrierList.length > 0) {
        for (const newCarrier of newCarrierList) {
          let index = newCarrier.split('_')[1]
          let carrierData = newPreferredCarrierList[index]
          if(!carrierData.carrierPartyId){
            return true
          }
          if(carrierData.contracted){
            if(!(
              moment(carrierData.contractStartDate, 'YYYY-MM-DD').isValid() &&
              moment(carrierData.contractEndDate, 'YYYY-MM-DD').isValid() &&
              ( moment(carrierData.contractStartDate, 'YYYY-MM-DD').isSame(
                moment(carrierData.contractEndDate, 'YYYY-MM-DD')) || 
                moment(carrierData.contractStartDate, 'YYYY-MM-DD').isBefore(
                moment(carrierData.contractEndDate, 'YYYY-MM-DD'))))
            ){
              return true
            }
          }
        }
      }
      return false
    }

    function shouldDisableApplyButton() {
      if(initialPrefPcarrier.every(item => checkBoxValue.includes(item)) && checkBoxValue.every(item => initialPrefPcarrier.includes(item))){
        // unchanged
        return true
      }else{
        return checkNewCarrierFields()
      }
    }

  return (
    <Box m={1}>
        <Box mx={6}>
            <Card variant="outlined"  style={{ borderStyle:'solid', borderWidth:'2px', borderColor:'#7CE7AC', borderRadius:16}}>
            <Box m={1} >
                <Grid container alignItems="center" justify="space-between">
                    <Grid item > 
                        <Typography variant='caption' style={{color:"#8996AF"}}>{translatedTextsObject.origin}</Typography>
                        <Box>
                            {polDescriptionEn}
                            <span className='pl-10'>
                                <Chip
                                label={<b>{rowData.polCode}</b>}
                                size='small'
                                />
                            </span>
                        </Box>
                    </Grid>
                    <Grid item > 
                        <Grid container justify="flex-end">
                        <Typography variant='caption' style={{color:"#8996AF"}}>{translatedTextsObject.destination}</Typography>
                        </Grid>
                        <Grid container justify="flex-end">
                        <Box>
                          <span className='pr-10'>
                              <Chip
                              label={<b>{rowData.podCode}</b>}
                              size='small'
                              />
                          </span>
                          {podDescriptionEn}
                        </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            </Card>
        </Box>

        <Box mt={2}>
        <SelectPreferredCarrierStep editCarrierPort/>
        </Box>

        <Box display='flex' flexDirection={'row-reverse'} mt={2} mr={6}> 
        <SubmitButton
          disabled={shouldDisableApplyButton()} 
          isSubmitting={isSubmitting}
        >
          {translatedTextsObject.apply}
        </SubmitButton>
        <Box mr={1}>
            <CancelButton onClick={closeDialog} disabled={isSubmitting} />
        </Box>
        </Box>
    </Box>
    )
}

const initialValues = {
  prefPcarrier: [],
  preferredCarrier: [],
  newPreferredCarrier: [],
  polCode: '',
  podCode: ''
}

const validationSchema = Yup.object({
  newPreferredCarrier: Yup.array(
    Yup.object({
      carrierPartyId: Yup.mixed().when('selected', {
        is: true,
        then: Yup.mixed().required('Carrier is Required.'),
        otherwise: Yup.mixed().nullable()
      }),
      contractEndDate: Yup.date().when(['contracted', 'selected'], {
        is: true,
        then: Yup.date()
          .validFormat()
          .typeError('Invalid date format. Only "YYYY-MM-DD" format is valid.')
          .min(
            Yup.ref('contractStartDate'),
            "Contract end date can't be before Contract start date."
          ),
        otherwise: Yup.date().nullable()
      }),
      contractStartDate: Yup.date().when(['contracted', 'selected'], {
        is: true,
        then: Yup.date()
          .validFormat()
          .typeError('Invalid date format. Only "YYYY-MM-DD" format is valid.'),
        otherwise: Yup.date().nullable()
      })
    })
  )
})

export default ManageEditPreferredPortPairsDialogForm