import PollingFormProperties from './PollingFormProperties'
import PollingApiUrls from 'src/apiUrls/PollingApiUrls'
import { components } from 'cng-web-lib'
import React from 'react'

const {
  form: { CngAddForm }
} = components

function PollingAddForm({
  history,
  showNotification,
  onPostSubmitSuccess
}) {
  return (
    <CngAddForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      history={history}
      showNotification={showNotification}
      bodySection={
        <PollingFormProperties.Fields
          showNotification={showNotification}
        />
      }
      formikProps={PollingFormProperties.formikProps}
      toClientDataFormat={PollingFormProperties.toClientDataFormat}
      toServerDataFormat={PollingFormProperties.toServerDataFormat}
      create={{
        url: PollingApiUrls.POST,
        onPostSubmitSuccess: onPostSubmitSuccess
      }}
    />
  )
}

export default PollingAddForm
