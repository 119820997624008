import Namespace from 'src/constants/locale/Namespace'
import TcalVsVesselKeys from 'src/constants/locale/key/TcalVsVessel'
import { useTranslation } from 'cng-web-lib'

const VesselsTranslationText = () => {
  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.TCAL_VS_VESSEL
  ])

  let vesselInformation = translate(
    Namespace.TCAL_VS_VESSEL,
    TcalVsVesselKeys.VESSEL_INFORMATION
  )
  let addAnotherVessel = translate(
    Namespace.TCAL_VS_VESSEL,
    TcalVsVesselKeys.ADD_ANOTHER_VESSEL
  )
  let discard = translate(Namespace.TCAL_VS_VESSEL, TcalVsVesselKeys.DISCARD)
  let addVessels = translate(
    Namespace.TCAL_VS_VESSEL,
    TcalVsVesselKeys.ADDVESSELS
  )
  let name = translate(Namespace.TCAL_VS_VESSEL, TcalVsVesselKeys.NAME)
  let imoNo = translate(Namespace.TCAL_VS_VESSEL, TcalVsVesselKeys.IMO_NO)
  let vesselStatus = translate(
    Namespace.TCAL_VS_VESSEL,
    TcalVsVesselKeys.VESSELSTATUS
  )
  let description = translate(
    Namespace.TCAL_VS_VESSEL,
    TcalVsVesselKeys.DESCRIPTION
  )
  let additionalVesselDetails = translate(
    Namespace.TCAL_VS_VESSEL,
    TcalVsVesselKeys.ADDITIONALVESSELDETAILS
  )
  let vessel = translate(Namespace.TCAL_VS_VESSEL, TcalVsVesselKeys.TITLE)
  let searchDesc = translate(
    Namespace.TCAL_VS_VESSEL,
    TcalVsVesselKeys.SEARCHDESC
  )
  let seqNo = translate(Namespace.TCAL_VS_VESSEL, TcalVsVesselKeys.SEQNO)
  let action = translate(Namespace.TCAL_VS_VESSEL, TcalVsVesselKeys.ACTION)
  let itemsSelected = translate(
    Namespace.TCAL_VS_VESSEL,
    TcalVsVesselKeys.ITEMSSELECTED
  )
  let lastModified = translate(
    Namespace.TCAL_VS_VESSEL,
    TcalVsVesselKeys.LASTMODIFIED
  )
  let user = translate(Namespace.TCAL_VS_VESSEL, TcalVsVesselKeys.USER)
  let backToMVesssel = translate(
    Namespace.TCAL_VS_VESSEL,
    TcalVsVesselKeys.BACK_TO_M_VESSEL
  )
  let amend = translate(Namespace.TCAL_VS_VESSEL, TcalVsVesselKeys.AMEND)
  let w1 = translate(Namespace.TCAL_VS_VESSEL, TcalVsVesselKeys.W1)
  let w2 = translate(Namespace.TCAL_VS_VESSEL, TcalVsVesselKeys.W2)
  let saveChanges = translate(
    Namespace.TCAL_VS_VESSEL,
    TcalVsVesselKeys.SAVE_CHANGES
  )
  let active = translate(Namespace.TCAL_VS_VESSEL, TcalVsVesselKeys.ACTIVE)
  let inactive = translate(Namespace.TCAL_VS_VESSEL, TcalVsVesselKeys.INACTIVE)
  let deleteRecords = translate(
    Namespace.TCAL_VS_VESSEL,
    TcalVsVesselKeys.DELETERECORDS
  )
  let noTakeMeBack = translate(
    Namespace.TCAL_VS_VESSEL,
    TcalVsVesselKeys.NOTAKEMEBACK
  )
  let yesDelete = translate(
    Namespace.TCAL_VS_VESSEL,
    TcalVsVesselKeys.YESDELETE
  )
  let d1 = translate(Namespace.TCAL_VS_VESSEL, TcalVsVesselKeys.D1)
  let d2 = translate(Namespace.TCAL_VS_VESSEL, TcalVsVesselKeys.D2)
  let d3 = translate(Namespace.TCAL_VS_VESSEL, TcalVsVesselKeys.D3)
  let items = translate(Namespace.TCAL_VS_VESSEL, TcalVsVesselKeys.ITEMS)
  let deleteSuccess = translate(
    Namespace.TCAL_VS_VESSEL,
    TcalVsVesselKeys.DELETESUCCESS
  )
  let amendVesselInfo = translate(
    Namespace.TCAL_VS_VESSEL,
    TcalVsVesselKeys.AMENDVESSELINFO
  )
  let addSuccess = translate(
    Namespace.TCAL_VS_VESSEL,
    TcalVsVesselKeys.ADDSUCCESS
  )
  let changeSuccess = translate(
    Namespace.TCAL_VS_VESSEL,
    TcalVsVesselKeys.CHANGESUCCESS
  )

  return {
    vesselInformation,
    addAnotherVessel,
    discard,
    addVessels,
    name,
    imoNo,
    vesselStatus,
    description,
    additionalVesselDetails,
    vessel,
    searchDesc,
    seqNo,
    action,
    itemsSelected,
    lastModified,
    user,
    backToMVesssel,
    amend,
    w1,
    w2,
    saveChanges,
    active,
    inactive,
    deleteRecords,
    noTakeMeBack,
    yesDelete,
    d1,
    d2,
    d3,
    items,
    deleteSuccess,
    amendVesselInfo,
    addSuccess,
    changeSuccess
  }
}

export default VesselsTranslationText
