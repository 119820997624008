import React, { useState } from 'react'
import { components, useServices } from 'cng-web-lib'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import AddScheduleDetailsFormProperties from './AddScheduleDetailsFormProperties'
import ManageScheduleTranslationText from '../ManageScheduleTranslationText'
import TcalVsManageScheduleApiUrls from 'src/apiUrls/TcalVsManageScheduleApiUrls'
import moment from 'moment'
import pathMap from 'src/paths/PathMap_VesselSchedule'
import useAddScheduleDetailsValidationSchema from './useAddScheduleDetailsValidationSchema'

// const validationSchema = useNewBookingMakeValidationSchema()

const {
  form: { CngViewForm },
  table: { useDefaultNotification }
} = components

function AddScheduleDetailsPage({ showNotification }) {
  const { id } = useParams()
  const history = useHistory()
  const location = useLocation()
  const voyageData = location.state.voyageData
  const { createRecord } = useServices()
  const [loading, setLoading] = useState(false)
  const translatedTextsObject = ManageScheduleTranslationText()
  const validationSchema = useAddScheduleDetailsValidationSchema()

  const {
    success: showSuccessNotification,
    error: showErrorNotification
  } = useDefaultNotification(showNotification)

  function onSubmit(data) {
    // console.log('submit scheduleData >', JSON.stringify(data))
    // console.log('submit voyageData > ', JSON.stringify(voyageData))

    // const  voyageLegsData = {...data}
    const DDMMYY_HHMMSS = 'DD-MM-YYYY HH:mm:ss'
    const backendDateFormat = 'YYYY-MM-DDTHH:mm:ss'
    const voyageLegsData = JSON.parse(JSON.stringify(data))
    console.log(data)

    // data.schedules.forEach(function(schedule, index) {
    //   console.log('vSchedule : ' + index
    //   + ' departDate : ' + schedule.departDate
    //   + ' validateDepartDate : ' + schedule.validateDepartDate)
    // })

    // history.push({
    //   pathname: pathMap.TCAL_VS_MANAGE_SCHEDULE_ADD_FINISH,
    //   // state: {voyageData: voyageData, scheduleData: data}
    //   state: {...voyageData, ...data}
    // })

    //This code formats the data with data/time
    voyageLegsData.voyageLegs.map((item) => {
      // moment(item.departureTime, DDMMYY_HHMMSS, true).isValid() > check if date is a valida format from UI
      // moment(item.departureTime, DDMMYY_HHMMSS).format(backendDateFormat) > then convert DD-MM-YYY HH:mm:ss to backend date format

      if (moment(item.departureTime, DDMMYY_HHMMSS, true).isValid()) {
        item.departureTime =
          moment(item.departureTime, DDMMYY_HHMMSS).format(backendDateFormat) +
          '+0800'
      }
      if (moment(item.arrivalTime, DDMMYY_HHMMSS, true).isValid()) {
        item.arrivalTime =
          moment(item.arrivalTime, DDMMYY_HHMMSS).format(backendDateFormat) +
          '+0800'
      }
      if (moment(item.cyCutoffTime, DDMMYY_HHMMSS, true).isValid()) {
        item.cyCutoffTime =
          moment(item.cyCutoffTime, DDMMYY_HHMMSS).format(backendDateFormat) +
          '+0800'
      }


    })

    let firstPort = ''
    let lastPort = ''
    let totalTransitTime = 0

    voyageLegsData.voyageLegs.forEach(function (voyageLeg, index) {
      if (index == 0) {
        firstPort = voyageLeg.portCode
      } else if (
        voyageLegsData.voyageLegs.length > 1 &&
        index == voyageLegsData.voyageLegs.length - 1
      ) {
        lastPort = voyageLeg.portCode
      }

      console.log('transitTime > ' + totalTransitTime)
      console.log('transitTime > ' + parseInt(voyageLeg.transitTime))
      totalTransitTime += parseInt(voyageLeg.transitTime) || 0
    })

    voyageData.transitTime = totalTransitTime

    let finalData = {
      ...voyageData,
      voyageNo: voyageData['voyageNo'].trim(),
      ...voyageLegsData
    }
    console.log('finalData scheduleData after configuration > ' + JSON.stringify(finalData))
    // console.log('submit scheduleData >', JSON.stringify(data))
    saveUpdateSchedule(finalData)
  }

  function saveUpdateSchedule(data) {
    console.log('data', data)
    setLoading(true)

    let isSave = data.isSave
    console.log('saveUpdateSchedule , isSave : ' + isSave)

    function onSuccess(response) {
      console.log('connection-request onSuccess', response)
      setLoading(false)
      if (response.errorMessages != null) {
        // if (response.errorMessages.length > 0) {
        showErrorNotification(response.errorMessages)
        // }
      } else {
        showSuccessNotification(
          `${
            isSave
              ? translatedTextsObject.saveScheduleSuccess
              : translatedTextsObject.amendScheduleSuccess
          }`
        )

        data.version = response.version
        history.push({
          pathname: isSave
            ? pathMap.TCAL_VS_MANAGE_SCHEDULE_ADD_FINISH
            : pathMap.TCAL_VS_MANAGE_SCHEDULE_AMEND_FINISH,
          state: {
            ...data,
            isSave: isSave,
            searchCriteria: data.searchCriteria
          }
        })
      }
    }

    function onError(error) {
      console.log('connection-request error', error.message)
      setLoading(false)
      showErrorNotification(error.message)
    }

    createRecord.execute(
      isSave
        ? TcalVsManageScheduleApiUrls.CREATE
        : TcalVsManageScheduleApiUrls.UPDATE,
      data,
      onSuccess,
      onError
    )
  }

  return (
    // use CngViewForm here for customizable button set
    <CngViewForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      history={history}
      showNotification={showNotification}
      bodySection={
        <AddScheduleDetailsFormProperties.FormFields
          disabled={false}
          showNotification={showNotification}
          loading={loading}
        />
      }
      formikProps={{
        ...AddScheduleDetailsFormProperties.formikProps,
        validationSchema,
        onSubmit: onSubmit
      }}
    />
  )
}

export default AddScheduleDetailsPage
