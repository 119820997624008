import { Card, CardContent, Grid } from '@material-ui/core'

import Namespace from 'src/constants/locale/Namespace'
import PollingKeys from 'src/constants/locale/key/Polling'
import React from 'react'
import { components } from 'cng-web-lib'
import makeValidationSchema from './PollingSourceMakeValidationSchema'
import { useTranslation } from 'cng-web-lib'
import CommonUtils from 'src/views/common/utils/Utils'


const {
  card: { CngSimpleCardHeader },
  form: {
    field: { CngTextField,CngDateTimeField }
  },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  source: '',
  status: '',
  runDate: '',
  completedDate: '',
  errorMsg: ''
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({
  disabled,
  showNotification,
  parentIdDropdownItems,
  shouldHideMap
}) {
  const { translate } = useTranslation(Namespace.POLLING)
  const translatedTextsObject = makeTranslatedTextsObject()
  const SG_DATE_FORMAT = CommonUtils.UI_FORMAT_DATE_SERVER_DATE


  function makeTranslatedTextsObject() {
    let pollingSource = translate(
      Namespace.POLLING,
      PollingKeys.PollingSource.TITLE
    )
    let source = translate(Namespace.POLLING, PollingKeys.PollingSource.SOURCE)
    let status = translate(Namespace.POLLING, PollingKeys.PollingSource.STATUS)
    let runDate = translate(
      Namespace.POLLING,
      PollingKeys.PollingSource.RUN_DATE
    )
    let completedDate = translate(
      Namespace.POLLING,
      PollingKeys.PollingSource.COMPLETED_DATE
    )
    let errorMsg = translate(
      Namespace.POLLING,
      PollingKeys.PollingSource.ERROR_MSG
    )

    return {
      pollingSource,
      source,
      status,
      runDate,
      completedDate,
      errorMsg
    }
  }

  return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.pollingSource} />
      <CardContent>
        <Grid container spacing={3}>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.source}>
            <CngTextField
              name='source'
              label={translatedTextsObject.source}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.status}>
            <CngTextField
              name='status'
              label={translatedTextsObject.status}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.runDate}>
            <CngDateTimeField
              name='runDate'
              label={translatedTextsObject.runDate}
              format={SG_DATE_FORMAT}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.completedDate}>
            <CngDateTimeField
              name='completedDate'
              label={translatedTextsObject.completedDate}
              format={SG_DATE_FORMAT}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.errorMsg}>
            <CngTextField
              name='errorMsg'
              label={translatedTextsObject.errorMsg}
              disabled={disabled}
            />
          </CngGridItem>
        </Grid>
      </CardContent>
    </Card>
  )
}

const PollingSourceFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default PollingSourceFormProperties
