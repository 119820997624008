import PollingFormProperties from './PollingFormProperties'
import PollingApiUrls from 'src/apiUrls/PollingApiUrls'
import { components } from 'cng-web-lib'
import React from 'react'

const {
  form: { CngViewForm }
} = components

function PollingViewForm({
  showNotification,
  id,
  onFetchPreSuccess = () => {
    //do nothing
  }
}) {
  return (
    <CngViewForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      showNotification={showNotification}
      bodySection={
        <PollingFormProperties.Fields
          disabled={true}
          showNotification={showNotification}
        />
      }
      formikProps={PollingFormProperties.formikProps}
      toClientDataFormat={PollingFormProperties.toClientDataFormat}
      toServerDataFormat={PollingFormProperties.toServerDataFormat}
      fetch={{
        id: id,
        url: PollingApiUrls.GET,
        onPreSuccess: onFetchPreSuccess
      }}
    />
  )
}

export default PollingViewForm
