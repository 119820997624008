import { Box, Paper } from '@material-ui/core'

import AddScheduleFileUploadComponent from './AddScheduleFileUploadComponent'
import CngBackdrop from '../../searchschedule/cngcomponent/CngBackDrop'
import GeneralStepperComponent from 'src/views/common/ui/GeneralStepperComponent'
import ManageScheduleTranslationText from '../ManageScheduleTranslationText'
import React from 'react'
import { components } from 'cng-web-lib'

const { CngGridItem } = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  ...AddScheduleFileUploadComponent.initialValues
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES }
}

function FormFields({ disabled, showNotification, loading }) {
  const msTranslatedTextsObject = ManageScheduleTranslationText()

  const addScheduleStep = [
    msTranslatedTextsObject.addFileUploadScheduleStep1,
    msTranslatedTextsObject.addScheduleStep3
  ]

  return (
    <Box>
      <CngGridItem xs={12} sm={9} shouldHide={loading ? false : true}>
        <CngBackdrop loading={loading} />
      </CngGridItem>

      <Paper>
        <Box alignItems='center' pr={30} pl={30}>
          <GeneralStepperComponent steps={addScheduleStep} activeStep={0} />
        </Box>
        <Box p={2} className='page-content'>
          <AddScheduleFileUploadComponent.FormBody
            showNotification={showNotification}
          />
        </Box>
      </Paper>
      <Paper>
        <Box alignItems='center'></Box>
      </Paper>
    </Box>
  )
}

const AddScheduleFileUploadFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  FormFields: FormFields
})

export default AddScheduleFileUploadFormProperties
