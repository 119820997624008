import React, { Fragment, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import AddScheduleFileUploadFormProperties from './AddScheduleFileUploadFormProperties'
import CngBackdrop from 'src/views/vesselschedule/searchschedule/cngcomponent/CngBackDrop'
import Utils from 'src/views/freightbooking/shared/Utils'
import VesselsTranslationText from '../../managevessels/VesselsTranslationText'
import { components } from 'cng-web-lib'
import pathMap from 'src/paths/PathMap_VesselSchedule'

const {
  form: { CngViewForm },
  table: { useDefaultNotification }
} = components

function AddScheduleFileUploadPage({ showNotification }) {
  const { id } = useParams()
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const translatedTextsObject = VesselsTranslationText()

  const { error: showErrorNotification } = useDefaultNotification(
    showNotification
  )

  function onSubmit(data) {
    console.log('submit', data)

    if (data.processDocuments && !Utils.isEmpty(data.processDocuments)) {
      const numErrorFile = data.processDocuments.filter(
        (doc) => doc.status === 'Error'
      ).length
      const numProgressFile = data.processDocuments.filter(
        (doc) => doc.status === 'Pending' || doc.status === 'Processing'
      ).length

      if (numErrorFile === 0 && numProgressFile === 0) {
        history.push({
          pathname: pathMap.TCAL_VS_MANAGE_SCHEDULE_ADD_FILE_UPLOAD_FINISH,
          state: data
        })
      } else {
        numProgressFile > 0
          ? showErrorNotification(
              numProgressFile +
                ' file(s) still processing. Please wait before submitting.'
            )
          : showErrorNotification(
              numErrorFile +
                ' file(s) error found. Please remove them before submitting.'
            )
      }
    } else {
      showErrorNotification('No File is being process yet!')
    }
  }

  return (
    // use CngViewForm here for customizable button set
    <Fragment>
      <CngBackdrop loading={loading} />
      <CngViewForm
        fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
        history={history}
        showNotification={showNotification}
        bodySection={
          <AddScheduleFileUploadFormProperties.FormFields
            disabled={false}
            showNotification={showNotification}
            loading={loading}
          />
        }
        formikProps={{
          ...AddScheduleFileUploadFormProperties.formikProps,
          onSubmit: onSubmit
        }}
      />
    </Fragment>
  )
}

export default AddScheduleFileUploadPage
