function ExportPdf(columns, data, filename, fontSize) {
  var _interopRequireDefault = require('@babel/runtime/helpers/interopRequireDefault')

  var _objectWithoutProperties2 = _interopRequireDefault(
    require('@babel/runtime/helpers/objectWithoutProperties')
  )

  var _typeof2 = _interopRequireDefault(
    require('@babel/runtime/helpers/typeof')
  )

  require('jspdf-autotable')

  try {
    var JSpdf = typeof window !== 'undefined' ? require('jspdf').jsPDF : null
    var finalData = data // Grab first item for data array, make sure it is also an array.
    // If it is an object, 'flatten' it into an array of strings.

    if (data.length && !Array.isArray(data[0])) {
      if ((0, _typeof2['default'])(data[0]) === 'object') {
        // Turn data into an array of string arrays, without the `tableData` prop
        finalData = data.map(function (_ref) {
          var tableData = _ref.tableData,
            row = (0, _objectWithoutProperties2['default'])(_ref, ['tableData'])
          return Object.values(row)
        })
      }
    }

    if (JSpdf !== null) {
      var content = {
        startY: 50,
        head: [
          columns.map(function (col) {
            return col.title
          })
        ],
        body: finalData
      }
      var unit = 'pt'
      var size = 'A4'
      var orientation = 'landscape'
      var doc = new JSpdf(orientation, unit, size)
      doc.setFontSize(fontSize != null ? fontSize : 15)
      doc.text(filename, 40, 40)
      doc.autoTable(content)
      doc.save(filename + '.pdf')
    }
  } catch (err) {
    console.error(
      "exporting pdf : unable to import 'jspdf-autotable' : ".concat(err)
    )
  }
}

export default ExportPdf
