import React, { useRef, useState } from 'react'
import { components, useServices } from 'cng-web-lib'
import { useHistory, useParams } from 'react-router-dom'

import AddScheduleFormProperties from './AddScheduleFormProperties'
import FreightBookingTranslationText from 'src/views/freightbooking/shared/FreightBookingTranslationText'
import ManageScheduleTranslationText from '../ManageScheduleTranslationText'
import pathMap from 'src/paths/PathMap_VesselSchedule'
import useAddScheduleValidationSchema from './useAddScheduleValidationSchema'

// const validationSchema = useNewBookingMakeValidationSchema()

const {
  form: { CngViewForm },
  table: { useDefaultNotification }
} = components

function AddSchedulePage({ showNotification }) {
  const { id } = useParams()
  const history = useHistory()
  const { createRecord } = useServices()
  const [loading, setLoading] = useState(false)
  const translatedTextsObject = ManageScheduleTranslationText()
  const validationSchema = useAddScheduleValidationSchema()

  const {
    success: showSuccessNotification,
    error: showErrorNotification
  } = useDefaultNotification(showNotification)

  function onSubmit(data) {
    console.log('submit addschedule', data)

    let isSave = ( (data.id == null || data.id == '') ? true : false)

    if(isSave){
      history.push({
        pathname: pathMap.TCAL_VS_MANAGE_SCHEDULE_ADD_DETAILS,
        state: {voyageData : {...data, isSave: isSave}}
      })
    }else{
      history.push({
        pathname: pathMap.TCAL_VS_MANAGE_SCHEDULE_AMEND_DETAILS,
        state: {voyageData : {...data, isSave: isSave}, searchCriteria : data.searchCriteria}
      })
    }
    
  }

  return (
    // use CngViewForm here for customizable button set
    <CngViewForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      history={history}
      showNotification={showNotification}
      bodySection={
        <AddScheduleFormProperties.FormFields
          disabled={false}
          showNotification={showNotification}
          loading={loading}
        />
      }
      formikProps={{
        ...AddScheduleFormProperties.formikProps,
        validationSchema,
        onSubmit: onSubmit
      }}
    />
  )
}

export default AddSchedulePage
