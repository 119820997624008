import React, { useState } from 'react'

import CloseDialogIconButton from 'src/components/iconbutton/CloseDialogIconButton.js'
import ManageCarriersTranslationText from './ManageCarriersTranslationText'
import ManageEditPreferredCarrierDialogContext from './ManageEditPreferredCarrierDialogContext.js'
import ManageEditPreferredCarrierForm from './ManageEditPreferredCarrierForm.js'
import Paper from '@material-ui/core/Paper'
import { components } from 'cng-web-lib'

const { CngDialog } = components

function ManageEditPreferredCarrierDialog({
  isDialogOpen,
  closeDialog,
  rowData,
  showNotification,
  cngTableRef
}) {
  const [isSubmitting, setSubmitting] = useState(false)
  const translatedTextsObject = ManageCarriersTranslationText()

  return (
    <ManageEditPreferredCarrierDialogContext.Provider
      value={{
        closeDialog,
        showNotification,
        rowData,
        form: {
          isSubmitting,
          setSubmitting
        },
        cngTableRef
      }}
    >
      <CngDialog
        customDialogContent={
          <Paper>
            <div style={{ overflow:'hidden' }}>
            <ManageEditPreferredCarrierForm />
            </div>
          </Paper>
        }
        dialogTitle={
          <>
            <b>{translatedTextsObject.editPreferredCarrier}</b>
            <CloseDialogIconButton
              onClick={closeDialog}
              disabled={isSubmitting}
            />
          </>
        }
        open={isDialogOpen}
        fullWidth
        maxWidth='sm'
      />
    </ManageEditPreferredCarrierDialogContext.Provider>
  )
}

export default ManageEditPreferredCarrierDialog