import React, { useState } from 'react'

import CloseDialogIconButton from 'src/components/iconbutton/CloseDialogIconButton.js'
import ManageCarriersTranslationText from 'src/views/vesselschedule/managepreferredcarrier/ManageCarriersTranslationText'
import ManagePreferredPortPairsDialogContext from './ManagePreferredPortPairsDialogContext.js'
import ManagePreferredPortPairsForm from './ManagePreferredPortPairsForm.js'
import Paper from '@material-ui/core/Paper'
import { components } from 'cng-web-lib'

const { CngDialog } = components

function ManagePreferredPortPairsDialog({
  portPairTableArray,
  isDialogOpen,
  closeDialog,
  showNotification,
  cngTableRef
}) {
  const [isSubmitting, setSubmitting] = useState(false)
  const [isStepOnTransitioning, setStepOnTransitioning] = useState(false)
  const translatedTextsObject = ManageCarriersTranslationText()

  return (
    <ManagePreferredPortPairsDialogContext.Provider
      value={{
        closeDialog,
        showNotification,
        form: {
          isSubmitting,
          setSubmitting
        },
        wizard: {
          isStepOnTransitioning,
          setStepOnTransitioning
        },
        cngTableRef
      }}
    >
      <CngDialog
        customDialogContent={
          <Paper>
            <div style={{ overflow:'hidden' }}>
            <ManagePreferredPortPairsForm portPairTableArray={portPairTableArray}/>
            </div>
          </Paper>
        }
        dialogTitle={
          <>
            <b>{translatedTextsObject.addPreferredPortPair}</b>
            <CloseDialogIconButton
              onClick={closeDialog}
              disabled={isSubmitting}
            />
          </>
        }
        open={isDialogOpen}
        fullWidth
        maxWidth='md'
      />
    </ManagePreferredPortPairsDialogContext.Provider>
  )
}

export default ManagePreferredPortPairsDialog
