import { Box, Paper } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import { components, constants, useServices } from 'cng-web-lib'
import { useHistory, useLocation } from 'react-router-dom'

import AmendButton from 'src/components/button/AmendButton'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import CngBackdrop from '../../searchschedule/cngcomponent/CngBackDrop'
import CodeMaintainanceApi from 'src/views/common/api/CodeMatainanceApi'
import ConfirmDialog from 'src/components/dialog/ConfirmDialog'
import EditIcon from '@material-ui/icons/Edit'
import GeneralStepperComponent from 'src/views/common/ui/GeneralStepperComponent'
import Loading from 'src/views/vesselschedule/searchschedule/Loading'
import ManageScheduleTranslationText from '../ManageScheduleTranslationText'
import TcalVsManageScheduleApiUrls from 'src/apiUrls/TcalVsManageScheduleApiUrls.js'
import TcalVsVesselApiUrls from 'src/apiUrls/TcalVsVesselApiUrls.js'
import Utils from 'src/views/common/utils/Utils'
import ViewSchduleInfoTableComponent from 'src/views/vesselschedule/manageschedule/viewschedule/ViewScheduleInfoTableComponent'
import ViewVesselVoyageComponent from './ViewVesselVoyageComponent'
import WarningDialog from 'src/components/dialog/WarningDialog'
import pathMap from 'src/paths/PathMap_VesselSchedule'
import { v4 as uuid } from 'uuid'

// import Api from '../shared/api'

const {
  form : {
    adapter: {
      useFormAdapter:{ useField, useFormikContext }
    }
  },
  table: { useDefaultNotification },
  CngGridItem,
  button: { CngPrimaryButton }
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  // ...VesselVoyageComponent.initialValues
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES }
  // makeValidationSchema: DEFAULT_VALIDATION_SCHEMA
  //makeValidationSchema: makeValidationSchema}
}

const {
  CodeMaintenanceType,
  filter: { EQUAL }
} = constants

function FormFields(props) {
  const { showNotification } = props
  const history = useHistory()
  const location = useLocation()
  const voyageData = location.state ? location.state.voyageData : null

  const msTranslatedTextsObject = ManageScheduleTranslationText()
  const uiTranslatedTextsObject = CalistaUiComponentTranslationText()
  const { fetchRecords } = useServices()
  const [voyage, setVoyage] = useState([])
  const [shouldRender, setShouldRender] = useState(false)
  const [loading, setLoading] = useState(false)
  const isEdit = useRef(false)
  const { errors, submitForm, setFieldValue } = useFormikContext()

  const convertVoyageStatus = (str) => {
    console.log('str.length == 1 >' + (str.length == 1))
    return str.includes('CVS') ? str.slice(str.length - 1) : str
  }

  useEffect(() => {
    // alert(location.state.voyageId)
    setLoading(true)
    fetchRecords.execute(
      TcalVsManageScheduleApiUrls.GET_BY_ID,
      // {voyageId: location.state.voyageId},
      { customData: { voyageId: location.state.voyageId } },
      (data) => {
        // temporary convert voyage status to match with temporary solution remove line below after fixed CAL_VOYAGE_STATUS in code master unique constraint
        data.voyageStatus = convertVoyageStatus(data.voyageStatus)
        populateVessel(data)
      },
      (error) => {
        console.log(error)
        setLoading(false)
      }
    )

    function populateVessel(voyage) {
      fetchRecords.execute(
        TcalVsVesselApiUrls.GET,
        {
          filters: [
            {
              field: 'id',
              operator: EQUAL,
              value: voyage.vesselId
            }
          ]
        },
        (data) => {
          voyage.vesselName = data.content[0].name
          voyage.imoNo = data.content[0].imoNo

          //get portCode from voyageList to filter
          let portCodes = voyage.voyageLegs.map((v) => v.portCode)

          populatePortName(portCodes, voyage)
          // setVoyage(voyage)
          // setLoading(false)
        },
        (error) => {
          console.log(error)
          setLoading(false)
        }
      )
    }
    setShouldRender(true)

    function populatePortName(portCodes, voyage) {
      CodeMaintainanceApi.fetchCodeMaintainance(
        fetchRecords,
        CodeMaintenanceType.PORT,
        [
          {
            field: 'code',
            operator: 'IN',
            value: portCodes
          }
        ],
        false,
        onLoadPortSuccess
      )

      function onLoadPortSuccess(data) {
        // alert(JSON.stringify(data))
        let newVoyageLegs = voyage.voyageLegs
          .sort((a, b) => (a.seqNo > b.seqNo ? 1 : -1))
          .map((v, index) => ({
            ...v,
            waypoint:
              v.seqNo == 1
                ? msTranslatedTextsObject.startingPoint
                : v.seqNo == voyage.voyageLegs.length
                ? msTranslatedTextsObject.finalPoint
                : msTranslatedTextsObject.stopOverPoint,
            portName:
              data.findIndex((port) => port.code == v.portCode) > -1
                ? data[data.findIndex((port) => port.code == v.portCode)]
                    .descriptionEn
                : ''
          }))

        voyage.voyageLegs = newVoyageLegs

        // alert(JSON.stringify(newVoyageLegs))
        setVoyage(voyage)
        setLoading(false)
      }
    }

    setShouldRender(true)
  }, [])

  if (!shouldRender) {
    return null
  }

  const amendSchedule = () => {
    history.push({
      pathname: pathMap.TCAL_VS_MANAGE_SCHEDULE_AMEND,
      //   state: { voyageId: voyageData.id }
      state: { voyageData: voyage }
    })
  }

  return (
    <Box>
      {/* {'Error :' + JSON.stringify(errors)} */}
      {/* <br/> */}
      {/* {'Touched :' + JSON.stringify(touched)} */}

      <Loading loading={loading} />
      {!loading ? (
        // <CngGridItem xs={12} sm={9} shouldHide={loading ? false : true}>
        //     <CngBackdrop loading={loading} />
        // </CngGridItem>

        <Paper>
          {!location.state.hideAmend ? (
            <Box
              display='flex'
              flexDirection='row-reverse'
              pr={5}
              pt={1}
              pb={1}
            >
              <CngPrimaryButton onClick={() => amendSchedule()}>
                <EditIcon />
                {uiTranslatedTextsObject.amendSchedule}
              </CngPrimaryButton>
            </Box>
          ) : null}

          <Box pl={5} pr={5} pt={3} className='page-content'>
            <Box pt={3}>
              <ViewVesselVoyageComponent.FormBody
                voyageData={voyage}
                showRemark
              />
            </Box>
            {voyage.voyageLegs ? (
              <Box pt={3}>
                <ViewSchduleInfoTableComponent.FormBody
                  voyageData={voyage}
                  showNotification={showNotification}
                />
              </Box>
            ) : null}
          </Box>
        </Paper>
      ) : null}
    </Box>
  )
}

const ViewScheduleFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  FormFields: FormFields
  // toClientDataFormat: toClientDataFormat,
  // toServerDataFormat: toServerDataFormat
})

export default ViewScheduleFormProperties
