import { DateTimeFormatter, Yup, components, useServices } from 'cng-web-lib'
import React, { useContext } from 'react'

import ManageCarriersTranslationText from 'src/views/vesselschedule/managepreferredcarrier/ManageCarriersTranslationText'
import ManagePreferredPortPairsDialogContext from './ManagePreferredPortPairsDialogContext.js'
import ManagePreferredPortPairsWizard from './ManagePreferredPortPairsWizard.js'
import TcalVsManagePreferredCarrierApiUrls from 'src/apiUrls/TcalVsManagePreferredCarrierApiUrls.js'
import TcalVsManagePreferredPortPairsApiUrls from 'src/apiUrls/TcalVsManagePreferredPortPairsApiUrls.js'
import moment from 'moment'

const {
  form : {
    CngViewForm,
    adapter: {
      useFormAdapter:{ useField, useFormikContext }
    }
  },
  table: { useDefaultNotification }
} = components

function ManagePreferredPortPairsForm(props) {
  const translatedTextsObject = ManageCarriersTranslationText()
  const { createRecord } = useServices()
  const dialogContext = useContext(ManagePreferredPortPairsDialogContext)
  const {
    closeDialog,
    showNotification,
    form: { isSubmitting, setSubmitting },
    cngTableRef
  } = dialogContext
  const {
    success: showSuccessNotification,
    error: showErrorNotification
  } = useDefaultNotification(showNotification)

  function onSubmit(data) {

    let newPortPairRecords = []
    let newPrefCarrierRecords = []

    function onSuccess(successData) {
      //console.log("sucess in adding record: " + JSON.stringify(successData))
      setSubmitting(false)
      closeDialog()
      if (cngTableRef.current && cngTableRef.current.performRefresh) {
        cngTableRef.current.performRefresh()
      }
      showSuccessNotification(translatedTextsObject.addPreferredPortPairSuccess)
    }

    function onError(error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.errorMessages &&
        error.response.data.errorMessages.length > 0
      ) {
        showErrorNotification(error.response.data.errorMessages)
      } else {
        showErrorNotification(error.message)
      }
      console.log('manage-preferred-port-pair error', error.message)
      setSubmitting(false)
    }

    function onSuccessThenPost(carrierDataList) {
      let portPairRecords = []
      carrierDataList.content.map(carrierData => {
        portPairRecords.push({
          preferredCarrier: { id: carrierData.id },
          polCode: data.polCode,
          podCode: data.podCode
        })
      })
      let mergedRecords = newPortPairRecords.concat(portPairRecords)
      createRecord.execute(
        TcalVsManagePreferredPortPairsApiUrls.MULTIPLE_POST,
        mergedRecords,
        onSuccess,
        onError
      )
    }

    setSubmitting(true)
    data['prefPcarrier'].forEach(partyId => {
      let index = partyId.split('_')[1]
      if(index){
        let newPartyData = data['newPreferredCarrier'][index]
        let temp_record = {
          carrierPartyId: newPartyData.carrierPartyId,
          contracted: newPartyData.contracted,
          contractStartDate: newPartyData.contracted ? newPartyData.contractStartDate : null,
          contractEndDate: newPartyData.contracted ? newPartyData.contractEndDate : null,
          sentExpiredNtf: newPartyData.contracted && moment(DateTimeFormatter.toClientDate(new Date())).isAfter(moment(newPartyData.contractEndDate)) ? true : false
        }
        newPrefCarrierRecords.push(temp_record)
      }else{
        let partyData = data['preferredCarrier'].find((carrier) => carrier.value === partyId)
        let temp_record = {
          prefCarrierId: partyData.value,
          preferredCarrier: { id: partyData.value },
          polCode: data.polCode,
          podCode: data.podCode
        }
        newPortPairRecords.push(temp_record)
      }
    })

    if(newPrefCarrierRecords.length == 0){
      createRecord.execute(
        TcalVsManagePreferredPortPairsApiUrls.MULTIPLE_POST,
        newPortPairRecords,
        onSuccess,
        onError
      )
    }else{
      createRecord.execute(
        TcalVsManagePreferredCarrierApiUrls.MULTIPLE_POST,
        newPrefCarrierRecords,
        onSuccessThenPost,
        onError
      )
    }

  }

  return (
    <CngViewForm
      formikProps={{
      initialValues: {  
        ...initialValues,
        portPairTableArray: props.portPairTableArray
      },
      validationSchema: validationSchema,
       onSubmit: onSubmit,
       validateOnChange:true
    }}
    bodySection={

       <FormBody closeDialog={closeDialog} isSubmitting={isSubmitting} />
    }
    fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
  />

  )
}

function FormBody({ closeDialog, isSubmitting }) {
  return <ManagePreferredPortPairsWizard />
}

const initialValues = {
  prefPcarrier: [],
  preferredCarrier: [],
  newPreferredCarrier: [],
  polCode: '',
  podCode: ''
}

const validationSchema = Yup.object({
  newPreferredCarrier: Yup.array(
    Yup.object({
      carrierPartyId: Yup.mixed().when('selected', {
        is: true,
        then: Yup.mixed().required('Carrier is Required.'),
        otherwise: Yup.mixed().nullable()
      }),
      contractEndDate: Yup.date().when(['contracted', 'selected'], {
        is: true,
        then: Yup.date()
          .validFormat()
          .typeError('Invalid date format. Only "YYYY-MM-DD" format is valid.')
          .min(
            Yup.ref('contractStartDate'),
            "Contract end date can't be before Contract start date."
          ),
        otherwise: Yup.date().nullable()
      }),
      contractStartDate: Yup.date().when(['contracted', 'selected'], {
        is: true,
        then: Yup.date()
          .validFormat()
          .typeError('Invalid date format. Only "YYYY-MM-DD" format is valid.'),
        otherwise: Yup.date().nullable()
      })
    })
  ),
  polCode: Yup.string()
    .required('Origin is Required')
    .notOneOf(
      [Yup.ref('podCode')],
      'Origin and Destination is required and cannot be the same.'
    )
    .nullable(),
  podCode: Yup.string()
    .required('Destination is Required')
    .notOneOf(
      [Yup.ref('polCode')],
      'Origin and Destination is required and cannot be the same.'
    )
    .nullable()
})

export default ManagePreferredPortPairsForm
