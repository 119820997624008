import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  Grid
} from '@material-ui/core'
import { DateTimeFormatter, components } from 'cng-web-lib'
import React, { useEffect, useRef, useState } from 'react'

import AccordionFooterControlComponent from 'src/views/common/ui/AccordionFooterControlComponet'
import AccordionHeaderComponent from 'src/views/common/ui/AccordionHeaderComponent'
import ManageScheduleTranslationText from '../ManageScheduleTranslationText'
import VesselAutocompleteField from 'src/components/autocomplete/VesselAutocompleteField'
import { prop } from 'ramda'
import { v4 as uuid } from 'uuid'
import { withStyles } from '@material-ui/core/styles'

const {
  form: {
    adapter: {
      useFormAdapter:{ useField, useFormikContext }
    },
    field: { CngTextField, CngDateField, CngSelectField }
  },
  CngGridItem
} = components

const initialValues = Object.freeze({
  vesselId: '',
  vesselName: '',
  voyageStatus: '',
  intVoyageNo: '',
  voyageNo: '',
  imoNo: '',
  service: '',
  scheduleNo: '',
  version: 0
  // partyId: ""
})

const AsteriskRedCngTextField = withStyles({
  root: {
    '& .MuiFormLabel-asterisk.MuiInputLabel-asterisk': {
      color: 'red'
    }
  }
})(CngTextField)

const AsteriskRedCngSelectField = withStyles({
  root: {
    '& .MuiFormLabel-asterisk.MuiInputLabel-asterisk': {
      color: 'red'
    }
  }
})(CngSelectField)

const FormBody = (props) => {
  const [vesselIdField, ,] = useField('vesselId')
  const [vesselNameField, ,] = useField('vesselName')
  const [, ,] = useField('voyageStatus')
  const [imoNoField, , { setValue: setImoNo }] = useField('imoNo')
  const [, ,] = useField('service')
  const [, ,] = useField('intVoyageNo')
  const [, ,] = useField('voyageNo')
  const [, ,] = useField('scheduleNo')
  const [voyageStatusKey, setVoyageStatusKey] = useState()
  const translatedTextsObject = ManageScheduleTranslationText()
  const [detailsExpanded, setDetailsExpanded] = useState(false)
  const { submitForm, setFieldValue } = useFormikContext()
  const imoNoKeyRef = useRef(uuid())
  const [shouldRender, setShouldRender] = useState(false)

  const handleDetailsExpand = () => {
    setDetailsExpanded(!detailsExpanded)
  }

  const onChangeVesselAutoComplete = (value, option) => {
    if (option) {
      setFieldValue('vesselName', option.data.name)
      // setFieldValue('vesselId', option.data.id)
      setFieldValue('imoNo', option.data.imoNo)
    } else {
      setFieldValue('vesselName', '')
      // setFieldValue('vesselId', '')
      setFieldValue('imoNo', '')
    }
  }

  useEffect(() => {
    setVoyageStatusKey(uuid())
    // imoNoKeyRef.current = uuid()
    setShouldRender(true)
  }, [props.isEdit])

  if (!shouldRender) {
    return null
  }

  return (
    <Card>
      <Accordion expanded={detailsExpanded}>
        <AccordionSummary
          aria-controls='vesselVoyageComponentContent'
          id='vesselVoyageComponentHeader'
        >
          <Grid container>
            <AccordionHeaderComponent
              title={translatedTextsObject.vesselVoyageInfo}
            />
            <Grid container item xs={12} sm={12} spacing={2}>
              <CngGridItem xs={4} sm={4}>
                <AsteriskRedCngSelectField
                  name='voyageStatus'
                  label={translatedTextsObject.voyageStatus}
                  items={[
                    { text: translatedTextsObject.open, value: '1' },
                    { text: translatedTextsObject.closed, value: '0' },
                    { text: translatedTextsObject.suspended, value: '5' }
                  ]}
                  required
                />
              </CngGridItem>
              <CngGridItem item xs={4} sm={4}>
                <VesselAutocompleteField
                  name='vesselId'
                  label={translatedTextsObject.vesselName}
                  onChangeAutoComplete={onChangeVesselAutoComplete}                  
                  value={{label: vesselNameField.value}}
                  required
                />
              </CngGridItem>
              <CngGridItem item xs={4} sm={4}>
                <AsteriskRedCngTextField
                  type='text'
                  name='voyageNo'
                  label={translatedTextsObject.voyageNo}
                  inputProps={{ maxLength: 17 }}
                  required
                />
              </CngGridItem>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid container item xs={12} sm={12} spacing={2}>
              <CngGridItem item xs={4} sm={4}>
                <CngTextField
                  type='text'
                  name='intVoyageNo'
                  label={translatedTextsObject.internationalVoyageNo}
                  inputProps={{ maxLength: 20 }}
                />
              </CngGridItem>
              <CngGridItem item xs={4} sm={4}>
                <CngTextField
                  type='text'
                  name='imoNo'
                  label={translatedTextsObject.imoNumber}
                  disabled
                  value={imoNoField.value}
                  keyRef={imoNoKeyRef.current}
                />
              </CngGridItem>
              <CngGridItem item xs={4} sm={4}>
                <CngTextField
                  type='text'
                  name='service'
                  label={translatedTextsObject.service}
                  inputProps={{ maxLength: 100 }}
                />
              </CngGridItem>

              <CngGridItem item xs={4} sm={4}>
                <CngTextField
                  type='text'
                  name='scheduleNo'
                  label={translatedTextsObject.scheduleNo}
                  inputProps={{ maxLength: 20 }}
                />
              </CngGridItem>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <AccordionFooterControlComponent
        handleAccordionExpand={handleDetailsExpand}
        expanded={detailsExpanded}
        footerText={translatedTextsObject.additionalVesselVoyageDetails}
      />
    </Card>
  )
}

const VesselVoyageComponent = Object.freeze({
  FormBody: FormBody,
  initialValues: initialValues
})

export default VesselVoyageComponent
