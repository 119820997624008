import { Box, Card, Grid } from '@material-ui/core'

import LabelValueVertical from 'src/views/freightbooking/components/LabelValueVertical'
import ManageScheduleTranslationText from '../ManageScheduleTranslationText'
import React from 'react'
import { components } from 'cng-web-lib'

const { CngGridItem } = components

const FormBody = (props) => {
  const { voyageData, showRemark } = props
  const translatedTextsObject = ManageScheduleTranslationText()

  return (
    <Card>
      <Box className='view-card-header'>
        {translatedTextsObject.vesselVoyageInfo}
      </Box>
      {console.log('voyageData > ' + JSON.stringify(voyageData))}
      <Grid container>
        <Grid container item xs={12} sm={12} spacing={2}>
          <CngGridItem xs={4} sm={4}>
            <LabelValueVertical
              label={translatedTextsObject.voyageStatus}
              value={
                voyageData.voyageStatus === '1'
                  ? translatedTextsObject.open
                  : voyageData.voyageStatus === '0'
                  ? translatedTextsObject.closed
                  : voyageData.voyageStatus === '5'
                  ? translatedTextsObject.suspended
                  : '-'
              }
            />
          </CngGridItem>
        </Grid>
        <Grid container item xs={12} sm={12} spacing={2}>
          <CngGridItem item xs={3} sm={3}>
            <LabelValueVertical
              label={translatedTextsObject.vesselName}
              value={voyageData.vesselName}
            />
          </CngGridItem>
          <CngGridItem item xs={2} sm={2}>
            <LabelValueVertical
              label={translatedTextsObject.voyageNo}
              value={voyageData.voyageNo}
            />
          </CngGridItem>
          <CngGridItem item xs={2} sm={2}>
            <LabelValueVertical
              label={translatedTextsObject.internationalVoyageNo}
              value={voyageData.intVoyageNo}
            />
          </CngGridItem>
          <CngGridItem item xs={2} sm={2}>
            <LabelValueVertical
              label={translatedTextsObject.imoNumber}
              value={voyageData.imoNo}
            />
          </CngGridItem>
          <CngGridItem item xs={1} sm={1}>
            <LabelValueVertical
              label={translatedTextsObject.service}
              value={voyageData.service}
            />
          </CngGridItem>
          <CngGridItem item xs={2} sm={2}>
            <LabelValueVertical
              label={translatedTextsObject.scheduleNo}
              value={voyageData.scheduleNo}
            />
          </CngGridItem>
        </Grid>

        {showRemark ? (
          <Grid item xs={12} sm={12} spacing={2}>
            <LabelValueVertical
              label={translatedTextsObject.remarks}
              value={voyageData.remarks}
            />
          </Grid>
        ) : null}
      </Grid>
    </Card>
  )
}

const ViewVesselVoyageComponent = Object.freeze({
  FormBody: FormBody
  // initialValues: initialValues
})

export default ViewVesselVoyageComponent
