import { BasePage as CngBasePage } from 'cng-web-lib'
import ManagePreferredPortPairsTable from './ManagePreferredPortPairsTable.js'
import React from 'react'
import TranslationText from 'src/views/vesselschedule/searchschedule/SearchScheduleTranslationText'
import useBreadcrumbNameMap from 'src/navigations/breadcrumbs/Breadcrumb_VesselSchedule'

function ManagePreferredPortPairsTablePage(props) {
  const translatedTextsObject = TranslationText()
  const breadcrumbNameMap = useBreadcrumbNameMap()

  return (
    <CngBasePage
      moduleTitle={translatedTextsObject.vesselSchedule}
      renderPage={(showSnackbar) => (
        <ManagePreferredPortPairsTable
          showNotification={showSnackbar}
          {...props}
        />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

export { ManagePreferredPortPairsTablePage }
