import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
  Paper
} from '@material-ui/core'
import { DateTimeFormatter, components, useServices } from 'cng-web-lib'
import React, { useEffect, useRef } from 'react'

import CarrierAutocompleteField from 'src/components/autocomplete/CarrierAutocompleteField.js'
import ManageCarriersTranslationText from 'src/views/vesselschedule/managepreferredcarrier/ManageCarriersTranslationText'
import SearchScheduleApiUrls from 'src/apiUrls/SearchScheduleApiUrls.js'
import check from 'check-types'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment'
import { useFieldArray, useFormContext } from 'react-hook-form'

const {
  form: {
    adapter: {
      useFormAdapter:{ useField, useFormikContext }
    },
    field: { CngCheckboxField, CngDateField }
  }
} = components

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15)
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20
  },
  details: {
    alignItems: 'center'
  },
  column: {
    flexBasis: '33.33%'
  }
}))

function AddNewPreferredCarrier(props) {
  const translatedTextsObject = ManageCarriersTranslationText()
  const { fetchRecords } = useServices()
  const carriersRef = useRef()
  // const [newPreferredCarrierList, , { setValue: setNewPreferredCarrierList }] = useField(
  //   'newPreferredCarrier'
  // )
  // const [prefPcarrier, , { setValue: setPrefPcarrier }] = useField(
  //   'prefPcarrier'
  // )
  //const { fields: prefPcarrier } = useFieldArray({name: "prefPcarrier"})
  const { getValues,trigger } = useFormContext()
  
  const checkBoxValue = getValues("prefPcarrier")
  const { fields: newPreferredCarrierList } = useFieldArray({name: "newPreferredCarrier"})
  const classes = useStyles()

  useEffect(() => {
    fetchRecords.execute(
      SearchScheduleApiUrls.CARRIER_ENROLL_GET,
      {},
      onSuccess,
      onError
    )

    function onSuccess(data) {
      carriersRef.current = data.content
    }

    function onError(error) {
      console.log('error', error.message)
    }
  }, [])

const { errors, setFieldValue } = useFormikContext()

  const getFieldError = ( idx, fieldPath) => {
    if(errors){
      console.log("there is error: " + JSON.stringify(errors))
      let temp = fieldPath.split('.')
      if(checkBoxValue.includes('new_' + idx)){
        if (errors[temp[0]] && errors[temp[0]][idx] && errors[temp[0]][idx][temp[1]]) {
          return errors[temp[0]][idx][temp[1]]
        }
      }
    }
    return null
  }

  return (
    <Accordion expanded={true}>
      <AccordionSummary aria-controls='panel1c-content' id='panel1c-header'>
        <div className={classes.column}>
          <Typography className={classes.secondaryHeading}>
            <b>{translatedTextsObject.addNewCarrier}</b>
          </Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        <Box>
          <Box mb={1} width={750}>
            <CarrierAutocompleteField
              name={`newPreferredCarrier[${props.index}].carrierPartyId`}
              label={translatedTextsObject.carriersNovccParty}
              onChange={(value) => {
                let temp = [...newPreferredCarrierList]
                if (check.number(value)) {
                  temp[props.index].name = carriersRef.current.find((pCarrier) => pCarrier.partyId === value).name
                  temp[props.index].code = carriersRef.current.find((pCarrier) => pCarrier.partyId === value).code
                }
                temp[props.index].carrierPartyId = value
                // setNewPreferredCarrierList(temp)
                setFieldValue('newPreferredCarrier',temp)
              }}
            />
          </Box>
          {console.log('newPreferredCarrierList', newPreferredCarrierList)}
          {console.log('props.index', props.index)}
          <Paper style={{backgroundColor: newPreferredCarrierList[props.index].contracted ? "#FAFBFC" : "#FFFFFF"}}>
          <Box m={1}>
          <Box my={1}>
            <CngCheckboxField
              name={`newPreferredCarrier[${props.index}].contracted`}
              label={
                <Typography variant='body2' gutterBottom>
                  {translatedTextsObject.contracted}
                </Typography>
              }
              onChange={() => {
                let temp_list = [...newPreferredCarrierList]
                temp_list[props.index].contractStartDate = DateTimeFormatter.toClientDate(new Date())
                temp_list[props.index].contractEndDate = DateTimeFormatter.toClientDate(moment().add(30, 'days').toDate())
                temp_list[props.index].contracted = !temp_list[props.index].contracted
                // setNewPreferredCarrierList(temp_list)
                setFieldValue('newPreferredCarrier', temp_list, true)
                trigger()
              }}
            />
          </Box>
          {newPreferredCarrierList[props.index].contracted && (
            <Box my={1} >
              <Box display='flex'>
                <Box flexGrow={1} />
                <Box width={0.45} mr={1.5} mb={1}>
                  <CngDateField
                    style={{backgroundColor: "#FFFFFF"}}
                    name={`newPreferredCarrier[${props.index}].contractStartDate`}
                    label={translatedTextsObject.startingDate}
                    onChange={(date) => {
                      let temp_list2 = [...newPreferredCarrierList]
              
                      if (moment(date, 'YYYY-MM-DD').isValid()) {
                        temp_list2[props.index].contractStartDate = DateTimeFormatter.toClientDate(date.toDate())
                        setFieldValue(`newPreferredCarrier[${props.index}].contractStartDate`,DateTimeFormatter.toClientDate(date.toDate()), true)
                      } else {
                        temp_list2[props.index].contractStartDate = date
                        setFieldValue(`newPreferredCarrier[${props.index}].contractStartDate`,date, true)
                      }
                      console.log("templist: " + JSON.stringify(temp_list2))
                     // setNewPreferredCarrierList(temp_list2)
                     setFieldValue('newPreferredCarrier', temp_list2, true)
                     trigger()
                    }}
                    error={getFieldError(props.index, `newPreferredCarrier.contractStartDate`)}
                    helperText={getFieldError(props.index, `newPreferredCarrier.contractStartDate`)}
                  />
                </Box>
                <Box width={0.45}>
                  <CngDateField
                    style={{backgroundColor: "#FFFFFF"}}
                    name={`newPreferredCarrier[${props.index}].contractEndDate`}
                    label={translatedTextsObject.endingDate}
                    shouldDisableDate={(date) => {
                      return moment(date).isBefore(
                        moment(newPreferredCarrierList[props.index].contractStartDate)
                      )
                    }}
                    onChange={(date) => {
                      console.log("templist: " + JSON.stringify(newPreferredCarrierList))
                      let temp_list3 = [...newPreferredCarrierList]
                      if (moment(date, 'YYYY-MM-DD').isValid()) {
                        temp_list3[props.index].contractEndDate = DateTimeFormatter.toClientDate(date.toDate())
                        setFieldValue(`newPreferredCarrier[${props.index}].contractEndDate`,DateTimeFormatter.toClientDate(date.toDate()), true)
                      } else {
                        temp_list3[props.index].contractEndDate = date
                        setFieldValue(`newPreferredCarrier[${props.index}].contractEndDate`,date, true)
                      }
                     // setNewPreferredCarrierList(temp_list3)
                     setFieldValue('newPreferredCarrier', temp_list3, true)
                    }}
                    error={getFieldError(props.index, `newPreferredCarrier.contractEndDate`)}
                    helperText={getFieldError(props.index, `newPreferredCarrier.contractEndDate`)}
                  />
                </Box>
              </Box>
            </Box>
          )}
          </Box>
          </Paper>
        </Box>
      </AccordionDetails>
    </Accordion>
  )
}

export default AddNewPreferredCarrier
