import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  Grid
} from '@material-ui/core'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { components, useServices } from 'cng-web-lib'

import AccordionFooterControlComponent from 'src/views/common/ui/AccordionFooterControlComponet'
import AccordionHeaderComponent from 'src/views/common/ui/AccordionHeaderComponent'
import DocumentComponent from './DocumentComponent'
import ManageScheduleTranslationText from '../ManageScheduleTranslationText'
import TcalVsManageScheduleApiUrls from 'src/apiUrls/TcalVsManageScheduleApiUrls'
import VesselsTranslationText from '../../managevessels/VesselsTranslationText'
import fileDownload from 'js-file-download'

const {
  form : {
    adapter: {
      useFormAdapter:{ useField, useFormikContext }
    }
  },
  button: { CngSecondaryButton },
  table: { useDefaultNotification },
  CngGridItem
} = components

const FormBody = (props) => {
  const translatedTextsObject = VesselsTranslationText()
  const msTranslatedTextsObject = ManageScheduleTranslationText()
  const [, , { setValue: setPendingBookingDocField }] = useField(
    'pendingDocuments'
  )
  const [, , { setValue: setUploadedDocsField }] = useField('processDocuments')
  const [pendingDocs, setPendingDocs] = useState([]) //for the file list below drop zone
  const [uploadedDocs, setUploadedDocs] = useState([]) //for the file list above the drop zone
  const [pollingCount, setPollingCount] = useState(0)
  const { createRecord, fetchRecords, deleteRecord } = useServices()
  const batchNo = useRef('')

  const {
    success: showSuccessNotification,
    error: showErrorNotification
  } = useDefaultNotification(props.showNotification)

  useEffect(() => {
    if (pollingCount !== 0 && batchNo.current !== '') {
      setTimeout(() => {
        fetchRecords.execute(
          TcalVsManageScheduleApiUrls.DOCUMENT_QUERY,
          {
            customData: { batchNo: batchNo.current }
          },
          (data) => {
            let FromApi = data.content
            console.log('From APi ' + JSON.stringify(FromApi[0]))
            let newUploadedDocs = FromApi.map((file) => {
              return {
                ...uploadedDocs.find((doc) => doc.docId === file.id),
                status: file.status,
                version: file.version
              }
            })

            console.log('new status : ' + newUploadedDocs)
            setUploadedDocs(newUploadedDocs)
            setUploadedDocsField(newUploadedDocs)

            if (
              newUploadedDocs.filter(
                (doc) => doc.status === 'Pending' || doc.status === 'Processing'
              ).length > 0
            ) {
              setPollingCount(pollingCount + 1)
            }
          },
          (error) => {
            console.log(error)
            setPollingCount(pollingCount + 1)
          }
        )
      }, 1000)
    }
  }, [pollingCount])

  const removeRowClick = (id) => (e) => {
    console.log('remove: ' + id)
    pendingDocs.splice(id, 1)
    const newFiles = [].concat([], pendingDocs)
    setPendingDocs(newFiles)
    setPendingBookingDocField(newFiles)

    e.stopPropagation()
  }

  const removeUploadedRowClick = (id) => {
    console.log('remove uploaded: ' + id)
    const removeFile = uploadedDocs[id]
    uploadedDocs.splice(id, 1)
    const newFiles = [].concat([], uploadedDocs)
    setUploadedDocs(newFiles)
    setUploadedDocsField(newFiles)

    deleteRecord.execute(
      TcalVsManageScheduleApiUrls.DOCUMENT_DELETE,
      { id: removeFile.docId, version: removeFile.version },
      () => {
        console.log('successfully remove the record with id : ' + id)
      },
      (error) => {
        console.log(error)
      }
    )
  }

  const downloadUploadedRowClick = (id) => {
    console.log('download uploaded: ' + id)
    console.log(uploadedDocs[id])

    fileDownload(uploadedDocs[id].file, uploadedDocs[id].file.name)
  }

  const downloadLogClick = (id) => {
    fetchRecords.execute(
      TcalVsManageScheduleApiUrls.DOCUMENT_LOG_DOWNLOAD,
      {
        customData: { id: uploadedDocs[id].docId }
      },
      (data) => {
        fileDownload(data, 'error_' + uploadedDocs[id].file.name + '.log')
      },
      (error) => {
        console.log(error)
      }
    )
  }

  const upload = () => {
    const newFiles = [].concat(pendingDocs)
    setPendingDocs([])
    setPendingBookingDocField([])
    uploadDocument(newFiles)
  }

  function uploadDocument(newFiles) {
    console.log('upload Document to Backend')

    var formData = new FormData()

    newFiles.map((file, i) => {
      var fileBlob = file.file
      console.log(fileBlob)
      formData.append('fileContents', fileBlob) // name of the file upload box
    })

    formData.append('batchNo', batchNo.current)

    function onSuccess(response) {
      console.log('onSuccess', response)
      // setLoading(false)
      if (response.errorMessages != null) {
        showErrorNotification(response.errorMessages)
      } else {
        let pDocs = [] //to store the final documents
        if (response && response.length > 0) {
          batchNo.current = response[0].batchNo
          response.map((file, i) => {
            let pDoc = {
              batchNo: file.batchNo,
              filename: file.fileName,
              status: file.status,
              docId: file.id,
              filesize: file.fileSize,
              version: file.version
            }

            pDocs.push(pDoc)
          })
        }

        let responseFile = newFiles.map((file, i) => {
          return {
            ...file,
            ...pDocs[i]
          }
        })
        const processFiles = [].concat(uploadedDocs, responseFile)
        setUploadedDocs(processFiles)
        setUploadedDocsField(processFiles)

        //showSuccessNotification('Successfully uploaded the files.')
        setPollingCount(pollingCount + 1)
      }
    }

    function onError(error) {
      console.log('connection-request error', error.message)
      //setLoading(false)
      showErrorNotification(error.message)
    }

    createRecord.execute(
      TcalVsManageScheduleApiUrls.DOCUMENT_UPLOAD,
      formData,
      onSuccess,
      onError
    )
  }

  function setItem(newFileObjs) {
    if (pendingDocs == null || pendingDocs.length <= 0) {
      setPendingDocs(newFileObjs)
      setPendingBookingDocField(newFileObjs)
    } else {
      setPendingDocs(pendingDocs.concat(newFileObjs))
      setPendingBookingDocField(pendingDocs.concat(newFileObjs))
    }
  }

  return (
    <Fragment>
      <Box m={1}>
        <Grid container xs={12} sm={12} spacing={2}>
          <Box width={1} mb={1}>
            <Accordion expanded={true}>
              <AccordionSummary
                aria-controls='vesselDetailsComponentContent'
                id='vesselDetailsComponentHeader'
              >
                <CngGridItem xs={12} sm={12}>
                  <Grid container xs={12} sm={12} spacing={2}>
                    <CngGridItem xs={12} sm={12}>
                      <Box display='flex'>
                        <Box flexGrow={1}>
                          <AccordionHeaderComponent
                            title={msTranslatedTextsObject.documents}
                            hideMandatory={true}
                          />
                        </Box>
                        <Box>
                          <a
                            className='download'
                            href={
                              process.env.PUBLIC_URL +
                              '/static/doc/schedule_template.csv'
                            }
                            download={'schedule_template.csv'}
                          >
                            <CngSecondaryButton name='downloadTemplate'>
                              {msTranslatedTextsObject.downloadTemplate}
                            </CngSecondaryButton>
                          </a>
                        </Box>
                      </Box>
                    </CngGridItem>
                    <CngGridItem xs={12}>
                      <DocumentComponent.FormBody
                        pendingDocs={pendingDocs}
                        uploadedDocs={uploadedDocs}
                        removeRowClick={removeRowClick}
                        removeUploadedRowClick={removeUploadedRowClick}
                        downloadUploadedRowClick={downloadUploadedRowClick}
                        downloadLogClick={downloadLogClick}
                        upload={upload}
                        setItem={setItem}
                      />
                    </CngGridItem>
                  </Grid>
                </CngGridItem>
              </AccordionSummary>
            </Accordion>

            <AccordionFooterControlComponent
              expanded={true}
              footerText={msTranslatedTextsObject.addFileUploadScheduleStep1}
            />
          </Box>
        </Grid>
      </Box>

      <Box pt={3} mr={2}>
        <Grid container xs={12} sm={12}>
          <Grid xs={6} sm={6} justify='flex-start'>
            <CngSecondaryButton
              onClick={() => {
                if (pendingDocs !== null && pendingDocs.length > 0) {
                  setPendingDocs([])
                  setPendingBookingDocField([])
                }
              }}
            >
              {translatedTextsObject.discard}
            </CngSecondaryButton>
          </Grid>
          <Grid container xs={6} sm={6} justify='flex-end'>
            <Box>
              <Button
                variant='contained'
                className='button-light-green originalText'
                type='submit'
              >
                {msTranslatedTextsObject.submitSchedule}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  )
}

const initialValues = {}

const AddScheduleFileUploadComponent = Object.freeze({
  FormBody: FormBody,
  initialValues: initialValues
})

export default AddScheduleFileUploadComponent
