import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid
} from '@material-ui/core'

import AccordionFooterControlComponent from 'src/views/common/ui/AccordionFooterControlComponet'
import DeleteIcon from '@material-ui/icons/Delete'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import IconButton from '@material-ui/core/IconButton'
import React from 'react'
import VesselsTranslationText from '../VesselsTranslationText'
import { components } from 'cng-web-lib'
import { withStyles } from '@material-ui/core/styles'

const {
  form: {
    field: { CngTextField, CngSelectField }
  },
  CngGridItem
} = components

const AsteriskRedCngTextField = withStyles({
  root: {
    '& .MuiFormLabel-asterisk.MuiInputLabel-asterisk': {
      color: 'red'
    }
  }
})(CngTextField)

const AsteriskRedCngSelectField = withStyles({
  root: {
    '& .MuiFormLabel-asterisk.MuiInputLabel-asterisk': {
      color: 'red'
    }
  }
})(CngSelectField)

const MultipleAddVesselDetailsComponent = (props) => {
  const translatedTextsObject = VesselsTranslationText()

  function icons(vesselList, i) {
    return (
      <CngGridItem xs={12} sm={12}>
        <IconButton aria-label='copy'>
          <FileCopyIcon onClick={() => props.handleCopyVessel(i)} />
        </IconButton>
        {vesselList.length > 1 && (
          <IconButton aria-label='remove'>
            <DeleteIcon onClick={() => props.handleRemoveVessel(i)} />
          </IconButton>
        )}
      </CngGridItem>
    )
  }

  return (
    <Grid container xs={12} sm={12} spacing={2}>
      {props.vesselList.map((x, i) => {
        return (
          <Box width={1} mb={1}>
            <Accordion expanded={x.vesselBoxExpanded}>
            <AccordionSummary
                aria-controls='vesselDetailsComponentContent'
                id='vesselDetailsComponentHeader'
              >
                <CngGridItem xs={12} sm={12} key={x.id}>
                  <Grid container xs={12} sm={12} spacing={2}>
                    <CngGridItem
                      xs={12}
                      sm={12}
                      style={{
                        color: '#8181a5',
                        fontWeight: 'bold',
                        fontSize: 'small'
                      }}
                    >{`SN${i + 1}`}</CngGridItem>
                    <CngGridItem xs={12} sm={4}>
                      <AsteriskRedCngTextField
                        name={`newVessel[${i}].name`}
                        label={translatedTextsObject.name} //<span style="color:red;">*</span>
                        value={x.name}
                        onChange={(e) => props.handleInputChange(e, i)}
                        error={props.getFieldError(i, 'newVessel.name')}
                        helperText={props.getFieldError(i, 'newVessel.name')}
                        inputProps={{ maxLength: 100 }}
                        required
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={4}>
                      <CngTextField
                        name={`newVessel[${i}].imoNo`}
                        label={translatedTextsObject.imoNo}
                        value={x.imoNo}
                        onChange={(e) => props.handleInputChange(e, i)}
                        error={props.getFieldError(i, 'newVessel.imoNo')}
                        helperText={props.getFieldError(i, 'newVessel.imoNo')}
                        inputProps={{ maxLength: 15 }}
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={4}>
                      <AsteriskRedCngSelectField
                        name={`newVessel[${i}].status`}
                        label={
                          translatedTextsObject.vessel +
                          ' ' +
                          translatedTextsObject.vesselStatus
                        }
                        items={[
                          { text: translatedTextsObject.active, value: 'true' },
                          {
                            text: translatedTextsObject.inactive,
                            value: 'false'
                          }
                        ]}
                        onChange={(e) =>
                          props.handleDropdownChange(e, i, 'status')
                        }
                        error={props.getFieldError(i, 'newVessel.status')}
                        helperText={props.getFieldError(i, 'newVessel.status')}
                        required
                      />
                    </CngGridItem>
                    {!x.vesselBoxExpanded ? icons(props.vesselList, i) : null}
                  </Grid>
                </CngGridItem>
              </AccordionSummary>

              <AccordionDetails>
                <Grid container xs={12} sm={12} spacing={2}>
                  <CngGridItem xs={12} sm={12}>
                    <CngTextField
                      type='text'
                      name={`newVessel[${i}].description`}
                      label={translatedTextsObject.description}
                      value={x.description}
                      multiline
                      rows={4}
                      onChange={(e) => props.handleInputChange(e, i)}
                      inputProps={{ maxLength: 255 }}
                    />
                  </CngGridItem>
                  {x.vesselBoxExpanded ? icons(props.vesselList, i) : null}
                </Grid>
              </AccordionDetails>
            </Accordion>

            <AccordionFooterControlComponent
              handleAccordionExpand={props.handleVesselDetailsExpand(i)}
              expanded={x.vesselBoxExpanded}
              footerText={translatedTextsObject.additionalVesselDetails}
            />
          </Box>
        )
      })}
    </Grid>
  )
}

export default MultipleAddVesselDetailsComponent
