import Namespace from 'src/constants/locale/Namespace'
import { Yup } from 'cng-web-lib'
import { constants } from 'cng-web-lib'

const {
  locale: {
    key: { CommonValidationMessageKeys }
  }
} = constants

function makeValidationSchema(translate) {
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )

  return Yup.object({
    source: Yup.string(),
    status: Yup.string(),
    runDate: Yup.date().typeError(dateTypeErrorMessage),
    completedDate: Yup.date().typeError(dateTypeErrorMessage),
    errorMsg: Yup.string()
  })
}

export default makeValidationSchema
