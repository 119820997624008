import { Avatar, Box, Paper, Typography, Tooltip, makeStyles } from '@material-ui/core'
import { PlusCircle, Trash, LogOut } from 'react-feather'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { components, useServices } from 'cng-web-lib'

import ManageCarriersTranslationText from './ManageCarriersTranslationText'
import ManagePreferredCarrierDialog from './ManagePreferredCarrierDialog.js'
import ExportPreferredDialog from '../component/ExportPreferredDialog.js'
import TcalVsManagePreferredCarrierApiUrls from 'src/apiUrls/TcalVsManagePreferredCarrierApiUrls.js'
import ManageEditPreferredCarrierDialog from './ManageEditPreferredCarrierDialog.js'
import moment from 'moment'

const {
  button: { CngPrimaryButton, CngSecondaryButton },
  table: { CngCrudTable, useDefaultNotification }
} = components

const useStyles = makeStyles((theme) => ({
  div: {
    '& .MuiTable-root': {
      borderSpacing: '0 5px',
      borderCollapse: 'separate',
      '& .MuiTableHead-root .MuiTableRow-head .MuiTableCell-head': {
        backgroundColor: '#fafbfc',
        borderBottom: 'none',
        '&:nth-child(1)': {
          width: 'auto !important'
        },
        '&:nth-child(2)': {
          width: '10% !important',
          fontSize: '0px'
        },
        '&:nth-child(n + 3):nth-last-child(n + 2)': {
          width: '20% !important'
        },
        '&:nth-last-child(1)': {
          width: '5% !important'
        }
      },
      '& .MuiTableRow-root[index]': {
        '&:nth-child(even)': {
          backgroundColor: '#fafbfc'
        },
        '& td[class*="MuiTableCell-root"]': {
          border: '1px solid #f1f4f9 !important',
          borderLeft: 'none !important',
          borderRight: 'none !important',
          '&:nth-child(1)': {
            borderLeft: '1px solid #f1f4f9 !important',
            borderRadius: '5px 0 0 5px !important',
            width: 'auto !important'
          },
          '&:nth-child(2)': {
            width: '10% !important'
          },
          '&:nth-child(n + 3):nth-last-child(n + 2)': {
            width: '20% !important'
          },
          '&:nth-last-child(1)': {
            width: '5% !important',
            borderRight: '1px solid #f1f4f9 !important',
            borderRadius: '0 5px 5px 0 !important',
            '& .MuiIconButton-root': {
              border: '1px solid #f4f4f7',
              borderRadius: '10px'
            }
          }
        }
      }
    }
  },
  trashIcon: {
    color: theme.palette.text.error,
    width: theme.spacing(2),
    height: theme.spacing(2)
  }
}))

function ManagePreferredCarrierTable(props) {
  const translatedTextsObject = ManageCarriersTranslationText()
  const { securedSendRequest } = useServices()
  const classes = useStyles()
  const { showNotification } = props

  const notification = useDefaultNotification(showNotification)

  const tableRef = useRef()
  const cngTableRef = useRef()
  const darkRef = useRef('')
  const [loading, setLoading] = useState(true)
  const [shipperPartyId, setShipperPartyId] = useState(null)

  const tableRefStateCallback = useCallback(() => {
    return tableRef.current?.state
  }, [])

  function renderDateColumn(date) {
    if (!date) {
      return null
    }

    return <span>{moment(date).format('DD/MM/YYYY')}</span>
  }

  const columns = [
    {
      field: 'name',
      title: translatedTextsObject.carriersNovccParty,
      show: true,
      render: (rowData) => {
        return (
          <Box display='flex' alignItems='center'>
            <Box>
              <Avatar
                classes={{
                  img: 'vs-carrier-image'
                }}
                alt='Carrier'
                src={`${process.env.PUBLIC_URL}/static/images/carriers/${rowData.code}.svg`}
              >
                <img
                  className={'search-button'}
                  src={`${process.env.PUBLIC_URL}/static/images/carriers/genericCarrier.svg`}
                ></img>
              </Avatar>
            </Box>
            <Box ml={1.5} flexGrow={1}>
              <b>{rowData.name}</b>
            </Box>
          </Box>
        )
      }
    },
    {
      field: 'contracted',
      title: translatedTextsObject.contracted,
      show: true,
      render: (rowData) => {
        return (
          <Box>
            {rowData.contracted ? (
              <b>{translatedTextsObject.contracted}</b>
            ) : null}
          </Box>
        )
      }
    },
    {
      field: 'contractStartDate',
      title: translatedTextsObject.startingDate,
      show: true,
      render: (rowData) => {
        return renderDateColumn(rowData.contractStartDate)
      }
    },
    {
      field: 'contractEndDate',
      title: translatedTextsObject.endingDate,
      show: true,
      render: (rowData) => {
        return renderDateColumn(rowData.contractEndDate)
      }
    }
  ]

  const [
    isManagePreferredCarrierDialogOpen,
    setManagePreferredCarrierDialogOpen
  ] = useState(false)

  function closeDialog() {
    setManagePreferredCarrierDialogOpen(false)
  }

  useEffect(() => {
    securedSendRequest.execute(
      'GET',
      `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/user-preference/by-current-user`,
      {},
      (data) => {
        darkRef.current = data.data.theme
        setShipperPartyId(data.data.partyId)
      },
      (error) => {
        console.log(error)
      },
      onComplete
    )

    function onComplete() {
      setLoading(false)
    }
  }, [])

  const [
    editPreferredCarrierDialogOpen,
    setEditPreferredCarrierDialogOpen
  ] = useState(false)

  function closeEditDialog() {
    setEditPreferredCarrierDialogOpen(false)
  }

  const [editRowData, setEditRowData] = useState({})

  const editRow = (data) =>{
    setEditPreferredCarrierDialogOpen(true)
    setEditRowData(data)
  }

  const [
    exportDialogOpen,
    setExportDialogOpen
  ] = useState(false)

  const [tableData, setTableData] = useState({})

  function closeExportDialog() {
    setExportDialogOpen(false)
  }

  return (
    <Paper>
      {!loading ? (
        <Box
          m='8px'
          className={darkRef.current.trim() === 'LIGHT' ? classes.div : ''}
        >
          <CngCrudTable
            {...props}
            fieldLevel=''
            tableRef={(ref) => {
              if (ref === null) {
                return
              }

              tableRef.current = ref
            }}
            columns={columns}
            onRowClick={(evt, data) => editRow(data)}
            del={{
              url: TcalVsManagePreferredCarrierApiUrls.DELETE
            }}
            fetch={{
              url: TcalVsManagePreferredCarrierApiUrls.PREFERRED_CARRIERS_GET
            }}
            notification={notification}
            options={{
              filtering: false,
              draggable: false
            }}
            localization={{
              header: {
                actions: translatedTextsObject.remove
              }
            }}
            deleteButtonProps={{
              icon: ({ color, ...otherProps }) => (
                <Trash {...otherProps} className={classes.trashIcon} />
              ),
              tooltip: translatedTextsObject.remove
            }}
            refreshButtonProps={{
              hidden: true
            }}
            actions={[
              {
                custom: () => {
                  return (
                    <Tooltip title={translatedTextsObject.exportTable}>
                    <Box flexGrow={2}>
                      <CngSecondaryButton
                        onClick={() =>
                          setExportDialogOpen(true)
                        }
                      >
                        <LogOut style={{ marginRight: 5 }} />{' '}
                        {translatedTextsObject.exportTable}
                      </CngSecondaryButton>
                    </Box>
                    </Tooltip>
                  )
                },
                isFreeAction: true
              }
            ]}
            fetchMode='FULL'
            customToolbar={(toolbar) => {
              const page = tableRefStateCallback()?.currentPage || 0
              const pageSize = tableRefStateCallback()?.pageSize || 0
              const totalResult = tableRefStateCallback()?.data?.length || 0

              return (
                <Box display='flex' alignItems='center'>
                  <Box pl='8px'>
                    <Typography variant='body2'>{`Showing ${Math.min(
                      page * pageSize + 1,
                      totalResult
                    )}-${Math.min(
                      (page + 1) * pageSize,
                      totalResult
                    )} of ${totalResult}`}</Typography>
                  </Box>
                  <Box flexDirection='row-reverse' flexGrow={1}>
                    {toolbar}
                  </Box>
                  <Box>
                  <Tooltip title={translatedTextsObject.addCarrier}>
                    <Box flexGrow={2}>
                      <CngPrimaryButton
                        onClick={() =>
                          setManagePreferredCarrierDialogOpen(true)
                        }
                      >
                        <PlusCircle style={{ marginRight: 5 }} />{' '}
                        {translatedTextsObject.addCarrier}
                      </CngPrimaryButton>
                    </Box>
                  </Tooltip>
                  </Box>
                </Box>
              )
            }}
            postFetch={(data) => {
              setTableData(data)
              return data.map((d) => ({ ...d, id: d.prefCarrierId }))
            }}
            cngTableRef={cngTableRef}
            // tableConfigurationCode="REPLACE_WITH_CORE_TABLE_CONFIG_CODE"
          />
        </Box>
      ) : null}
      <ManagePreferredCarrierDialog
        isDialogOpen={isManagePreferredCarrierDialogOpen}
        closeDialog={closeDialog}
        showNotification={showNotification}
        cngTableRef={cngTableRef}
      />
      <ManageEditPreferredCarrierDialog
        isDialogOpen={editPreferredCarrierDialogOpen}
        closeDialog={closeEditDialog}
        rowData={{...editRowData, shipperPartyId: shipperPartyId}}
        showNotification={showNotification}
        cngTableRef={cngTableRef}
      />
      <ExportPreferredDialog
        isDialogOpen={exportDialogOpen}
        closeDialog={closeExportDialog}
        tableData={tableData}
        columns={columns}
        fileName="Preferred Carriers"
        showNotification={showNotification}
        cngTableRef={cngTableRef}
      />
    </Paper>
  )
}

export default ManagePreferredCarrierTable
