import {
  Box,
  Paper,
  Table,
  TableContainer,
  TableFooter,
  TableRow
} from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import { components, constants, useServices } from 'cng-web-lib'
import { useHistory, useLocation } from 'react-router-dom'

import CngTableBody from 'src/components/table/CngTableBody'
import CngTableHeader from 'src/components/table/CngTableHeader'
import CngToolbarTable from 'src/components/table/CngToolbarTable'
import ConfirmDialog from 'src/components/dialog/ConfirmDialog'
import Loading from 'src/views/vesselschedule/searchschedule/Loading'
import ManageScheduleButtonTabComponent from './ManageScheduleButtonTab'
import ManageScheduleTranslationText from './ManageScheduleTranslationText'
import ResultPageTablePagination from 'src/views/vesselschedule/searchschedule/ResultPageTablePagination'
import TcalVsManageScheduleApiUrls from 'src/apiUrls/TcalVsManageScheduleApiUrls.js'
import TcalVsVesselApiUrls from 'src/apiUrls/TcalVsVesselApiUrls.js'
import { makeStyles } from '@material-ui/core'
import moment from 'moment'
import pathMap from 'src/paths/PathMap_VesselSchedule'

const {
  table: { useDefaultNotification }
} = components

const {
  filter: { EQUAL }
} = constants

const useStyles = makeStyles((theme) => ({
  div: {
    '& .MuiTable-root': {
      borderSpacing: '0 5px',
      borderCollapse: 'separate',
      '& .MuiTableHead-root .MuiTableRow-head .MuiTableCell-head': {
        backgroundColor: '#fafbfc',
        borderBottom: 'none',
        '&:nth-child(1)': {
          width: 'auto !important'
        },
        '&:nth-child(n + 2):nth-last-child(n + 2)': {
          width: '20% !important'
        }
      },
      '& .MuiTableRow-root[index]': {
        '&:nth-child(even)': {
          backgroundColor: '#fafbfc'
        },
        '& td[class*="MuiTableCell-root"]': {
          border: '1px solid #f1f4f9 !important',
          borderLeft: 'none !important',
          borderRight: 'none !important',
          '&:nth-child(1)': {
            borderLeft: '1px solid #f1f4f9 !important',
            borderRadius: '5px 0 0 5px !important',
            width: 'auto !important'
          },
          '&:nth-child(n + 2):nth-last-child(n + 2)': {
            width: '20% !important'
          },
          '&:nth-last-child(1)': {
            borderRight: '1px solid #f1f4f9 !important',
            borderRadius: '0 5px 5px 0 !important',
            '& .MuiIconButton-root': {
              border: '1px solid #f4f4f7',
              borderRadius: '10px'
            }
          }
        }
      }
    }
  },
  trashIcon: {
    color: theme.palette.text.error,
    width: theme.spacing(2),
    height: theme.spacing(2)
  }
}))

function ManageScheduleTable(props) {
  const {
    success: showSuccessNotification,
    error: showErrorNotification
  } = useDefaultNotification(props.showNotification)

  const translatedTextsObject = ManageScheduleTranslationText()
  const { fetchRecords, deleteRecord } = useServices()
  const [formCount, setFormCount] = useState(0)
  const [fileCount, setFileCount] = useState(0)
  const [vessels, setVessels] = useState([])
  const [voyages, setVoyages] = useState([])
  const [loading, setLoading] = useState(true)
  const [originalVoyages, setOriginalVoyages] = useState([])
  const [search, setSearch] = useState('')
  const [openSearchPanel, setOpenSearchPanel] = useState(false)
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [showDeleteInUseSchedule, seShowDeleteInUseSchedule] = useState(false)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [columns, setColumns] = useState([
    {
      title: translatedTextsObject.vesselName.toUpperCase(),
      active: false,
      field: 'vesselName',
      show: true
    },
    {
      title: translatedTextsObject.voyageNo.toUpperCase(),
      active: false,
      field: 'voyageNo',
      show: true
    },
    {
      title: translatedTextsObject.intVoyageNo.toUpperCase(),
      active: false,
      field: 'intVoyageNo',
      show: true
    },
    {
      title: translatedTextsObject.remarks.toUpperCase(),
      active: false,
      field: 'remarks',
      show: true
    },
    {
      title: translatedTextsObject.service.toUpperCase(),
      active: false,
      field: 'service',
      show: true
    },
    {
      title: translatedTextsObject.user.toUpperCase(),
      active: false,
      field: 'updatedBy',
      show: true,
      lookup: 'user'
    },
    {
      title: translatedTextsObject.lastModified.toUpperCase(),
      active: true,
      field: 'updatedDate',
      show: true,
      type: 'date',
      order: 'asc'
    }
  ])

  const classes = useStyles()
  const params = useRef([])
  const { showNotification } = props
  const notification = useDefaultNotification(showNotification)
  const location = useLocation()
  const history = useHistory()
  // const [, , { setValue: setFormClickedField }] = useField('formClicked')
  const [formClicked, setFormClicked] = useState()
  const [userProfile, setUserProfile] = useState([])
  const [user, setUser] = useState([])
  const [submittedFiles, setSubmittedFiles] = useState([])
  const [originalSubmittedFiles, setOriginalSubmittedFiles] = useState([])
  const [fileColumns, setFileColumns] = useState([
    {
      title: 'FILE NAME',
      active: true,
      field: 'fileName',
      show: true,
      order: 'desc'
    },
    {
      title: 'FILE SIZE',
      active: false,
      field: 'fileSize',
      show: true,
      type: 'fileSize'
    },
    {
      title: 'CREATION DATE',
      active: false,
      field: 'createdDate',
      show: true,
      type: 'date'
    }
  ])

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    params.current = {
      docId: queryParams.get('docId'),
      fileName: queryParams.get('fileName')
    }

    setFormClicked(true)

    fetchRecords.execute(
      `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/user/get`,
      undefined,
      (data) => {
        // console.log('userProfile >' + data.content)
        let FromApi = data.content
        setUserProfile(FromApi)
      },
      (error) => {
        console.log(error)
      }
    )

    fetchRecords.execute(
      `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/user/detail/get`,
      undefined,
      (data) => {
        // console.log('user >' + JSON.stringify(data.content))
        let FromApi = data.content
        setUser(FromApi)
        FetchVesselsList()
      },
      (error) => {
        console.log(error)
      }
    )

    fetchRecords.execute(
      TcalVsManageScheduleApiUrls.DOCUMENT_GET,
      undefined,
      (data) => {
        let FromApi = data.content
          .sort((a, b) => (a.fileName > b.fileName ? 1 : -1))
          .map((v, index) => ({
            ...v,
            checked: false,
            seqNo: index + 1
          }))
        setSubmittedFiles(FromApi)
        setFileCount(FromApi.length)
        setOriginalSubmittedFiles(FromApi)
      },
      (error) => {
        console.log(error)
      }
    )

    function FetchVesselsList() {
      fetchRecords.execute(
        TcalVsVesselApiUrls.GET,
        undefined,
        (data) => {
          let FromApi = data.content
          // .sort((a, b) => (a.id > b.id ? 1 : -1))
          console.log('vesselList size > ' + JSON.stringify(FromApi[0]))
          setVessels(FromApi)
          FetchVoyagesList(FromApi)
        },
        (error) => {
          console.log(error)
        }
      )
    }

    function FetchVoyagesList(vesselsList) {
      fetchRecords.execute(
        TcalVsManageScheduleApiUrls.GET,
        params.current.docId !== null
          ? {
              filters: [
                {
                  field: 'scheduleDocId',
                  operator: EQUAL,
                  value: params.current.docId
                }
              ]
            }
          : {
              filters: []
            },
        (data) => {
          let FromApi = data.content
            .sort((a, b) => (b.updatedDate > a.updatedDate ? 1 : -1))
            .map((v, index) => ({
              ...v,
              checked: false,
              seqNo: index + 1,
              vesselName:
                vesselsList.findIndex((vessel) => vessel.id == v.vesselId) !==
                -1
                  ? vesselsList[
                      vesselsList.findIndex((vessel) => vessel.id == v.vesselId)
                    ].name
                  : '',
              // temporary convert voyage status to match with temporary solution remove line below after fixed CAL_VOYAGE_STATUS in code master unique constraint
              voyageStatus: convertVoyageStatus(v.voyageStatus)
            }))
          // setFormCount(data.formCount)
          // console.log('voyageTable ' + JSON.stringify(FromApi))
          FromApi.forEach((v) => {
            console.log(v.voyageNo + ', voyage status > ' + v.voyageStatus)
          })
          setVoyages(FromApi)
          setOriginalVoyages(FromApi)

          // sort here to avoid incorrect sequence no
          onSortClick('vesselName')
          setFormCount(FromApi.length)
          setLoading(false)
        },
        (error) => {
          console.log(error)
          setLoading(false)
        }
      )
    }
  }, [])

  const convertVoyageStatus = (str) => {
    // console.log('str.length == 1 >' + (str.length == 1))
    return str.includes('CVS') ? str.slice(str.length - 1) : str
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const comparator = (prop, desc = true) => (a, b) => {
    console.log('a[' + prop + '] >' + a[prop])
    if (a[prop] === null) {
      return 1
    }
    if (b[prop] === null) {
      return -1
    }
    const order = desc ? -1 : 1
    if (a[prop] < b[prop]) {
      return -1 * order
    }
    if (a[prop] > b[prop]) {
      return 1 * order
    }
    return 0 * order
  }

  const onSortClick = (fieldName) => () => {
    if (formClicked) {
      setColumns(
        columns.map((column) => ({
          ...column,
          active: fieldName === column.field,
          order:
            (fieldName === column.field &&
              (column.order === 'desc' ? 'asc' : 'desc')) ||
            undefined
        }))
      )

      setVoyages(
        voyages
          .slice()
          .sort(
            comparator(
              fieldName,
              columns.find((column) => column.field === fieldName).order ===
                'desc'
            )
          )
          .map((v, index) => ({
            ...v,
            seqNo: index + 1
          }))
      )
    } else {
      setFileColumns(
        fileColumns.map((column) => ({
          ...column,
          active: fieldName === column.field,
          order:
            (fieldName === column.field &&
              (column.order === 'desc' ? 'asc' : 'desc')) ||
            undefined
        }))
      )

      setSubmittedFiles(
        submittedFiles
          .slice()
          .sort(
            comparator(
              fieldName,
              fileColumns.find((column) => column.field === fieldName).order ===
                'desc'
            )
          )
          .map((v, index) => ({
            ...v,
            seqNo: index + 1
          }))
      )
    }
  }

  const onRowCheck = (id) => (e) => {
    let newItems = formClicked ? [...voyages] : [...submittedFiles]
    let index = (formClicked ? voyages : submittedFiles).findIndex(
      (item) => item.id === id
    )
    let item = formClicked ? voyages[index] : submittedFiles[index]
    newItems[index] = { ...item, checked: !item.checked }
    formClicked ? setVoyages(newItems) : setSubmittedFiles(newItems)
    e.stopPropagation()
  }

  const onRowClick = (id) => () => {
    let index = (formClicked ? voyages : submittedFiles).findIndex(
      (item) => item.id === id
    )
    let item = formClicked ? voyages[index] : submittedFiles[index]
    !formClicked
      ? history.push({
          pathname: pathMap.TCAL_VS_MANAGE_SCHEDULE_VIEW_UPLOAD_SCHEDULE,
          search: '?docId=' + item.id + '&fileName=' + item.fileName
        })
      : history.push({
          pathname: pathMap.TCAL_VS_MANAGE_SCHEDULE_VIEW,
          state: {
            voyageId: id,
            hideAmend: params.current.fileName !== null ? true : false
          }
        })
  }

  const onHeaderCheck = (event) => {
    let newItems = formClicked ? [...voyages] : [...submittedFiles]
    newItems = newItems.map((item) => ({
      ...item,
      checked: event.target.checked
    }))
    formClicked ? setVoyages(newItems) : setSubmittedFiles(newItems)
  }

  const onCancel = () => {
    let newItems = formClicked ? [...voyages] : [...submittedFiles]
    newItems = newItems.map((item) => ({
      ...item,
      checked: false
    }))
    formClicked ? setVoyages(newItems) : setSubmittedFiles(newItems)
  }

  const onSearchChange = (e) => {
    setSearch(e.target.value)
    filterTextImplementation(e.target.value)
  }

  const onClickSearchPanel = () => {
    setOpenSearchPanel(true)
  }

  const onCloseSearchPanel = () => {
    closeSearchPanel()
  }

  const onCheckBoxViewColumnChange = (index) => ({ target: { checked } }) => {
    let newColumns = formClicked ? [...columns] : [...fileColumns]
    let column = formClicked ? columns[index] : fileColumns[index]
    newColumns[index] = { ...column, show: !column.show }
    formClicked ? setColumns(newColumns) : setFileColumns(newColumns)
  }

  const onDelete = () => {
    let newItems = formClicked
      ? voyages
          .filter((voyage) => !voyage.checked)
          .map((v, index) => ({ ...v, seqNo: index + 1 }))
      : submittedFiles
          .filter((submittedFile) => !submittedFile.checked)
          .map((v, index) => ({ ...v, seqNo: index + 1 }))
    formClicked ? setVoyages(newItems) : setSubmittedFiles(newItems)

    let deleteVoyages = voyages.filter((voyage) => voyage.checked)
    // delete seq no and check box will take immediate effect,
    // suggest to remove this code as now change to remove records from list after deletion success
    // deleteVoyages.forEach((v) => {
    //   delete v.seqNo
    //   delete v.checked
    // })

    // for delete voyages
    let formatDeleteVoyages = deleteVoyages.map((v) => {
      let obj = {}
      obj.voyageId = v.id
      obj.version = v.version
      return obj
    })

    let wrappedDeleteVoyages = { voyageCriteriaList: formatDeleteVoyages }

    let deleteScheduleDocs = submittedFiles.filter(
      (submittedFile) => submittedFile.checked
    )
    deleteScheduleDocs.forEach((v) => {
      delete v.seqNo
      delete v.checked
    })

    // formClicked ? setVoyages(newItems) : setSubmittedFiles(newItems) // reset list after delete success
    setConfirmDialogOpen(false)

    // console.log('wrappedDeleteVoyages' + JSON.stringify(wrappedDeleteVoyages))
    // console.log('deleteScheduleDocs > ' + JSON.stringify(deleteScheduleDocs))

    deleteRecord.execute(
      formClicked
        ? TcalVsManageScheduleApiUrls.MULTIPLE_DELETE
        : TcalVsManageScheduleApiUrls.DOCUMENT_MULTIPLE_DELETE,
      formClicked ? wrappedDeleteVoyages : { deleteScheduleDocs },
      () => {
        showSuccessNotification(
          (formClicked
            ? formatDeleteVoyages.length
            : deleteScheduleDocs.length) + translatedTextsObject.deleteSuccess
        )

        let newOriginalItems = formClicked
          ? originalVoyages
              .filter((oriVoyage) => {
                return deleteVoyages.findIndex(
                  (deleteVoyage) => deleteVoyage.id == oriVoyage.id
                ) !== -1
                  ? false
                  : true
              })
              .map((v, index) => ({ ...v, seqNo: index + 1 }))
          : originalSubmittedFiles
              .filter((oriSubmittedFile) => {
                return deleteScheduleDocs.findIndex(
                  (deleteScheduleDoc) =>
                    deleteScheduleDoc.id == oriSubmittedFile.id
                ) !== -1
                  ? false
                  : true
              })
              .map((v, index) => ({ ...v, seqNo: index + 1 }))

        formClicked ? setVoyages(newItems) : setSubmittedFiles(newItems) // reset list after delete success
        formClicked
          ? setOriginalVoyages(newOriginalItems)
          : setOriginalSubmittedFiles(newOriginalItems) // reset list after delete success
        formClicked
          ? setFormCount(newItems.length)
          : setFileCount(newItems.length)
      },
      (error) => {
        console.log(JSON.stringify(error))
        showErrorNotification(error.message)
        formClicked ? setVoyages(voyages) : setSubmittedFiles(submittedFiles)
      }
    )
  }

  const onOpenConfirmDialog = () => {
    // let checkedVoyages = voyages
    //   .filter((voyage) => voyage.checked)
    //   .filter((vVoyage) => {
    //     vVoyage = vVoyage.voyageLegs.filter(
    //       (leg) =>
    //         moment(leg.departureTime).isAfter(moment()) ||
    //         moment(leg.arrivalTime).isAfter(moment())
    //     )
    //     return vVoyage.length > 0
    //   })

    //   checkedVoyages.length > 0 ? seShowDeleteInUseSchedule(true) : seShowDeleteInUseSchedule(false)

    setConfirmDialogOpen(true)
  }

  // const onOpenConfirmDialog = () => {
  //   setConfirmDialogOpen(true)
  // }

  function closeSearchPanel() {
    setSearch('')
    filterTextImplementation('')
    setOpenSearchPanel(false)
    formClicked
      ? setColumns(
          columns.map((column) => ({
            ...column,
            active: column.field === 'name',
            order: column.field === 'name' ? 'desc' : undefined
          }))
        )
      : setFileColumns(
          fileColumns.map((column) => ({
            ...column,
            active: column.field === 'name',
            order: column.field === 'name' ? 'desc' : undefined
          }))
        )
  }

  function filterTextImplementation(filterText) {
    let newItems = formClicked
      ? [...originalVoyages]
      : [...originalSubmittedFiles]
    newItems = newItems
      .filter(
        (item) =>
          !filterText ||
          (formClicked
            ? item.vesselName
                .toUpperCase()
                .includes(filterText.toUpperCase()) ||
              (item.voyageNo !== null &&
                item.voyageNo.toUpperCase().includes(filterText.toUpperCase()))
            : item.fileName !== null &&
              item.fileName.toUpperCase().includes(filterText.toUpperCase()))
      )
      .map((v, index) => ({ ...v, seqNo: index + 1 }))

    formClicked ? setVoyages(newItems) : setSubmittedFiles(newItems)
  }

  const onAddButtonClick = () => {
    formClicked
      ? history.push(pathMap.TCAL_VS_MANAGE_SCHEDULE_ADD)
      : history.push(pathMap.TCAL_VS_MANAGE_SCHEDULE_ADD_FILE_UPLOAD)
  }

  const clickForm = () => {
    console.log('clickForm')
    closeSearchPanel()
    setFormClicked(true)
    // setFormClickedField(true)
    // history.push(pathMap.TCAL_VS_MANAGE_SCHEDULE_LIST)

    setPage(0)
    setRowsPerPage(10)
  }

  const clickFile = () => {
    console.log('click clickFile')
    closeSearchPanel()
    setFormClicked(false)
    setPage(0)
    setRowsPerPage(10)
  }

  return (
    <Paper>
      <Loading loading={loading} />
      <ConfirmDialog
        isConfirmDialogOpen={confirmDialogOpen}
        closeDialog={() => setConfirmDialogOpen(false)}
        confirmDialog={onDelete}
        content={`
          ${translatedTextsObject.d2} 
          ${
            formClicked
              ? voyages.filter((voyage) => voyage.checked === true).length
              : submittedFiles.filter(
                  (submittedFile) => submittedFile.checked === true
                ).length
          } 
          ${translatedTextsObject.items}?
        ${translatedTextsObject.d3}`}
        okMsg={translatedTextsObject.yesDelete}
        cancelMsg={translatedTextsObject.noTakeMeBack}
        title={translatedTextsObject.deleteRecords}
      />

      {!loading ? (
        <Box>
          <Box p='10px'>
            {!location.search.includes('docId') ? (
              <ManageScheduleButtonTabComponent.FormBody
                formCount={formCount}
                fileCount={fileCount}
                formClicked={formClicked}
                clickForm={clickForm}
                clickFile={clickFile}
              />
            ) : null}
          </Box>
          <Box pt='10px' pl='30px' mb='-20px' fontWeight='fontWeightBold'>
            {formClicked
              ? params.current.fileName !== null
                ? translatedTextsObject.voyageList +
                  ' ( ' +
                  params.current.fileName +
                  ' )'
                : translatedTextsObject.voyageList
              : translatedTextsObject.submittedFileList}
          </Box>
          <Box m='8px' className={classes.div}>
            <CngToolbarTable
              // vessels={vessels}
              data={formClicked ? voyages : submittedFiles}
              dataType={formClicked ? 'Voyage' : 'SubmittedFile'} // to specific export data in ExportComponent
              voyages={formClicked ? voyages : submittedFiles}
              onCancel={onCancel}
              openSearchPanel={openSearchPanel}
              searchDesc={
                formClicked
                  ? translatedTextsObject.searchDesc
                  : translatedTextsObject.searchDescSubmittedFile
              }
              search={search}
              onSearchChange={onSearchChange}
              onCloseSearchPanel={onCloseSearchPanel}
              page={page}
              rowsPerPage={rowsPerPage}
              onClickSearchPanel={onClickSearchPanel}
              columns={formClicked ? columns : fileColumns}
              onCheckBoxViewColumnChange={onCheckBoxViewColumnChange}
              classes={classes}
              showNotification={showNotification}
              addButtonText={translatedTextsObject.addScheduleTitle}
              onAddButtonClick={onAddButtonClick}
              onOpenConfirmDialog={onOpenConfirmDialog}
              user={user}
              userProfile={userProfile}
              viewOnly={params.current.fileName !== null}
            />
            <TableContainer>
              <Table>
                <CngTableHeader
                  // vessels={vessels}
                  data={formClicked ? voyages : submittedFiles}
                  onHeaderCheck={onHeaderCheck}
                  columns={formClicked ? columns : fileColumns}
                  onSortClick={onSortClick}
                />
                <CngTableBody
                  // vessels={vessels}
                  data={formClicked ? voyages : submittedFiles}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  columns={formClicked ? columns : fileColumns}
                  onRowCheck={onRowCheck}
                  onRowClick={onRowClick}
                  lookupVessel={vessels}
                  user={user}
                  userProfile={userProfile}
                />
                <TableFooter>
                  <TableRow>
                    <ResultPageTablePagination
                      // recordLength={vessels.length}
                      recordLength={
                        formClicked ? voyages.length : submittedFiles.length
                      }
                      rowsPerPage={rowsPerPage}
                      page={page}
                      handleChangePage={handleChangePage}
                      handleChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      ) : null}
    </Paper>
  )
}

export default ManageScheduleTable
