import Namespace from 'src/constants/locale/Namespace'
import {
  constants,
  Yup
} from 'cng-web-lib'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )

  return Yup.object({
    typeName: Yup.string().required(requiredMessage),
    calistaCode: Yup.string().required(requiredMessage),
    otherPlatformCode: Yup.string().required(requiredMessage)
  })
}

export default makeValidationSchema
