import { Container, Grid, Paper } from '@material-ui/core'
import { components, useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import PollingEditForm from './PollingEditForm'
import PollingKeys from 'src/constants/locale/key/Polling'
import PollingSourceCrudTable from './PollingSourceCrudTable'
import PollingViewForm from './PollingViewForm'
import React from 'react'
import { useParams } from 'react-router-dom'

const { CngTabs } = components

function EditPage({ showNotification }) {
  const { id } = useParams()
  const { translate } = useTranslation([Namespace.POLLING])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let polling = translate(Namespace.POLLING, PollingKeys.TITLE)
    let pollingSource = translate(
      Namespace.POLLING,
      PollingKeys.PollingSource.TITLE
    )

    return {
      polling,
      pollingSource
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper>
          <Container maxWidth='xl'>
            <PollingViewForm
              showNotification={showNotification}
              id={id}
              onFetchPreSuccess={(datum) => {
                console.log('to do')
              }}
            />
          </Container>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <CngTabs
          headerColor='primary'
          tabs={[
            {
              tabName: translatedTextsObject.polling,
              tabContent: (
                <PollingEditForm showNotification={showNotification} id={id} />
              )
            },
            {
              tabName: translatedTextsObject.pollingSource,
              tabContent: (
                <PollingSourceCrudTable
                  showNotification={showNotification}
                  pollingId={id}
                />
              )
            }
          ]}
        />
      </Grid>
    </Grid>
  )
}

export default EditPage
