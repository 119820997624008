import { Box, Paper } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import { components, constants, useServices } from 'cng-web-lib'
import { useHistory, useLocation } from 'react-router-dom'

import AddScheduleFinishButtonComponent from './AddScheduleFinishButtonComponent'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined'
import CngBackdrop from 'src/views/vesselschedule/searchschedule/cngcomponent/CngBackDrop'
import ConfirmDialog from 'src/components/dialog/ConfirmDialog'
import GeneralStepperComponent from 'src/views/common/ui/GeneralStepperComponent'
import ManageScheduleTranslationText from '../ManageScheduleTranslationText'
import Utils from 'src/views/common/utils/Utils'
import ViewRemarksComponent from '../viewschedule/ViewRemarksComponent'
import ViewScheduleInfoComponent from '../viewschedule/ViewScheduleInfoComponent'
import ViewVesselVoyageComponent from '../viewschedule/ViewVesselVoyageComponent'
import WarningDialog from 'src/components/dialog/WarningDialog'
import pathMap from 'src/paths/PathMap_VesselSchedule'
import { v4 as uuid } from 'uuid'

// import ViewSchduleInfoTableComponent from 'src/views/vesselschedule/manageschedule/viewschedule/ViewScheduleInfoTableComponent'

// import Api from '../shared/api'

const {
  form : {
    adapter: {
      useFormAdapter:{ useField, useFormikContext }
    }
  },
  table: { useDefaultNotification },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  schedule: ''
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES }
}

const { CodeMaintenanceType } = constants

function FormFields({ disabled, showNotification, loading }) {
  const history = useHistory()
  const location = useLocation()
  // alert(JSON.stringify(location.state))
  const voyageData = location.state
  // const voyageData = location.state.voyageData
  // const scheduleData = location.state.scheduleData

  const msTranslatedTextsObject = ManageScheduleTranslationText()
  const uiTranslatedTextsObject = CalistaUiComponentTranslationText()

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [warningDialogOpen, setWarningDialogOpen] = useState(false)

  const { errors, submitForm, setFieldValue } = useFormikContext()
  const [searchCriteria, ,] = useField('searchCriteria')
  const [voyageId, setVoyageId] = useState(0)

  const addScheduleStep = [
    msTranslatedTextsObject.addScheduleStep1,
    msTranslatedTextsObject.addScheduleStep2,
    msTranslatedTextsObject.addScheduleStep3
  ]

  const amendScheduleStep = [
    msTranslatedTextsObject.amendScheduleStep1,
    msTranslatedTextsObject.amendScheduleStep2,
    msTranslatedTextsObject.amendScheduleStep3
  ]

  const backToManageSchedule = () => {
    if (searchCriteria.value != null) {
      //amend from carrier search schedule, return to search schedule with searchCriteria
      history.push({
        pathname: pathMap.CARRIER_SEARCH_SCHEDULE_RESULT,
        state: searchCriteria.value
      })
      // }else if(voyageId > 0){
      //     //amend from view schedule, return to view schedule
      //     history.push({
      //         pathname: pathMap.TCAL_VS_MANAGE_SCHEDULE_VIEW,
      //         state: {
      //           voyageId: voyageData.id,
      //           hideAmend: false
      //         }
      //       })
    } else {
      //add schedule, return to manage schedule table list
      history.push(pathMap.TCAL_VS_MANAGE_SCHEDULE_LIST)
    }
  }

  useEffect(() => {
    setFieldValue(
      'searchCriteria',
      location.state
        ? location.state.hasOwnProperty('searchCriteria')
          ? location.state.searchCriteria
          : null
        : null
    )

    setVoyageId(voyageData.id)
  }, [])

  return (
    <Box>
      {/* {'Error :' + JSON.stringify(errors)} */}
      {/* <br/> */}
      {/* {'Touched :' + JSON.stringify(touched)} */}

      <CngGridItem xs={12} sm={9} shouldHide={loading ? false : true}>
        <CngBackdrop loading={loading} />
      </CngGridItem>

      <Paper>
        {/* <Box p={5} borderTop={1} borderBottom={1} className="border-light-blue" > */}
        <Box alignItems='center' pr={15} pl={15}>
          <GeneralStepperComponent
            steps={voyageId > 0 ? amendScheduleStep : addScheduleStep}
            activeStep={2}
          />
        </Box>
        <Box p={3} className='page-content'>
          <Box fontWeight={600} fontSize='h3.fontSize'>
            <Box>
              <Box display='flex' flexDirection='row'>
                {voyageData.isSave
                  ? msTranslatedTextsObject.scheduleSubmitted
                  : msTranslatedTextsObject.submitChanges}
                <Box pl={1.5}>
                  <CheckCircleOutlineOutlinedIcon className='light-green' />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box pt={3}>
            <ViewVesselVoyageComponent.FormBody voyageData={voyageData} />
          </Box>
          <Box pt={3}>
            <ViewRemarksComponent.FormBody voyageData={voyageData} />
          </Box>
          <Box pt={3}>
            <ViewScheduleInfoComponent.FormBody voyageData={voyageData} />
          </Box>
        </Box>
        {/* </Box> */}
      </Paper>
      <Paper className='sticky-footer'>
        <Box alignItems='center'>
          <AddScheduleFinishButtonComponent
            onBack={backToManageSchedule}
            serarchSchedule={searchCriteria.value != null ? true : false}
          />
        </Box>
      </Paper>
    </Box>
  )
}

const AddScheduleFinishFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  FormFields: FormFields
  // toClientDataFormat: toClientDataFormat,
  // toServerDataFormat: toServerDataFormat
})

export default AddScheduleFinishFormProperties
