import {
  Avatar,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@material-ui/core'
import React, { useEffect } from 'react'

import DocumentDetailsActionMenu from 'src/views/freightbooking/components/DocumentDetailsActionMenu'
import DocumentInfoDecoratorComponent from 'src/views/common/ui/DocumentInfoDecoratorComponent'
import { FileText } from 'react-feather'
import FreightBookingTranslationText from 'src/views/freightbooking/shared/FreightBookingTranslationText'

const DocumentDetailsComponents = (props) => {
  const translatedTextsObject = FreightBookingTranslationText()
  console.log(props.tile)
  useEffect(() => {
    console.log('file details change 2')

    var files = props.tile
    console.log(files)
  }, [props.tile])

  if (props.tile == null || props.tile.length <= 0) {
    return <br />
  } else {
    return (
      <TableContainer component={Paper}>
        <Table id='table'>
          <TableBody>
            {props.tile.map((elem, i) => {
              return (
                <TableRow key={`row-${i}`}>
                  <TableCell>
                    <Box display='flex'>
                      <Box flexGrow={1}>
                        <Box display='flex'>
                          <Box>
                            <Avatar style={{ backgroundColor: '#f5f6fa' }}>
                              <FileText size='25px' />
                            </Avatar>
                          </Box>
                          <Box ml={2}>
                            <DocumentInfoDecoratorComponent
                              bold={true}
                              name={elem.file.name}
                              size={elem.file.size}
                            />
                          </Box>
                        </Box>
                      </Box>
                      {!props.hideStatus ? (
                        <Box>
                          {elem.status === 'Completed' ? (
                            <Box mt={2} mr={8} className='rs-green'>
                              <b>Success</b>
                            </Box>
                          ) : elem.status === 'Pending' ||
                            elem.status === 'Processing' ? (
                            <Box mt={2} mr={8} style={{ color: '#8181a5' }}>
                              <b>In Progress...</b>
                            </Box>
                          ) : (
                            <Box mt={2} mr={8} style={{ color: 'red' }}>
                              <b>File Error</b>
                            </Box>
                          )}
                        </Box>
                      ) : null}
                      <Box>
                        <DocumentDetailsActionMenu
                          hideEdit={props.hideEdit}
                          hideDelete={props.hideDelete}
                          hideDownloadLog={elem.status !== 'Error'}
                          id={i}
                          removeRowClick={props.removeRowClick}
                          downloadRowClick={props.downloadRowClick}
                          downloadLogClick={props.downloadLogClick}
                        />
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }
}

export default DocumentDetailsComponents
