import { constants, useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import TcalVsManageScheduleValidationKeys from 'src/constants/locale/key/TcalVsManageScheduleValidation'

const {
    locale: {
        key: { CommonValidationMessageKeys }
    }
} = constants

const ManageScheduleValidationTranslateText = () => {
    const { translate } = useTranslation([
        Namespace.TCAL_VS_MANAGE_SCHEDULE_VALIDATION,
        Namespace.COMMON_VALIDATION_MSG,
    ])

    let requiredMessage = translate(
        Namespace.COMMON_VALIDATION_MSG,
        CommonValidationMessageKeys.REQUIRED
    )

    let dateTypeErrorMessage = translate(
        Namespace.COMMON_VALIDATION_MSG,
        CommonValidationMessageKeys.DATE_TYPE_ERROR
    )

    let dateTimeErrorMessage = translate(
        Namespace.TCAL_VS_MANAGE_SCHEDULE_VALIDATION,
        TcalVsManageScheduleValidationKeys.DATE_TIME_ERROR
    )
    let dateTimeErrorDDMMYYYHHmmMessage = translate(
        Namespace.TCAL_VS_MANAGE_SCHEDULE_VALIDATION,
        TcalVsManageScheduleValidationKeys.DATE_TIME_ERROR_DDMMYYYY_HHmm
    )

    let arrivalEarlierThanDepartureMessage = translate(
        Namespace.TCAL_VS_MANAGE_SCHEDULE_VALIDATION,
        TcalVsManageScheduleValidationKeys.ARRIVAL_EARLIER_THAN_DEPARTURE
    )
    let arrivalPortSameError = translate(
        Namespace.TCAL_VS_MANAGE_SCHEDULE_VALIDATION,
        TcalVsManageScheduleValidationKeys.ARRIVAL_PORT_SAME_ERROR
    )
    let departureEarlierThanArrival = translate(
        Namespace.TCAL_VS_MANAGE_SCHEDULE_VALIDATION,
        TcalVsManageScheduleValidationKeys.DEPARTURE_EARLIER_THAN_ARRIVAL
    )
    let cyCutoffLaterThanDeparture = translate(
        Namespace.TCAL_VS_MANAGE_SCHEDULE_VALIDATION,
        TcalVsManageScheduleValidationKeys.CY_CUTOFF_LATER_THAN_DEPARTURE
    )

    let exceedMaxCharactersMessage = translate(
        Namespace.TCAL_VS_MANAGE_SCHEDULE_VALIDATION,
        TcalVsManageScheduleValidationKeys.EXCEED_MAX_CHARACTERS
    )
        
    let emojiCharacterPresentMessage = translate(
        Namespace.TCAL_VS_MANAGE_SCHEDULE_VALIDATION,
        TcalVsManageScheduleValidationKeys.EMOJI_CHARACTER_PRESENT

    )
    return {
        requiredMessage,
        dateTypeErrorMessage,
        dateTimeErrorMessage,
        dateTimeErrorDDMMYYYHHmmMessage,
        arrivalEarlierThanDepartureMessage,
        arrivalPortSameError,
        departureEarlierThanArrival,
        cyCutoffLaterThanDeparture,
        emojiCharacterPresentMessage,
        exceedMaxCharactersMessage
    }
}

export default ManageScheduleValidationTranslateText