import React, { useState } from 'react'

import AddScheduleFileUploadFinishFormProperties from './AddScheduleFileUploadFinishFormProperties'
import { components } from 'cng-web-lib'
import { useHistory } from 'react-router-dom'

const {
  form: { CngViewForm }
} = components

function AddScheduleFileUploadFinishPage({ showNotification }) {
  const history = useHistory()
  const [loading, setLoading] = useState(false)

  return (
    // use CngViewForm here for customizable button set
    <CngViewForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      history={history}
      showNotification={showNotification}
      bodySection={
        <AddScheduleFileUploadFinishFormProperties.FormFields
          disabled={false}
          showNotification={showNotification}
          loading={loading}
        />
      }
      formikProps={{
        ...AddScheduleFileUploadFinishFormProperties.formikProps
      }}
    />
  )
}

export default AddScheduleFileUploadFinishPage
