import {
    Box,
    Button,
    Card,
    CardContent,
    Grid
} from '@material-ui/core'
import React, { useState } from 'react'

import ManageScheduleTranslationText from './ManageScheduleTranslationText'
import clsx from 'clsx'
import { components } from 'cng-web-lib'

const {
    CngGridItem,
    button: { CngPrimaryButton },
} = components

const FormBody = (props) => {
    const translatedTextsObject = ManageScheduleTranslationText()

    // let pendingShown = props.pendingClicked ? 'shown(' + props.shownCount + ')' : ''
    // let processedShown = !props.pendingClicked ? 'shown(' + props.shownCount + ')' : ''

    return (
        // <Card>
            // <CardContent>
                <Grid container spacing={1}>
                    <CngGridItem item xs={9} sm={9}>
                        <Grid container spacing={1}>
                            <CngGridItem item xs={12} sm={12}>
                                <Box display="flex" justifyContent="flex-start">
                                    <Box>
                                        <Button
                                            onClick={props.clickForm}
                                            variant={props.formClicked ? "outlined" : "text"}
                                            classes={{
                                                root: clsx(
                                                    props.formClicked ? 'ng-button-outline' : 'ng-button-text'
                                                )
                                            }}
                                            // disabled={props.formClicked ? true: false}
                                            >
                                            {translatedTextsObject.filledUpForms + '('+ props.formCount +')'}
                                        </Button>
                                    </Box>
                                    <Box pl={1}>
                                        <Button
                                            onClick={props.clickFile}
                                            variant={!props.formClicked ? "outlined" : "text"} 
                                            classes={{
                                                root: clsx(
                                                    !props.formClicked ? 'ng-button-outline' : 'ng-button-text'
                                                )
                                            }}    
                                            >
                                            {translatedTextsObject.submittedFiles + '('+ props.fileCount +')'}
                                        </Button>
                                    </Box>
                                </Box>
                            </CngGridItem>
                            
                        </Grid>
                    </CngGridItem>
                    <CngGridItem item xs={3} sm={3}>

                    </CngGridItem>
                </Grid>

            // </CardContent>
        // </Card>
    )
}

const ManageScheduleButtonTabComponent = Object.freeze({
    FormBody: FormBody,
})

export default ManageScheduleButtonTabComponent