import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import CodeMappingKeys from 'src/constants/locale/key/CodeMapping'
import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
  DataFlattener,
  DateTimeFormatter,
  useTranslation
} from 'cng-web-lib'
import makeValidationSchema from './MakeValidationSchema'

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngTextField,
      CngDateField,
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  codeMappingId: 0,
  typeName: "",
  calistaCode: "",
  otherPlatformCode: "",
  createdDate: "",
  updatedDate: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap }) {
  const { translate } = useTranslation(Namespace.CODE_MAPPING)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let codeMapping = translate(
      Namespace.CODE_MAPPING,
      CodeMappingKeys.TITLE
    )
    let codeMappingId = translate(
      Namespace.CODE_MAPPING,
      CodeMappingKeys.CODE_MAPPING_ID
    )
    let typeName = translate(
      Namespace.CODE_MAPPING,
      CodeMappingKeys.TYPE_NAME
    )
    let calistaCode = translate(
      Namespace.CODE_MAPPING,
      CodeMappingKeys.CALISTA_CODE
    )
    let otherPlatformCode = translate(
      Namespace.CODE_MAPPING,
      CodeMappingKeys.OTHER_PLATFORM_CODE
    )
    let createdBy = translate(
      Namespace.CODE_MAPPING,
      CodeMappingKeys.CREATED_BY
    )
    let createdDate = translate(
      Namespace.CODE_MAPPING,
      CodeMappingKeys.CREATED_DATE
    )
    let updatedBy = translate(
      Namespace.CODE_MAPPING,
      CodeMappingKeys.UPDATED_BY
    )
    let updatedDate = translate(
      Namespace.CODE_MAPPING,
      CodeMappingKeys.UPDATED_DATE
    )
    let version = translate(
      Namespace.CODE_MAPPING,
      CodeMappingKeys.VERSION
    )

    return {
      codeMapping,
      codeMappingId,
      typeName,
      calistaCode,
      otherPlatformCode,
      createdBy,
      createdDate,
      updatedBy,
      updatedDate,
      version
    }
  }

	return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.codeMapping} />
      <CardContent>
        <Grid container spacing={3}>
          <CngGridItem xs={12} sm={6} shouldHide={true}>
            <CngTextField
              name="codeMappingId"
              type="number"
              label={translatedTextsObject.codeMappingId}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.typeName}>
            <CngTextField
              name="typeName"
              label={translatedTextsObject.typeName}
              disabled={disabled}
              required
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.calistaCode}>
            <CngTextField
              name="calistaCode"
              label={translatedTextsObject.calistaCode}
              disabled={disabled}
              required
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.otherPlatformCode}>
            <CngTextField
              name="otherPlatformCode"
              label={translatedTextsObject.otherPlatformCode}
              disabled={disabled}
              required
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={true}>
            <CngDateField
              name="createdDate"
              label={translatedTextsObject.createdDate}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={true}>
            <CngDateField
              name="updatedDate"
              label={translatedTextsObject.updatedDate}
              disabled={disabled}
            />
          </CngGridItem>
        </Grid>
      </CardContent>
    </Card>
	)
}

function toClientDataFormat(serverData) {
  let localData = DataFlattener.parse(serverData);
  localData.createdDate = DateTimeFormatter.toClientDate(
    localData.createdDate
  );
  localData.updatedDate = DateTimeFormatter.toClientDate(
    localData.updatedDate
  );
  return localData;
}

function toServerDataFormat(localData) {
  localData.createdDate = DateTimeFormatter.toServerDate(
    localData.createdDate
  );
  localData.updatedDate = DateTimeFormatter.toServerDate(
    localData.updatedDate
  );
  return DataFlattener.unflatten(localData);
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
